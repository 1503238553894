import React, { useEffect, useState } from 'react'
import { getAPIAuth, postAPIAuth } from '../../../service/apiInstance';
import { errorToaster, succesToaster } from '../../../utils/toaster';
import { getFantasyAuth } from '../../../fantasy/fantasyServices';
import { useAuth } from '../../../hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { fantasyAddUserData, stockAddUserData } from '../../../store/action';
import { postTradingAPI } from '../../../Trading/TradingServices';
import moment from 'moment/moment';
import { fontSize } from '@mui/system';
import DarkPagination from '../../darkPagination/DarkPagination';

const Converter = ({ tabopenn, openaccordian }) => {

  const [tabopen, setTabopen] = useState("fantasy");
  const [amount, setAmount] = useState(0)
  const [deductFrom, setDeductFrom] = useState('main')
  const [addTo, setAddTo] = useState('fantasy')
  const [swap, setSwap] = useState(false)
  const { token } = useAuth()
  const dispatch = useDispatch()
  const fantasyUserData = useSelector((state) => state?.fantasyUserData);
  const stockUserData = useSelector((state) => state?.stockUserData);
  const [converter, setConverter] = useState("converter")
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  // console.log("deductFrom", tabopen);


  const getConverterHistory = async () => {
    try {
      const res = await getAPIAuth("wallet/getWalletConvertList" + `?page=${currentPage}`)
      setCurrentPage(res.data.data.pagination.page)
      setTotalPages(res.data.data.pagination.totalPage)
      setData(res?.data?.data?.data)
    } catch (error) {
      console.log("error", error)
    }
  }
  useEffect(() => {
    if (tabopenn === "Converter" || openaccordian === "Converter") {
      getConverterHistory()
    }
  }, [converter, currentPage])

  // api for fantasy and betting money convert
  const handleConvert = async () => {
    try {
      const body = {
        "amount": amount,
        "deduct": deductFrom,
        "add": addTo
      }
      const res = await postAPIAuth(`wallet/walletConvert`, body)
      if (res?.data?.data?.success) {
        succesToaster(res?.data?.data?.message)
        getProfileData();
        setAmount('')
      }
    } catch (error) {
      errorToaster(error?.response?.data?.data?.message)
    }
  }

  // api for stock money convert
  const handleStockConvert = async () => {
    try {
      const body = {
        "amount": amount,
        "deduct": deductFrom,
        "add": addTo
      }
      const res = await postAPIAuth(`wallet/walletConvertWithStock`, body)
      if (res?.data?.data?.success) {
        succesToaster(res?.data?.data?.message)
        getStockData()
        setAmount('');
      }
    } catch (error) {
      errorToaster(error?.response?.data?.data?.message)
    }
  }


  // function for swap handle in fantasy

  useEffect(() => {
    if (tabopen == "fantasy") {
      if (swap) {
        setDeductFrom('fantasy')
        setAddTo('main')
      } else {
        setDeductFrom('main')
        setAddTo('fantasy')
      }
    }
    else if (tabopen == "betting") {

      if (swap) {
        setDeductFrom('bettingPoint')
        setAddTo('main')
      } else {
        setDeductFrom('main')
        setAddTo('bettingPoint')
      }

    }

    else if (tabopen == "stock") {

      if (swap) {
        setDeductFrom('stock')
        setAddTo('main')
      } else {
        setDeductFrom('main')
        setAddTo('stock')
      }

    }

    else {

    }
  }, [swap])

  console.log("deductFrom", deductFrom);


  // function for getting main , fantasy and betting wallet
  const getProfileData = async () => {
    try {
      const res = await getFantasyAuth(`userFullDetails`, token);
      if (res.data.success) {
        dispatch(fantasyAddUserData(res.data.data));
      }
    } catch (error) {
      console.log("---------------------->>>>>>>", error)
    }
  };

  // function for getting stock wallet
  const getStockData = async () => {
    try {
      const body = {
        action: "account-balance",
      };
      const res = await postTradingAPI("account-balance.php", body);
      const response = await res.json();
      dispatch(stockAddUserData(response));

    } catch (error) {
      console.log("error", error);
    }
  };
  return (

    <>
      <div className="row justify-content-cente">
        <div className="col-12 pb-2 d-none d-md-block">
          <div className="d-flex align-items-center accountCardHeading">
            <div className="cardImg">
              <img
                src="assets/img/sidebar/switch.png"
                alt="affiliate"
                className="h-100 w-100 img-fluid "
              />
            </div>
            CONVERTER
          </div>
        </div>
        <div className="col-12">
          <div className='nav nav-tabs gap-2 reffralsTab border-0 py-4'>
            <button className={`nav-link refferalBtn ${converter === "converter" ? "active" : ""
              }`}
              onClick={() => setConverter("converter")}
            >CONVERTER</button>
            <button className={`nav-link refferalBtn  ${converter === "converterHistory" ? "active" : ""
              }`}
              onClick={() => setConverter("converterHistory")}
            >CONVERTER HISTORY</button>
          </div>

          <div class="tab-content" id="pills-tabContent">
            <div className={`tab-pane fade ${converter === "converter" ? "show active" : ""
              }`}
              id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
              <div class="row pt-3 g-2">
                <div class="col-sm-3 col-6">
                  <div class="reffralsCard text-center h-100">
                    <div class="totalComission">₹ {fantasyUserData?.points ? fantasyUserData?.points?.toFixed(2) : 0.00}</div>
                    <div class="Comission">Main Wallet</div>
                  </div>
                </div>
                <div class="col-sm-3 col-6">
                  <div class="reffralsCard text-center h-100">
                    <div class="totalComission">₹ {fantasyUserData?.walletamaount ? fantasyUserData?.walletamaount?.toFixed(2) : 0.00}</div>
                    <div class="Comission">Fantasy Wallet</div>
                  </div>
                </div>
                {/* <div class="col-sm-3 col-6 mb-sm-0 mb-3">
                  <div class="reffralsCard text-center h-100">
                    <div class="totalComission">₹ {fantasyUserData?.points ? fantasyUserData?.points?.toFixed(2) : 0.00}</div>
                    <div class="Comission">Betting Wallet</div>
                  </div>
                </div> */}
                <div class="col-sm-3 col-6 mb-sm-0 mb-3">
                  <div class="reffralsCard text-center h-100">
                    <div class="totalComission">₹ {stockUserData?.amount_balance ? Number(stockUserData?.amount_balance)?.toFixed(2) : '0.00'}</div>
                    <div class="Comission">Stock Wallet</div>
                  </div>
                </div>
              </div>
              <nav className="col-12 pt-md-4">
                <div
                  className="nav nav-tabs gap-2 reffralsTab border-0"
                  id="nav-tab"
                  role="tablist"
                >
                  <button
                    className={`nav-link refferalBtn ${tabopen === "fantasy" ? "active" : ""
                      }`}
                    onClick={() => {
                      setTabopen("fantasy")
                      setDeductFrom('main')
                      setAddTo('fantasy')
                      setSwap(false)
                    }}
                  >
                    FANTASY
                  </button>
                  {/* <button
                    className={`nav-link refferalBtn ${tabopen === "betting" ? "active" : ""
                      }`}
                    onClick={() => {
                      setTabopen("betting")
                      setDeductFrom('main')
                      setAddTo('bettingPoint')
                    }}
                  >
                    BETTING
                  </button> */}
                  <button
                    className={`nav-link refferalBtn ${tabopen === "stock" ? "active" : ""
                      }`}
                    onClick={() => {
                      setTabopen("stock")
                      setDeductFrom('main')
                      setAddTo('stock')
                    }}
                  >
                    STOCK
                  </button>
                </div>
              </nav>
              {
                tabopen === 'fantasy' ?
                  <>
                    <div className="col-12 mt-4">
                      <div className="row align-items-center">
                        <div className="col-sm">
                          <div className="converterBox">
                            <div className="row mx-0 gx-0">
                              <div className="col-12 converterBoxTxt">Pay</div>
                              <div className="col-12">
                                <input
                                  type="text" className='converterInp form-control shadow-none border-0'
                                  value={amount}
                                  placeholder='Enter Amount'
                                  onChange={(e) => setAmount(e.target.value)} />
                              </div>
                              <div className="col-12">
                                <div className="converterDivider">
                                  <img src="assets/img/converterLine.png" className='w-100' alt="" />
                                </div>
                              </div>
                              <div className="col-12 mt-2">
                                <div className="row">
                                  <div className="col converterBoxTxt">From</div>
                                  <div className="col-auto converterBoxTxt2">{swap ? 'Fantasy Wallet' : 'Main Wallet'}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-auto px-0 py-3 py-sm-0 d-flex justify-content-center">
                          <div className="converterIcon" onClick={() => {
                            setSwap(!swap)
                            // handleSwap()
                          }}>
                            <img src="assets/img/sidebar/switch.png" alt="" />
                          </div>
                        </div>
                        <div className="col-sm">
                          <div className="converterBox">
                            <div className="row mx-0 gx-0">
                              <div className="col-12 converterBoxTxt">Receive</div>
                              <div className="col-12">
                                <input type="text" className='converterInp form-control shadow-none border-0' readonly value={amount} />
                              </div>
                              <div className="col-12">
                                <div className="converterDivider">
                                  <img src="assets/img/converterLine.png" className='w-100' alt="" />
                                </div>
                              </div>
                              <div className="col-12 mt-2">
                                <div className="row">
                                  <div className="col converterBoxTxt">To</div>
                                  <div className="col-auto converterBoxTxt2">{swap ? 'Main Wallet' : 'Fantasy Wallet'}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div class="col-sm-8 mx-auto pt-4">
                          <button class="customBtn customBtntwo d-flex align-items-center justify-content-center w-100 " onClick={handleConvert}>CONVERT</button>
                        </div>
                      </div>
                    </div>
                  </>
                  : tabopen === 'betting' ?
                    <>
                      <div className="col-12 mt-4">
                        <div className="row align-items-center">
                          <div className="col-sm">
                            <div className="converterBox">
                              <div className="row mx-0 gx-0">
                                <div className="col-12 converterBoxTxt">Pay</div>
                                <div className="col-12">
                                  <input type="text"
                                    className='converterInp form-control shadow-none border-0'
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                  />
                                </div>
                                <div className="col-12">
                                  <div className="converterDivider">
                                    <img src="assets/img/converterLine.png" className='w-100' alt="" />
                                  </div>
                                </div>
                                <div className="col-12 mt-2">
                                  <div className="row">
                                    <div className="col converterBoxTxt">From</div>
                                    <div className="col-auto converterBoxTxt2"> {swap ? 'Betting Wallet' : 'Main Wallet'}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-auto px-0 py-3 py-sm-0 d-flex justify-content-center">
                            <div className="converterIcon" onClick={() => {
                              setSwap(!swap)
                              // handleSwap()
                            }}>
                              <img src="assets/img/sidebar/switch.png" alt="" />
                            </div>
                          </div>
                          <div className="col-sm">
                            <div className="converterBox">
                              <div className="row mx-0 gx-0">
                                <div className="col-12 converterBoxTxt">Receive</div>
                                <div className="col-12">
                                  <input type="text" className='converterInp form-control shadow-none border-0' readonly value={amount} />
                                </div>
                                <div className="col-12">
                                  <div className="converterDivider">
                                    <img src="assets/img/converterLine.png" className='w-100' alt="" />
                                  </div>
                                </div>
                                <div className="col-12 mt-2">
                                  <div className="row">
                                    <div className="col converterBoxTxt">To</div>
                                    <div className="col-auto converterBoxTxt2">{swap ? 'Main Wallet' : 'Betting Wallet'}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-sm-8 mx-auto pt-4">
                            <button onClick={handleConvert} class="customBtn customBtntwo d-flex align-items-center justify-content-center w-100 ">CONVERT</button>
                          </div>
                        </div>
                      </div>
                    </>
                    : tabopen === 'stock' ?
                      <>
                        <div className="col-12 mt-4">
                          <div className="row align-items-center">
                            <div className="col-sm">
                              <div className="converterBox">
                                <div className="row mx-0 gx-0">
                                  <div className="col-12 converterBoxTxt">Pay</div>
                                  <div className="col-12">
                                    <input type="text" className='converterInp form-control shadow-none border-0'
                                      value={amount}
                                      onChange={(e) => setAmount(e.target.value)} />
                                  </div>
                                  <div className="col-12">
                                    <div className="converterDivider">
                                      <img src="assets/img/converterLine.png" className='w-100' alt="" />
                                    </div>
                                  </div>
                                  <div className="col-12 mt-2">
                                    <div className="row">
                                      <div className="col converterBoxTxt">From</div>
                                      <div className="col-auto converterBoxTxt2">{swap ? 'Main Wallet' : 'Stock Wallet'}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-auto px-0 py-3 py-sm-0 d-flex justify-content-center">
                              <div className="converterIcon" onClick={() => {
                                setSwap(!swap)
                                // handleSwap()
                              }}>
                                <img src="assets/img/sidebar/switch.png" alt="" />
                              </div>
                            </div>
                            <div className="col-sm">
                              <div className="converterBox">
                                <div className="row mx-0 gx-0">
                                  <div className="col-12 converterBoxTxt">Receive</div>
                                  <div className="col-12">
                                    <input type="text" className='converterInp form-control shadow-none border-0' readonly value={amount} />
                                  </div>
                                  <div className="col-12">
                                    <div className="converterDivider">
                                      <img src="assets/img/converterLine.png" className='w-100' alt="" />
                                    </div>
                                  </div>
                                  <div className="col-12 mt-2">
                                    <div className="row">
                                      <div className="col converterBoxTxt">To</div>
                                      <div className="col-auto converterBoxTxt2">{swap ? 'Stock Wallet' : 'Main Wallet'}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div class="col-sm-8 mx-auto pt-4">
                              <button onClick={handleStockConvert} class="customBtn customBtntwo d-flex align-items-center justify-content-center w-100 ">CONVERT</button>
                            </div>
                          </div>
                        </div>
                      </>
                      : ''
              }
            </div>
            <div className={`tab-pane fade ${converter === "converterHistory" ? "show active" : ""
              }`}
              id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
              <div className="">
                <div className="col-12 cardsubHeading settingHeading mb-2 ">CONVERTER HISTORY</div>
                <div className="table-responsive">
                  <table className="table affiliateTable mb-2 align-middle">
                    <thead>
                      <tr className="tableHead">
                        <th scope="col" className="border-0 rounded-start-2 px-3">
                          SR NO.
                        </th>
                        <th scope="col" className="border-0 px-3">
                          FROM
                        </th>
                        <th scope="col" className="border-0 px-3">
                          TO
                        </th>
                        <th scope="col" className="border-0 px-3">
                          AMOUNT
                        </th>
                        <th scope="col" className="border-0 px-3">
                          TRANSACTION ID
                        </th>
                        <th scope="col" className="border-0 px-3">
                          DATE
                        </th>
                      </tr>
                    </thead>
                    <tbody className='tablebody'>
                      {
                        data.length ? data.map((item, index) => (
                          <tr className='text-nowrap' key={index}>
                            <td className='text-white border-0 px-4' style={{ fontSize: '12px' }}>{index + 1}</td>
                            <td className='text-white border-0 px-4' style={{ fontSize: '12px' }}>{item.from}</td>
                            <td className='text-white border-0 px-4' style={{ fontSize: '12px' }}>{item.to}</td>
                            <td className='text-white border-0 px-4' style={{ fontSize: '12px' }}>{item.amount}</td>
                            <td className='text-white border-0 px-4' style={{ fontSize: '12px' }}>{item.transaction_id}</td>
                            <td className='text-white border-0 px-4' style={{ fontSize: '12px' }}>{moment(item.createdAt).format('L')}</td>
                          </tr>
                        ))

                          : 'no data'
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              {
                totalPages > 1 ?
                  <DarkPagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                  : ''
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Converter