import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import { adminGetAPI, adminGetAPIAuth, adminPostAPI, adminPostAPIAuth } from "../../AdminServices";
import UpdateCryptoDetails from "../cryptoDetails/UpdateCryptoDetails";
import AddCryptoDetails from "../cryptoDetails/AddCryptoDetails";
import { useSelector } from "react-redux";
import Pagination from "../../Admincomponents/pagination/Pagination";
import { succesToaster as successToaster } from "../../../utils/toaster";


const Bonus = () => {
    const adminDetails = useSelector(state => state.adminData)
    const [bonusData, setBonusData]= useState();
    const [show, setShow]= useState(false)
    const [id, setId] = useState('');
    const [formValues, setFormValues] = useState({
        amount:"",
        amountType: "",
        type: ""
      })

    const getBonusDetails = async () => {
        try {
            const res = await adminGetAPIAuth("user/getBonusSettings")
            if(res.data.data.success){
            const data=res.data.data.response;
            setBonusData(data);
            setFormValues({
             amount:data.amount,
            amountType: data.amountType,
            type: data.type
            });
        }
        } catch (error) {
            console.log('error------', error)
        }
    }
    
       

    useEffect(() => {
        getBonusDetails()
       
    }, [])

    const handleChange=(e)=>{
        const {name,value}= e.target;
        setFormValues((prev)=>({
                ...prev,
                [name]:value
                }))
        }
        const editHandler=(list)=>{
            setShow(true);
            setId(list._id);
            setFormValues({
                amount:list.amount,
                amountType: list.amountType,
                type: list.type
            });
            }
        const resetState=()=>{
            setFormValues({
                amount:'',
                amountType: '',
                type: ''
            });
            setId('');
            setShow(false);

        }
        const handleSubmit=async(e)=>{
            e.preventDefault();
            if(!formValues.amount||!formValues.amountType||!formValues.type){
                successToaster("Fill all fields");
            }else{
            let url;
            if(id){
                url=`user/updateBonusSettings?_id=${id}`
            }else{
                url=`user/addBonusSettings`
            }
            try {
                const res = await adminPostAPIAuth(url, formValues);
                if (res?.data?.data?.success) {
                    successToaster(res?.data?.data?.message);
                    setShow(false);
                    setFormValues({
                        amount:'',
                        amountType: '',
                        type: ''
                    });
                    setId('')
                    console.log(res.data);
                  getBonusDetails()
                }
              } catch (error) {
                console.log(error)
              }
            }
        }

        const deleteHandler=async(id)=>{
           
            try {
                const res = await adminPostAPIAuth(`user/deleteBonusSettings?_id=${id}`);
                if (res?.data?.data?.success) {
                    successToaster(res?.data?.data?.message);
                    resetState();
                  getBonusDetails();
                }
              } catch (error) {
                console.log(error)
              }
        }
    return (
        <>
            <div className="app">
                <main className="app-main">
                    <div className="wrapper">
                        <div className="page">
                            <div className="page-inner ">
                                <header className="page-title-bar justify-content-between row">
                                    <h1 className="page-title col-md">Bonus</h1>
                                    {/* <div className=" page-section align-items-center w-auto m-0  flex-row-reverse col-md-auto">
                                        {!tableData.length > 0 && <AddCryptoDetails getPaymentDetails={getPaymentDetails} />}
                                    </div> */}
                                </header>
                                <div className="d-flex justify-content-between my-3">
                                    <div>
                                        <ol className="breadcrumb"></ol>
                                    </div>
                                </div>
                                
                                <Button type='submit' onClick={()=>setShow(true)} className="bg-dark border border-dark shadow">
                                  {/* {!edit &&  <i class="bi bi-pencil"></i>} */}
                                   Add Bonus</Button>
                                <div className="page-section">
                                    <div className=" card-fluid">
                                        <div className="card-body px-0">
                                            <div className="table-responsive">
                                            <Modal show={show} 
                                                onHide={resetState} >
                                                <Modal.Header>
                                                    <Modal.Title>Add Bonus</Modal.Title>
                                                    <span
                                                    className="p-2 close-modal-btn"
                                                    onClick={resetState}
                                                    >
                                                    <i className="bi bi-x-lg"></i>
                                                    </span>
                                                </Modal.Header>

                                                <Modal.Body>
                                            <Form>
                                            <Container>
                                                <Row>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                    <Form.Label htmlFor="fromUser">Bonus Amount</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        id="amount"
                                                        name="amount"
                                                        value={formValues.amount}
                                                        onChange={handleChange}
                                                    />
                                                    </Form.Group>
                                                </Col>
                                                </Row>
                                                <Row>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                    <Form.Label htmlFor="currentPoints">Bonus Type</Form.Label>
                                                <Form.Select
                                                        id="amountType"
                                                        name="amountType"
                                                        placeholder="Select"
                                                        value={formValues.amountType}
                                                        onChange={handleChange}
                                                          >
                                                        <option value={''} selected>Select</option>
                                                        <option value={'CURRENCY'}>Rupees(INR)</option>
                                                        <option value={'PERCENT'}>Percentage(%)</option>
                                                    </Form.Select>
                                                    </Form.Group>
                                                    </Col>
                                                    </Row>
                                                <Row>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                    <Form.Label htmlFor="currentPoints">Bonus Set</Form.Label>
                                                <Form.Select
                                                        id="type"
                                                        name="type"
                                                        placeholder="Select"
                                                        value={formValues.type}
                                                        onChange={handleChange}

                                                    > 
                                                        <option value={''} selected>Select</option>
                                                        <option value={'FIRST_TIME'}>First Time Deposit</option>
                                                        <option value={'EVERY_TIME'}>Everytime Deposit</option>
                                                        <option value={'REFERRAL'}>Referral Bonus</option>
                                                    </Form.Select>
                                                    </Form.Group>
                                                    </Col>
                                                    </Row>
                                                <Row>
                                                <Col>
                                                <Form.Group className="mb-3">
                                                    <Button type='submit' 
                                                    onClick={handleSubmit}
                                                    >Save</Button>
                                                    </Form.Group>
                                                </Col>
                                                </Row>
                                            </Container>
                                            </Form>
                                            </Modal.Body>
                                                <Modal.Footer></Modal.Footer>
                                                </Modal>
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                style={{ minWidth: "140px", cursor: "pointer" }}
                                                                className="p-2 d-flex justify-content-evenly"
                                                            >
                                                                <span>S.No</span>
                                                            </th>

                                                            <th
                                                                style={{ minWidth: "150px", cursor: "pointer" }}
                                                                className="p-2"
                                                            >
                                                                <span>Bonus Amount</span>
                                                            </th>
                                                            <th
                                                                style={{ minWidth: "140px", cursor: "pointer" }}
                                                                className="p-2"
                                                            >
                                                                <span>Bonus Type</span>
                                                            </th>
                                                            <th
                                                                style={{ minWidth: "140px", cursor: "pointer" }}
                                                                className="p-2"
                                                            >
                                                                <span>Bonus Set Period</span>
                                                            </th>
                                                           
                                                            <th
                                                                style={{ minWidth: "140px", cursor: "pointer" }}
                                                                className="p-2"
                                                            >
                                                                <span>Action</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {bonusData?.length > 0 ? bonusData?.map((item, index) => (
                                                            <tr key={item?._id}>
                                                                <td className="align-middle  d-flex justify-content-evenly pt-3">{index+1}</td>
                                                                <td className="align-middle">{item?.amount}</td>
                                                                <td className="align-middle">{item?.amountType}</td>
                                                                <td className="align-middle">{item?.type}</td>
                                                                <td className="align-middle">
                                                                <Button className="mx-1 fw-bold" variant="info" onClick={()=>editHandler(item)}>Edit</Button>
                                                                <Button className="mx-1 fw-bold" variant="danger" onClick={()=>deleteHandler(item._id)}>Delete</Button>
                                                                </td>
                                                                {/* {item.status==="pending" &&(<>
                                                                <td className="align-middle d-flex" ><Button className="mx-1 fw-bold" variant="info" onClick={()=>getActionsButton(item._id,1)}>VERIFY</Button>
                                                               <Button className="mx-1 fw-bold" variant="info" onClick={()=>getActionsButton(item._id,2)}>Reject</Button></td>
                                                                </> )}
                                                                {item.status==="approved" &&(<td className="align-middle "><Button className="mx-1 fw-bold" variant="success">{item.status}</Button></td>)}
                                                                {item.status==="rejected" &&(<td className="align-middle"><Button className="mx-1 fw-bold" variant="danger">{item.status}</Button></td>)}
                                                            */}
                                                            </tr>
                                                        )) :

                                                            <tr>
                                                                <td colSpan={6} className="text-center" style={{ fontSize: 16, fontWeight: 500 }}>No Data Found</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
};

export default Bonus;
