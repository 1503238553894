// export const BASE_URL=import.meta.env.VITE_BASE_URL

export const USER={
    login:"user/login",
    register:"user/register",
    children:"user/children", // requires id ex= children/id
    profile:"user/profile", // requires id ex= profile/id
    changePassword:"user/changepassword",
    changeStatus:"user/changestatus",
    transferPoints:"user/transferpoints",
    commission:"user/commission",
    settlement:"user/settlement",
    logout:"user/logout",
    saveCommission:"user/saveCommissionPercentage"
}

export const REPORTS={
    betGames:"bet/games",
    betAllBetLists:"bet/allBetLists",
    myaccountstatement:"user/myaccountstatement",
    adminaccountstatement:"user/adminaccountstatement",
    gameReportStage:"user/gamereport/stage",
    profitlossreport:"user/profitlossreport",
    balance:"user/getUserBalance",
    commissionReport:"user/getCommissionReport",
    eventCommissionReport:"user/getEventLevelCommissionReport"
}