import React from "react";
import { ROUTES_CONST } from "../../constants/routeConstant";
import { Link } from "react-router-dom";
import { useDownloadModal } from "./../../hooks/useDownload";

const DownloadModal = () => {
    const  {downloadObject, setDownloadObject} = useDownloadModal()
  return (
    <div
      className={`modal fade downloadModalFade ${downloadObject.isDownloadOpen ? 'show d-block pl-0' : ''}`}
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl modal-dialog-centered downloadModalDialog">
        <div className="modal-content downloadModalContent">
          <div className="modal-header downloadModalHeader">
            <button
              type="button"
               onClick={()=> setDownloadObject({isDownloadOpen : false})}
              className="btn-close modalCloseBtn me-2 mt-2 shadow-none"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img src="assets/img/modal/close.png" alt="" />
            </button>
          </div>
          <div className="modal-body downloadModalBody">
            <ul
              className="nav nav-pills navPillsUl mb-3"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item navItem me-4" role="presentation">
                <button
                  className="nav-link navLink active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-ios"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  For IOS Setup
                </button>
              </li>
              <li className="nav-item navItem" role="presentation">
                <button
                  className="nav-link navLink"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-android"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  For Android Setup
                </button>
              </li>
            </ul>
            <div className="tab-content pillsContent" id="pills-tabContent">
              <div
                className="tab-pane tabPaneIos fade show active"
                id="pills-ios"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
                tabIndex="0"
              >
                <div className="iosTab">
                  <div className="row align-items-center">
                    <div className="col-6">
                      <div className="row">
                        <div className="col-12">
                          <div className="heading">
                            HOW TO DOWNLOAD MOBILE APP FOR IOS ?
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="subheading">
                            1. Open BX in Safari browser
                          </div>
                          <div className="para">
                            Open Safari browser on your phone and go to{" "}
                            <Link to={ROUTES_CONST.INDEX} className="anchor">
                              BX.io.
                            </Link>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="subheading">
                            2. Tap Sharing button.
                          </div>
                          <div className="para">
                            When on{" "}
                            <Link to={ROUTES_CONST.INDEX} className="anchor">
                              BX.io.
                            </Link>{" "}
                            tap then on the Sharing Button.
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="subheading">
                            3. Choose to Add to Home Screen.
                          </div>
                          <div className="para">
                            Press Add to Home Screen in the list popup to add to
                            the home screen. You may need to swipe left to
                            locate the Add to Home Screen button.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="modalImg">
                        <img src="assets/img/modal/download2.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane tabPaneIos fade"
                id="pills-android"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
                tabIndex="0"
              >
                <div className="iosTab">
                  <div className="row align-items-center">
                    <div className="col-6">
                      <div className="row">
                        <div className="col-12">
                          <div className="heading">
                            HOW TO DOWNLOAD MOBILE APP FOR ANDROID ?
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="subheading">
                            1. Open website in Chrome browser
                          </div>
                          <div className="para">
                            Tap to open Google Chrome on your phone
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="subheading">2. Tap menu button.</div>
                          <div className="para">
                            Use Google Chrome APP to go{" "}
                            <Link to={ROUTES_CONST.INDEX} className="anchor">
                              BX.io.
                            </Link>{" "}
                            and then click the button “Three dots”.tap then on
                            the Sharing Button.
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="subheading">3. Tap menu button.</div>
                          <div className="para">
                            Press Install app in the list popup to add to the
                            home screen.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="modalImg">
                        <img src="assets/img/modal/download1.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer downloadModalFooter"></div>
        </div>
      </div>
    </div>
  );
};

export default DownloadModal;
