import React, { useEffect, useState } from "react";
import EmailOrNumber from "./EmailOrNumber";
import PasswordField from "./PasswordField";
import OtpField from "./OtpField";
import { useAuthModal } from "../../../hooks/useAuthModal";
import { AUTH_MODAL_TABS } from "../../../constants";
import NumberField from "./NumberField";
import EmailField from "./EmailField";
import ReferralModal from "./ReferralModal";

const SignUpTab = ({ track }) => {
    const [Active, setActive] = useState(true);
    const [isNumber, setIsNumber] = useState(false)
    const [optid, setotpid] = useState(false);
    const [passwordView, setPasswordView] = useState(false)
    const [emailOrNumber, setEmailOrNumber] = useState(false)
    const [mobile, setMobile] = useState('')
    const [email, setEmail] = useState('')
    const [checks, setChecks] = useState({
        terms: false,
        promotions: false
    })
    const [username, setUsername] = useState('')
    const { authModalObject } = useAuthModal()
    const [referral, setReferral] = useState()
    const [isReferral, setIsReferral] = useState(false)
    const [fullName, setFullName] = useState('')

    useEffect(() => {
        if (authModalObject.selectedTab === AUTH_MODAL_TABS.SIGN_UP) {
            setActive(true)
            setPasswordView(false)
            setotpid(false)
            setIsNumber(false)
        }
    }, [track])

    useEffect(() => {
        const data = localStorage.getItem('middlePart')
        if (!!data) {
            setReferral(data)
        }
    }, [])

    return (
        <div className="d-flex h-100 flex-column"
        >
            {/* <div className="col-12">
                <ul className="nav nav-tabs border-0 mb-3" id="myTab" role="tablist">
                    <li
                        className="nav-item"
                        role="presentation"
                    >
                        <button
                            className={`nav-link loginBtn border-0 ${Active || passwordView ? "active" : ""}`}
                            onClick={() => {
                                setActive(true)
                                setIsNumber(false)
                                setPasswordView(false)
                                setotpid(false)
                            }}
                        >
                            Email
                        </button>
                    </li>
                    <li
                        className="nav-item"
                        role="presentation"
                    >
                        <button
                            className={`nav-link loginBtn border-0 cursor-pointer ${isNumber || optid ? "active" : ""}`}
                            onClick={() => {
                                setIsNumber(true)
                                setActive(false)
                                setPasswordView(false)
                                setotpid(false)
                            }}
                        >
                            Phone Number
                        </button>
                    </li>
                </ul>
            </div> */}
            {/* email/phone number  fields */}
            {/* {
                Active ? (
                    <EmailOrNumber
                        setPasswordView={setPasswordView}
                        setOtpId={setotpid}
                        setActive={setActive}
                        setEmailOrNumber={setEmailOrNumber}
                        emailOrNumber={emailOrNumber}
                        setUsername={setUsername}
                        setChecks={setChecks}
                        checks={checks}
                        Active={Active}
                    />
                ) : (
                    <></>
                )
            } */}
            {
                Active ? (
                    <EmailField
                        setPasswordView={setPasswordView}
                        setActive={setActive}
                        setUsername={setUsername}
                        setChecks={setChecks}
                        checks={checks}
                        Active={Active}
                        email={email}
                        setEmail={setEmail}
                        mobile={mobile} 
                        setMobile={setMobile}
                        referral={referral}
                        setReferral={setReferral}
                        isReferral={isReferral}
                        setIsReferral={setIsReferral}
                        setFullName={setFullName}
                    />
                ) : (
                    <></>
                )

            }
            {/* //---password field---// */}
            {
                passwordView ? (
                    <PasswordField
                        setPasswordView={setPasswordView}
                        setActive={setActive}
                        email={email}
                        username={username}
                        checks={checks}
                        passwordView={passwordView}
                        referral={referral}
                        fullName={fullName}
                        mobile={mobile} 
                    />
                ) : (
                    <></>
                )
            }
            {
                // isNumber ? (
                //     <NumberField
                //         setOtpId={setotpid}
                //         setActive={setActive}
                //         setUsername={setUsername}
                //         setChecks={setChecks}
                //         checks={checks}
                //         Active={Active}
                //         number={number}
                //         setNumber={setNumber}
                //         setIsNumber={setIsNumber}
                //         isNumber={isNumber}
                //         referral={referral}
                //         setReferral={setReferral}
                //         isReferral={isReferral}
                //         setIsReferral={setIsReferral}
                //     />
                // ) : (
                //     <></>
                // )
            }
            {/* //---opt field---// */}
            {
                // optid ? (
                //     <OtpField
                //         setActive={setActive}
                //         setOtpId={setotpid}
                //         username={username}
                //         checks={checks}
                //         optid={optid}
                //         referral={referral}
                //         number={number}
                //     />
                // ) : (
                //     <></>
                // )
            }

        </div>
    );
};

export default SignUpTab;
