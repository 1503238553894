import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import { adminGetAPI, adminGetAPIAuth, adminPostAPI, adminPostAPIAuth } from "../../AdminServices";
import UpdateCryptoDetails from "../cryptoDetails/UpdateCryptoDetails";
import AddCryptoDetails from "../cryptoDetails/AddCryptoDetails";
import { useSelector } from "react-redux";
import Pagination from "../../Admincomponents/pagination/Pagination";
import { succesToaster as successToaster } from "../../../utils/toaster";
import { getAPI } from "../../../service/apiInstance";


const Setting = () => {
    const adminDetails = useSelector(state => state.adminData)
    const[permissions, setPermissions]= useState([]);
    const [edit, setEdit] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [paymentData, setPaymentData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [id, setId] = useState('');

      const getPermission = async () => {

        try {
          const res = await getAPI("user/getMenuSettings");
          if (res?.data?.data?.success) {
            let data= res?.data.data.response;
            setId(data._id);
            let del=["createdAt","updatedAt","_id"]
            del.forEach(key => {
                delete data[key];
              });
          setPermissions(res?.data.data.response);
          }
        } catch (error) {
          console.log("error", error);
        }
  
    }
  
    useEffect(() => {
      getPermission();
    }, []);

    const handleChange=async(e)=>{
        const {name, checked}= e.target;
        // console.log(name, checked);
        setEdit(name)
        const updatedPermission = {
            [name]: checked
        };
        console.log(updatedPermission)
        try {
            const res = await adminPostAPIAuth(`user/updateMenuSettings?_id=${id}`,updatedPermission );
            if (res?.data?.data?.success) {
                setEdit(null)
                successToaster(res?.data?.data?.message);
                console.log(res);
                setPermissions((prev)=>({
                    ...prev,
                    [name]:checked
                }));
                getPermission();
            }
          } catch (error) {
            setEdit(null)
            console.log(error)
          } 
        }
           
        function formatString(str) {
            let result = str.charAt(0).toUpperCase() + str.slice(1);
            result = result.replace(/([a-z])([A-Z])/g, '$1 $2');
            return result;
          }
          
console.log(permissions)
    return (
        <>
            <div className="app">
                <main className="app-main">
                    <div className="wrapper">
                        <div className="page">
                            <div className="page-inner ">
                                <header className="page-title-bar justify-content-between row">
                                    <h1 className="page-title col-md">Settings</h1>
                                </header>
                                <div className="d-flex justify-content-between my-3">
                                    <div>
                                        <ol className="breadcrumb"></ol>
                                    </div>
                                </div>
                                <div className="page-section">
                                    <div className=" card-fluid">
                                        <div className="card-body px-0">
                                            <div className="table-responsive">
                                                <Table>
                                                   <thead>
                                                        <tr>
                                                            <th
                                                                style={{ minWidth: "140px", cursor: "pointer" }}
                                                                className="p-2 d-flex justify-content-evenly"
                                                            >
                                                                <span>S.No</span>
                                                            </th>

                                                            <th
                                                                style={{ minWidth: "150px", cursor: "pointer" }}
                                                                className="p-2"
                                                            >
                                                                <span>Menu Name</span>
                                                            </th>
                                                            <th
                                                                style={{ minWidth: "140px", cursor: "pointer" }}
                                                                className="p-2"
                                                            >
                                                                <span>Action</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                          {Object.values(permissions).length > 0 ? Object.entries(permissions).map(([item, value], index) => {

                                                          return(
                                                            <tr key={item?._id}>
                                                                <td className="align-middle  d-flex justify-content-evenly pt-3">{1+index}</td>
                                                                <td className="align-middle capitalize">{formatString(item)}</td>
                                                                <td className="align-middle">
                                                                   {edit===item ?
                                                                    <div class="spinner-border spinner-border-sm" role="status">
                                                                    <span class="visually-hidden">Loading...</span>
                                                                    </div>
                                                                    :
                                                                    <div className="form-check form-switch">
                                                                    <input name={item} className="form-check-input border border-dark" type="checkbox" id="flexSwitchCheckDefault" onChange={(e)=>handleChange(e)} checked={value}/>
                                                                    <label className="form-check-label text-dark" for="flexSwitchCheckDefault"></label>
                                                                    </div> 
                                                                   }
                                                               </td>
                                                                 </tr>
                                                        )}) :

                                                            <tr>
                                                                <td colSpan={6} className="text-center" style={{ fontSize: 16, fontWeight: 500 }}>No Data Found</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
};

export default Setting;
