export const ROUTES_CONST = {
    INDEX: '/',
    CASINO: '/games',
    SPORTS: '/sports',
    ACCOUNT: '/accounts',
    WALLET: '/wallet',
    PROMOTIONS: "/promotions",
    FAIRNESS: "/fairness",
    FORUM: "/forum",
    PARTNERS: "/partners",
    GLOBAL_SETTINGS: "/global-settings",
    AML_POLICY: "/aml-policy",
    Responsible_Gamble: "/responsible-gamble",
    BET_LIST: '/bet-history',
    MY_ACCOUNT_STATEMENT: '/my-account-statement',
    PROFIT_AND_LOSS_STATEMENT: '/profit-loss-statement',
    COMMISSION_REPORT: '/commission-statement',
    PLAY_SLOT: '/playslot',
    BUY_CRYPTO_URL: 'https://buy.onramper.com/',
    TERMS_AND_CONDITIONS: '/terms-and-conditions',
    RESPONSIBLE_GAMBLING: '/responsible-gambling',
    NEW_AML_POLICY: '/amlpolicy',
    DOWNLOAD_APP: '/download-app',
    AFFILIATE_PAGE: '/affiliate-page',
    SPORTS_POLICY: '/sports-policy',
    PROVIDERS: '/providers',
    PROMOTION_DETAIL: '/promotion-detail',
    COMING_SOON: '/coming-soon',
    SUBSCRIPTION_PAGE: '/subscription',
    SPORTS_SUBSCRIPTION: '/sports-subscription',
    JACKPOT: '/jackpot ',
    LOTTERY: "/jackpot",
    SUBSCRIBTION_BET_LIST: '/subscription-bet-history',
    SUBSCRIBTION_MY_BET_PAGE: '/subscription-mybets',
    MY_ACCOUNT_SUBSCRIBTION_STATEMENT: '/my-account-subscription-statement',
    COMMISSION_SUBSCRIBTION_REPORT: '/subscription-commission-statement',
    WIN_MORE: 'win-more',
    CASINO_HISTORY:"/casino-bet-history",
    MY_ACCOUNT_CASINO_STATEMENT:"/my-account-casino-statement",



    // sports page
    SPORTS_PAGE: '/sports-page',
    SPORTS_DETAIL_PAGE: '/sports-detail-page',
    SPORTS_MY_BET_PAGE: '/sports-mybets',



    // trading pages

    TRADING_WATCHLIST: '/trading/watchlist',
    TRADING_TRADES: '/trading/trades',
    TRADING_PORTFOLIO: '/trading/tradingPortfolio',
    TRADING_BLOCKED_SCRIPTS: '/trading/tradingBlockedScripted',
    TRADING_MAX_QUANTITY_DETAILS: '/trading/trangingMaxQuantityDetails',
    TRADING_MARGIN_MANAGEMENT: '/trading/TrandingMarginManagement',
    TRADING_SUMMARY_REPORT: '/trading/TradingSummaryReport',
    TRADING_HISTORY: '/trading/history',
    TRADING_Profit_Loss: '/trading/profitAndLoss',
    TRADING_ORDERS: '/trading/tradingOrders',


    // fantasy pages
    FANTASY_HOME: '/fantasy',
    FANTASY_DETAIL: '/fantasy-detail',
    CREATE_TEAM: '/fantasy/create-team',
    VIEW_TEAM: '/fantasy/view-team',
    SELECT_CAPTAIN_VICECAPTAIN: '/fantasy/select-captains',
    MY_MATCHES: '/fantasy/my-matches',
    MY_MATCHES_CONTEST: '/fantasy/my-matches-contest',
    FANTASY_WALLET: '/fantasy/wallet',
    FANTASY_DEPOSIT_CASH: '/fantasy/deposit-cash',
    FANTASY_TRANSACTION: '/fantasy/transaction',
    FANTASY_LEADERbOARD: '/fantasy/leaderboard',
    FANTASY_MATCH_DETAIL: '/fantasy/fantasy-match-detail',
    FANTASY_WITHDRAW: '/fantasy/withdraw',
    MY_TEAMS_PAGE: '/fantasy/my-teams-page',
    CONTEST_DETAILS_PAGE: '/fantasy/contest-details-page',
    FANTASY_SELECT_STOCKS_PAGE: '/fantasy/select-stocks-page',

    FANTASY_INVITE_FRIEND: '/fantasy/invite-friend',
    FANTASY_VERIFY_ACCOUNT: '/fantasy/verify-account',
    FANTASY_PROFILE: '/fantasy/profile',
    FANTASY_PLAY_INSIGHT_PROFILE: '/fantasy/play-insight',
    FANTASY_MORE: '/fantasy/more',
    FANTASY_CONTACT_HELP: '/fantasy/contact-help',
    FANTASY_TERM_AND_CONDITION: '/fantasy/terms-and-condition',
    FANTASY_MY_PORTFOLIO: '/fantasy/my-portfolio',
    FANTASY_NOTIFICATION: '/fantasy/notification',
    FANTASY_STOCK_USABLE_BALANCE: '/fantasy/Stock-usable-balance',
    FANTASY_STOCK_LIST: '/fantasy/stock-list',
    FANTASY_STOCK_LEADERBOARD: '/fantasy/stock-leaderBoard',

    // casino 
    CASINO_HOME: '/casino',
    CASINO_DETAILS: "/casino-details",
    CASINO_REPORTS: "/casino-report"
}