import React, { useState } from 'react'
import AppFooter from '../../components/AppFooter/AppFooter'
import { useEffect } from 'react';
import { getAPIAuth } from '../../service/apiInstance';
import DarkPagination from '../../components/darkPagination/DarkPagination';
import { useAuth } from '../../hooks/useAuth';

const filterBetsOptions = [
    {
        label: "All",
        value: "",
    },
    {
        label: "Open",
        value: "open",
    },
    {
        label: "won",
        value: "won",
    },
    {
        label: "lost",
        value: "lost",
    },
];


const BetListPage = () => {
    const [selectedBet, setSelectedBet] = useState('All');
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loader, setLoader] = useState(true);
    const [wonFilterData, setWonFilterData] = useState([])
    const [totalPages, setTotalPages] = useState(null);
    const { token } = useAuth();

    console.log("data", data);

    const getAllJackpotTickets = async () => {
        setLoader(true)
        try {
            let apiEndpoint = '';
            if (!selectedBet) {
                apiEndpoint = 'user/getAllJackpotTickets';
            } else if (selectedBet === 'All') {
                apiEndpoint = 'user/getAllJackpotTickets';
            } else if (selectedBet === 'open') {
                apiEndpoint = 'user/getAllJackpotTickets?status=open';
            } else if (selectedBet === 'won') {
                apiEndpoint = 'user/getAllJackpotTickets?status=won';
            } else if (selectedBet === 'lost') {
                apiEndpoint = 'user/getAllJackpotTickets?status=lost';
            }

            console.log("apiEndpointapiEndpointapiEndpoint", apiEndpoint);
            const res = await getAPIAuth(apiEndpoint, token);
            console.log('resresres', res)
            if (res?.data?.data?.success) {
                setWonFilterData(res?.data?.data?.response)
                setData(res?.data?.data?.response)
            }
        } catch (error) {
            setData([])
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        getAllJackpotTickets()
    }, [selectedBet, currentPage]);

    let wonCount = 0;
    let lostCount = 0;
    let OpenCount = 0;


    data?.forEach(item => {
        if (item.status === 'won') {
            wonCount++;
        } else if (item.status === 'lost') {
            lostCount++;
        } else if (item.status === 'open') {
            OpenCount++;
        }
    });

    return (
        <>

            <main className="main homepage tradingPages " id="main">
                <div className="row mx-0 mt-2">
                    <div className="col-12">
                        <div className="row gap-3">
                            <div className="col-12 px-0">
                                <div className="row g-3 align-items-center ">
                                    <div className="col-lg">
                                        <div className="tradingSecHead">All Tickets</div>
                                    </div>

                                    <div className="col-lg-2 col-md-3 col-6">
                                        <div className="reportsDropdownTitle">Filter Bets</div>
                                        <div className="dropdown reportsDropdown">
                                            <button className="btn btn-secondary marketBtn dropdown-toggle dropdownToggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                {selectedBet ? selectedBet : 'All'}
                                            </button>
                                            <ul className="dropdown-menu dropdownMenu w-100">
                                                {
                                                    filterBetsOptions?.map((item) => (
                                                        <li className={`menuLinkDrop ${item?.label === selectedBet ? 'active' : ''}`}
                                                            onClick={() => setSelectedBet(item?.label?.toLowerCase() === "all" ? '' : item?.label?.toLowerCase())}
                                                            key={item?.label}
                                                        >{item?.label}
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-12 px-0">
                                <div className="row pb-1 pt-1 g-3 align-items-center ">
                                    <div className="col-lg-3 col-sm-6 col-12">
                                        <div class="adminTopCard p-3">
                                            <div class="d-flex align-items-center">
                                                <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
                                                    <img src="assets/img/present.svg" alt="" />
                                                </div>
                                                <div class="">
                                                    <div class="adminTopCardTitle">Total Bets</div>
                                                    <div class="adminTopCardSubtitle">{data.length ? data.length : 0}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-12">
                                        <div class="adminTopCard p-3">
                                            <div class="d-flex align-items-center">
                                                <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
                                                    <img src="assets/img/present.svg" alt="" />
                                                </div>
                                                <div class="">
                                                    <div class="adminTopCardTitle">Won</div>
                                                    <div class="adminTopCardSubtitle">{wonCount ? wonCount : 0}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-12">
                                        <div class="adminTopCard p-3">
                                            <div class="d-flex align-items-center">
                                                <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
                                                    <img src="assets/img/present.svg" alt="" />
                                                </div>
                                                <div class="">
                                                    <div class="adminTopCardTitle">Lost</div>
                                                    <div class="adminTopCardSubtitle">{lostCount ? lostCount : 0}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-12">
                                        <div class="adminTopCard p-3">
                                            <div class="d-flex align-items-center">
                                                <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
                                                    <img src="assets/img/present.svg" alt="" />
                                                </div>
                                                <div class="">
                                                    <div class="adminTopCardTitle">Open</div>
                                                    <div class="adminTopCardSubtitle">{OpenCount ? OpenCount : 0}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 px-0 dambleTable reportsTable sectionInner tableCard tab-content tab-pane table-responsive mb-0 mt-4">
                        <table className="table align-middle text-white text-nowrap mb0">
                            <thead>
                                <tr>
                                    <th>
                                        <div className="tabletxt d-flex align-items-center">
                                            Sr No.
                                        </div>
                                    </th>
                                    {/* <th>
                        <div className="tabletxt d-flex align-items-center">
                          username
                        </div>
                      </th> */}
                                    <th>
                                        <div className="tabletxt d-flex align-items-center">
                                            Jackpot Name
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tabletxt d-flex align-items-center">
                                            Image
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tabletxt d-flex align-items-center">
                                            Ticket Number
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tabletxt d-flex align-items-center">
                                            Ticket Price
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tabletxt d-flex align-items-center">
                                            Winning Amount
                                        </div>
                                    </th>
                                    <th>
                                        <div className={`tabletxt d-flex align-items-center`}>
                                            Status
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !loader ?
                                        data?.length > 0 ?
                                            data?.map((item, index) => (

                                                <tr key={item._id} className={item.status === 'won' ? 'won' : item.status === 'lost' ? 'lost' : 'open'}>
                                                    <td>{index + 1}</td>
                                                    <td>{item?.jackpotData?.name}</td>
                                                    <td className='all-data-img'>
                                                        <img src={item?.jackpotImage} alt="" />
                                                    </td>
                                                    <td>{item?.ticketNumber}</td>
                                                    <td>{item?.jackpotData?.ticket_price}</td>
                                                    <td>{item?.jackpotData?.winning_amount}</td>
                                                    <td className='status'>{item?.status}</td>

                                                </tr>
                                            ))
                                            : <tr>
                                                <td colSpan={10} className='text-center'>No Data</td>
                                            </tr>
                                        : 'loading'
                                }
                            </tbody>
                        </table>
                    </div>
                    {
                        totalPages > 1 ?
                            <DarkPagination
                                totalPages={totalPages}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                            : ''
                    }
                </div>

            </main>
        </>
    )
}

export default BetListPage
