import React, { useEffect, useState } from 'react'
import AppFooter from '../../../components/AppFooter/AppFooter'
import BetSlipOffcanvas from '../../sportsComponents/betSlipOffcanvas/BetSlipOffcanvas'
import { sportSocket } from '../../sportsServices/sportsSocket';
import { sportsGetAPIAuth, sportsPostAPIAuth } from '../../sportsServices/index';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { useAuthModal } from '../../../hooks/useAuthModal';
import { AUTH_MODAL_TABS } from '../../../constants';
import RelatedMatches from './RelatedMatches';
import { errorToaster, succesToaster } from '../../../utils/toaster';
import { formatNumberInK } from '../../../utils/returnsNumberInK';
import { getAPIAuth } from '../../../service/apiInstance';
import MatchScore from './MatchScore';
import refreshIcon from './../../sportsAssets/img/refresh.png'

const SportsDetailPage = () => {
  const [matchBetsData, setMatchBetsData] = useState();
  const [betAmount, setBetAmount] = useState(0);
  const [betInputValue, setBetInputValue] = useState();
  const [selectionId, setSelectionId] = useState('');
  const [betType, setBetType] = useState('');
  const [profit, setProfit] = useState(0);
  const [sectionName, setSectionName] = useState('');
  const [relatedMatches, setRelatedMatches] = useState([]);
  const [relatedMatchLoader, setRelatedMatchLoader] = useState(true)
  const id = useParams()?.matchId
  const [matchId, eventId, marketId] = id.split("|+|")

  const [indMarketId, setIndMarketId] = useState("")
  const { token } = useAuth()
  const { authModalObject, setAuthModalObject } = useAuthModal()
  const [isApplyingBet, setIsApplyingBet] = useState(false)
  const [isBetOpen, setIsBetOpen] = useState(false)
  const [oddsType, setOddsType] = useState('')
  const [marketName, setMarketName] = useState("")
  const [bookmakerComm, setBookmakerComm] = useState([]);
  const [bookmakerToss, setBookmakerToss] = useState([]);
  const [bookmakerTied, setBookmakerTied] = useState([]);
  const [showBet, setShowBet] = useState(false)
  const [openBets, setOpenBets] = useState([])
  const [betLoader, setBetLoader] = useState(false)
  const [itemIndex, setItemIndex] = useState(5)
  const [parentIndex, setParentIndex] = useState(5)
  const [arrType, setArrType] = useState('')
  const [secType, setSecType] = useState('')
  const [fancyKeys, setFancyKeys] = useState([])
  const [selectedFancyKey, setSelectedFancyKey] = useState('');
  const [fancyData, setFancyData] = useState([])
  const [blink, setBlink] = useState(false)
  const [previous, setPrevious] = useState({})
  const [previousOverUnderFootball, setpreviousOverUnderFootball] = useState([]);
  const [matchOddsFootBallTennis, setMatchOddsFootBallTennis] = useState({});
  const [overUnderDataFootball, setOverUnderDataFootball] = useState([]);

  const dummyOdds = [
    {
      price: 0,
      size: 0,
      level: 0
    },
    {
      price: 0,
      size: 0,
      level: 0
    },
    {
      price: 0,
      size: 0,
      level: 0
    }
  ]

  const getBetDelay = async () => {
    if (token) {
      try {
        const res = await getAPIAuth(`bet/getBetDelay`, token)
        // console.log('ressssssssssssssssss', res)
      } catch (error) {

      }
    }
  }

  useEffect(() => {
    getBetDelay()
  }, [])//eslint-disable-line

  const getOpenBets = async () => {
    if (matchId) {
      try {
        const res = await getAPIAuth(`bet/mybets?status=open&page=1&perPage=100&matchId=${matchId}`, token)
        if (res?.data?.data?.success) {
          setOpenBets(res?.data?.data?.data)
        }
      } catch (error) {

      }
    }
  }


  // for fancy section
  const returnExposerAmount = (id) => {
    if (openBets?.length > 0) {
      let total = 0;
      const data = openBets?.filter((item) => item?.selectionId === id)
      for (let i = 0; i < data?.length; i++) {
        if (data?.[i]?.betType === "back" || data?.[i]?.betType === "yes") {
          total += data?.[i]?.potentialWin
        } else if (data?.[i]?.betType === "lay" || data?.[i]?.betType === "no") {
          total -= data?.[i]?.potentialWin
        }
      }
      if (total === 0) return

      else return (
        <span className={`backLayVal ms-2 ${total > 0 ? 'blueVal' : 'pinkVal'}`}>{total?.toFixed(0)}</span>
      )
    }
  }


  // for odds, bookmark and toss section
  const returnExposerAmountt = (sid, mid) => {
    let total = 0;
    let wintotal = 0;
    let amounttotal = 0;
    if (openBets?.length > 0) {
      const marketData = openBets?.filter((item) => item?.marketId === mid)
      for (let i = 0; i < marketData?.length; i++) {
        if (marketData?.[i]?.selectionId == sid) {
          // console.log('params wintotal', marketData?.[i]?.selectionId,   sid)
          if (marketData?.[i]?.betType === "back") {
            wintotal += marketData?.[i]?.potentialWin
          } else {
            wintotal -= marketData?.[i]?.amount
          }
        } else {
          if (marketData?.[i]?.betType === "back") {
            amounttotal -= marketData?.[i]?.amount
          } else {
            amounttotal += marketData?.[i]?.potentialWin
          }

        }
      }
      total = wintotal + amounttotal
      // console.log('params inside open bets', total, wintotal, amounttotal)

      // return <span className={`backLayVal ms-2 ${total > 0 ? 'blueVal' : 'pinkVal'}`}>{total?.toFixed(0)}</span>
      return total
      // <span className={`backLayVal ms-2 ${total > 0 ? 'blueVal' : 'pinkVal'}`}>{total?.toFixed(0)}</span>
    }
  }


  useEffect(() => {
    getOpenBets()
  }, [matchId]) //eslint-disable-line


  // https://stream.1ex99.com/sportRadarScore?eventId=32887420


  useEffect(() => {
    if (betType === "back") {
      applyProfit(betInputValue);
    } else {
      applyLayProfit(betInputValue)
    }
    //eslint-disable-next-line
  }, [betAmount, betType])

  useEffect(() => {
    // console.log('ready for socket emit');
    let socketInterval;
    if (id) {
      const fetchData = () => {
        // setPrevious(matchBetsData?.[0]?.matchodds?.[0]?.runners)
        // sportSocket.emit('matchDetails', { _id: "6530dccb291ec90f34117e65" });
        sportSocket.emit('matchDetails', { _id: matchId });
        // sportSocket.on(`match-32727482`, (data) => {
        sportSocket.on(`match-${eventId}`, (data) => {

          setMatchBetsData(data);
          if (data?.[0]?.gameId === "1" || data?.[0]?.gameId === "2") {
            const filter = data?.[0]?.matchodds?.filter((item) => item.market === "Match Odds")
            setMatchOddsFootBallTennis(filter[0]);
          }

          if (data?.[0]?.gameId === "1") {
            const filter = data?.[0]?.matchodds?.filter((item) => item.market !== "Match Odds")
            setOverUnderDataFootball(filter);
          }

        });

      };

      fetchData();
      socketInterval = setInterval(fetchData, 1000);
    }

    return () => {
      if (id) {
        clearInterval(socketInterval);
      }
    };
    //eslint-disable-next-line
  }, [id]);


  console.log('matchhhhhhh', matchBetsData)


  useEffect(() => {
    setBlink(true);
    let timeout = setTimeout(() => {
      setBlink(false);
      setPrevious(matchBetsData?.[0]?.matchodds?.[0]?.runners)
      setpreviousOverUnderFootball(overUnderDataFootball);
    }, 300);

    return () => {
      // if (previous?.bid_price !== apiTableData?.bid_price) {
      clearTimeout(timeout);
      // }
    }
  }, [matchBetsData]);//eslint-disable-line

  // useEffect(()=> {
  //   const updatedPrice = matchBetsData?.allOddsList?.Odds[0]?.runners?.[0]?.ex?.availableToBack?.[index]
  //   console.log('updatedPrice', updatedPrice)
  // }, [matchBetsData?.allOddsList])
  /** set the required values */
  const setBettingSystem = (type, price, selectionId, sectionName, oddType, marketN, iMarketId,) => {
    setBetAmount(price);
    setBetType(type);
    setSelectionId(selectionId);
    setProfit(0);
    setSectionName(sectionName);
    setBetInputValue("")
    setIsBetOpen(true)
    setOddsType(oddType)
    setMarketName(marketN)
    setIndMarketId(iMarketId)
    // setItemIndex(index)
  }

  /** calculate the profit amount */
  const applyProfit = (inputValue) => {
    setProfit((inputValue * betAmount) - inputValue);
  }

  const applyLayProfit = (inputValue) => {
    setProfit(inputValue * (betAmount - 1))
  }
  /** configure the input value */
  const changeBetInputValue = (inputValue) => {
    setBetInputValue(inputValue);
    if (oddsType === "bookmakers" || oddsType === "fancy") {
      if (betType === "back" || betType === "yes") {
        setProfit((inputValue * betAmount) / 100);
      } else {
        setProfit((inputValue * betAmount) / 100)
      }
    } else {
      if (betType === "back") {
        applyProfit(inputValue);
      } else {
        applyLayProfit(inputValue)
      }
    }
  }

  const changeBetAmount = (value) => {
    setBetAmount((pre) => pre + value);
  };
  // get related matches

  const getRelatedMatches = async () => {
    setRelatedMatchLoader(true)
    try {
      const res = await sportsGetAPIAuth(
        `bet/getMatchList?seriesId=${matchBetsData?.seriesId}`
      )
      if (res?.data?.data?.success) {
        setRelatedMatches(res?.data?.data?.data);
      }
    } catch (error) {
    } finally {
      setRelatedMatchLoader(false);
    }
  };

  useEffect(() => {
    if (matchBetsData?.seriesId) {
      getRelatedMatches()
    }
    //eslint-disable-next-line
  }, [matchBetsData?.seriesId])
  /** bet apply  */
  const applyBet = async () => {
    if (!betInputValue) {
      errorToaster("Please add amount to stack")
      return
    }
    if (!oddsType) {
      errorToaster("Please select bet")
      return
    }
    if (token) {
      setBetLoader(true)
      setTimeout(() => {
        placigBet()
      }, 3000);
    } else {
      setAuthModalObject({
        isAuthOpen: true,
        selectedTab: AUTH_MODAL_TABS.LOG_IN
      })
    }
  }

  // console.log('matchBetsData', matchBetsData)
  const placigBet = async () => {
    let updatedPrice = 0;
    try {
      if (secType === 'oddsSection') {
        const dataClone = structuredClone(matchBetsData?.[0]?.matchodds?.[0]?.runners)
        if (arrType === 'availableToBack') {
          updatedPrice = dataClone?.[parentIndex]?.ex?.availableToBack?.reverse()?.[itemIndex]?.price
        } else if (arrType === 'availableToLay') {
          updatedPrice = dataClone?.[parentIndex]?.ex?.availableToLay?.[itemIndex]?.price
        }
      } else if (secType === 'bookmakerSec') {
        if (arrType === 'availableToBack') {
          updatedPrice = matchBetsData?.[0]?.bookmakersOdds?.[0]?.runners?.[parentIndex]?.back?.[0]?.price
        } else if (arrType === 'availableToLay') {
          updatedPrice = matchBetsData?.[0]?.bookmakersOdds?.[0]?.runners?.[parentIndex]?.lay?.[0]?.price
        }
      } else if (secType === 'bookmakerTossSec') {
        if (arrType === 'availableToBack') {
          updatedPrice = matchBetsData?.allOddsList?.Bookmaker?.[parentIndex]?.b1
        } else if (arrType === 'availableToLay') {
          updatedPrice = matchBetsData?.allOddsList?.Bookmaker?.[parentIndex]?.l1
        }
      } else if (secType === 'fancySec') {
        if (arrType === 'availableToBack') {
          if (selectedFancyKey === 'oddeven' || selectedFancyKey === 'fancy1') {
            updatedPrice = fancyData?.[parentIndex]?.BackPrice1
          } else {
            updatedPrice = fancyData?.[parentIndex]?.BackSize1
          }
        } else if (arrType === 'availableToLay') {
          if (selectedFancyKey === 'oddeven' || selectedFancyKey === 'fancy1') {
            updatedPrice = fancyData?.[parentIndex]?.LayPrice1
          } else {
            updatedPrice = fancyData?.[parentIndex]?.LaySize1
          }
        }
      }
      // console.log('updatedPrice', updatedPrice, parentIndex)
      const params = {
        stake: betInputValue,
        // matchId: "6530dccb291ec90f34117e65",
        packageSubscription: null,
        matchId: matchId,
        marketId: oddsType === "odds" ? matchBetsData?.[0]?.matchodds?.[0]?.marketId : indMarketId,
        selectionId: selectionId,
        odds: Number(updatedPrice),
        type: oddsType,
        betType: betType,
        marketType: marketName,
        outcomeDesc: matchBetsData?.[0]?.event?.name,
        marketName: marketName,
        eventId: matchBetsData?.[0]?.eventId,

      }
      //   {
      //     "amount": 1000,
      //     "matchId": "653f2c891e72577aca7dfc2a",
      //     "marketId": "1.220184750",
      //     "selectionId": "7337",
      //     "potentialAmount": 110,
      //     "odds":"1.90",
      //     "type":"odds"
      // }
      // type: odds || bookmakers || fancy


      const res = await sportsPostAPIAuth('bet/place', params, token);
      if (res?.status) {
        setBetAmount(0);
        setBetType('');
        setSelectionId("");
        setProfit(0);
        setBetInputValue("");
        setSectionName('');
        succesToaster(res?.data?.data?.message)
        getOpenBets()

      } else {
        errorToaster(res.data.message)
      }

    } catch (error) {
      console.log('catch error', error)

      if (error.message === "Request failed with status code 401") return errorToaster("Unauthorized error")
      errorToaster(error?.response?.data?.data?.message)
    } finally {
      setBetLoader(false)
    }
  }

  useEffect(() => {
    const data = structuredClone(matchBetsData?.[0]?.bookmakersOdds);
    const bookmakerCommData = data?.filter(
      (item) => item?.mname === "Bookmaker"
    );
    const bookmakerTiedData = data?.filter(
      (item) => item?.mname === "Tied Match"
    );
    const bookmakerTossData = data?.filter((item) => item?.mname === "TOSS");
    setBookmakerComm(bookmakerCommData);
    setBookmakerToss(bookmakerTossData);
    setBookmakerTied(bookmakerTiedData)
  }, [matchBetsData?.[0]?.bookmakersOdds?.[0]]);


  useEffect(() => {
    const data = structuredClone(matchBetsData?.[0]?.matchfancies)

    const gTypeSet = new Set();

    data?.forEach(obj => {
      gTypeSet?.add(obj?.gtype)
    })

    const gTypeArray = [...gTypeSet]
    setFancyKeys(gTypeArray)
    // setSelectedFancyKey(gTypeArray?.[0])

  }, [matchBetsData?.[0]?.matchfancies])//eslint-disable-line

  useEffect(() => {
    if (selectedFancyKey) {
      return
    } else {
      setSelectedFancyKey(fancyKeys?.[0])

    }
  }, [fancyKeys, selectedFancyKey])

  useEffect(() => {
    const data = structuredClone(matchBetsData?.[0]?.matchfancies)
    const filteredData = data?.filter(item => item?.gtype?.toLowerCase() === selectedFancyKey?.toLowerCase())
    setFancyData(filteredData)
  }, [selectedFancyKey])//eslint-disable-line





  // in case there are less than 3 data in match odds
  const returnMatchOdds = (data) => {
    switch (data?.length) {
      case 3:
        return data;
      case 2:
        return [...data, dummyOdds?.[0]]
      case 1:
        return [...data, dummyOdds?.[0], dummyOdds?.[1]];
      case 0:
        return [...dummyOdds];
      default:
        return data;
    }
  }


  const renderOddsSection = () => {
    const matchData = matchBetsData?.[0]?.gameId === "1" || matchBetsData?.[0]?.gameId === "2" ? matchOddsFootBallTennis : matchBetsData?.[0]?.matchodds?.[0];
    return (
      <>
        <div className="matchBettingSection">
          <div className="matchTextSection">
            <div className="matchTxtSecTitle"> Match Odds</div>
            {/* <div className='matchTxtSecVal'>{`MIN: ${matchData?.Odds[0]?.minBet} MAX: ${matchData?.Odds[0]?.maxBet}`}</div> */}
            <div className="matchTxtSecVal">
              {matchData?.matchodds
                ? `MIN: ${matchData?.minBet ? matchData?.minBet : "--"}`
                : "MIN: ---"}{" "}
              {matchData?.matchodds
                ? ` MAX: ${matchData?.maxBet ? matchData?.maxBet : "--"}`
                : "MAX: ---"}
            </div>
          </div>
        </div>
        <div className="matchOddsDetailsSection d-flex align-items-center justify-content-between">
          <div className="matchesBetLayFirst">
            Market
          </div>
          <div className="d-flex marketBtnSec">
            <div className="matchesBackSection d-flex align-items-center justify-content-center">
              <span>BACK</span>
            </div>
            <div className="matchesLaySection text-uppercase d-flex align-items-center justify-content-center">
              <span>LAY</span>
            </div>
          </div>
        </div>
        <div className="matchesBetLaySection">
          {matchData?.runners &&
            matchData?.runners?.map((oddsdata, pIndex) => {
              let exposerval = returnExposerAmountt(oddsdata?.selectionId, matchBetsData?.[0]?.matchodds?.[0]?.marketId)
              return (
                <div
                  className="matchesBetLayContainer isResp"
                  key={oddsdata?.selectionId}
                >
                  <div className="matchesBetLayFirst">
                    {oddsdata?.runner}
                    {exposerval ?
                      <span className={`backLayVal ms-2 ${exposerval > 0 ? 'blueVal' : 'pinkVal'}`}>
                        {returnExposerAmountt(oddsdata?.selectionId, matchBetsData?.[0]?.matchodds?.[0]?.marketId)?.toFixed(0)}
                      </span>
                      : ''}
                  </div>
                  <div
                    className={`matchesBetLaySecond ${matchData?.matchodds?.[0].betlock ? "disabled" : ""
                      }`}
                  >
                    {
                      exposerval ? <>
                        {sectionName === "odds" && (
                          <span >
                            {selectionId === oddsdata?.selectionId ? (
                              <>
                                {betInputValue && (betType === "back" ?
                                  <span className={(exposerval + Number(profit?.toFixed(0))) > 0 ? "text-success" : "text-danger"} >
                                    {exposerval + Number(profit?.toFixed(0))}
                                  </span> :
                                  <span className={(exposerval - Number(profit?.toFixed(0))) > 0 ? "text-success" : "text-danger"} >
                                    {exposerval - Number(profit?.toFixed(0))}
                                  </span>)}
                              </>
                            ) : (
                              <>
                                {betInputValue && (betType === "back" ?
                                  <span className={(exposerval - betInputValue) > 0 ? "text-success" : "text-danger"} >
                                    {exposerval - betInputValue}
                                  </span> :
                                  <span className={(exposerval + betInputValue) > 0 ? "text-success" : "text-danger"} >
                                    {exposerval + betInputValue}
                                  </span>)}
                              </>
                            )}
                          </span>
                        )}
                      </> : <>
                        {sectionName === "odds" && (
                          <span >
                            {selectionId === oddsdata?.selectionId ? (
                              <span className={betType === "back" ? "text-success" : "text-danger"} >
                                {betInputValue && (betType === "back"
                                  ? `+ ${profit.toFixed(2)}`
                                  : `- ${profit?.toFixed(2)}`)}
                              </span>
                            ) : (
                              <span className={betType !== "back" ? "text-success" : "text-danger"} >
                                {betInputValue && (betType === "back"
                                  ? `- ${betInputValue}`
                                  : `+ ${betInputValue}`)}
                              </span>
                            )}
                          </span>
                        )}
                      </>
                    }
                    {returnMatchOdds([...oddsdata?.ex?.availableToBack])?.reverse().map((back, index) => {
                      // console.log('oddsdata', oddsdata?.ex?.availableToBack)
                      return (
                        <>
                          <div
                            key={back?.price}
                            className={`matchesBackSection short ${back?.price && back?.size ? " " : "disabled "
                              } ${blink && previous?.length && (previous?.[pIndex]?.ex?.availableToBack)?.reverse()?.[index]?.price !== back?.price ? 'blink' : ''}`}
                            onClick={() => {
                              // type, price, selectionId, sectionName, oddType,marketN,iMarketId
                              setBettingSystem(
                                "back",
                                back?.price,
                                oddsdata?.name === "The Draw" ? 'draw' : oddsdata?.selectionId,
                                "odds",
                                "odds",
                                matchData?.markettype,
                              )
                              setSecType('oddsSection')
                              setArrType('availableToBack')
                              setItemIndex(index)
                              setParentIndex(pIndex)
                              setShowBet(true)
                            }

                            }
                          >
                            {/* {console.log('paramsssssss', matchData)} */}
                            {/* {
                            previous?.length ?
                            
                            <span>{([...previous?.[pIndex]?.ex?.availableToBack])?.reverse()?.[index]?.price}</span>
                             : ''
                          } */}
                            <span> {back?.price} </span>
                            <span> {formatNumberInK(Number(back?.size?.toFixed(2)))} </span>
                          </div>
                        </>
                      );
                    })}
                    {returnMatchOdds(oddsdata?.ex?.availableToLay)?.map((lay, index) => {
                      return (
                        <div
                          className={`matchesLaySection short ${lay?.price && lay?.size ? " " : "disabled "
                            } ${blink && previous?.length && previous?.[pIndex]?.ex?.availableToLay?.[index]?.price !== lay?.price ? 'blink' : ''}`}
                          onClick={() => {
                            setBettingSystem(
                              "lay",
                              lay?.price,
                              oddsdata?.name === "The Draw" ? 'draw' : oddsdata?.selectionId,
                              "odds",
                              "odds",
                              matchData?.markettype,
                            )
                            setSecType('oddsSection')
                            setArrType('availableToLay')
                            setItemIndex(index)
                            setParentIndex(pIndex)
                            setShowBet(true)
                          }
                          }
                          key={lay?.price}
                        >
                          <span> {lay?.price} </span>
                          <span>{formatNumberInK(Number(lay?.size?.toFixed(2)))} </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      </>
    );
  };

  // for football-------------------------------------------------->
  const renderOverUnderSectionFootball = (matchData, dataIndex) => {
    // const matchData =matchBetsData?.[0]?.matchodds?.[0];
    return (
      <>
        <div className="matchBettingSection">
          <div className="matchTextSection">
            <div className="matchTxtSecTitle">{matchData?.market}</div>
            {/* <div className='matchTxtSecVal'>{`MIN: ${matchData?.Odds[0]?.minBet} MAX: ${matchData?.Odds[0]?.maxBet}`}</div> */}
            <div className="matchTxtSecVal">
              {matchData?.matchodds
                ? `MIN: ${matchData?.minBet ? matchData?.minBet : "--"}`
                : "MIN: ---"}{" "}
              {matchData?.matchodds
                ? ` MAX: ${matchData?.maxBet ? matchData?.maxBet : "--"}`
                : "MAX: ---"}
            </div>
          </div>
        </div>
        <div className="matchOddsDetailsSection d-flex align-items-center justify-content-between">
          <div className="matchesBetLayFirst">
            Market
          </div>
          <div className="d-flex marketBtnSec">
            <div className="matchesBackSection d-flex align-items-center justify-content-center">
              <span>BACK</span>
            </div>
            <div className="matchesLaySection text-uppercase d-flex align-items-center justify-content-center">
              <span>LAY</span>
            </div>
          </div>
        </div>
        <div className="matchesBetLaySection">
          {matchData?.runners &&
            matchData?.runners?.map((oddsdata, pIndex) => {
              let exposerval = returnExposerAmountt(oddsdata?.selectionId, matchBetsData?.[0]?.matchodds?.[0]?.marketId)
              return (
                <div
                  className="matchesBetLayContainer isResp"
                  key={oddsdata?.selectionId}
                >
                  <div className="matchesBetLayFirst">
                    {oddsdata?.runner}
                    {exposerval ?
                      <span className={`backLayVal ms-2 ${exposerval > 0 ? 'blueVal' : 'pinkVal'}`}>
                        {returnExposerAmountt(oddsdata?.selectionId, matchBetsData?.[0]?.matchodds?.[0]?.marketId)?.toFixed(0)}
                      </span>
                      : ''}
                  </div>
                  <div
                    className={`matchesBetLaySecond ${matchData?.matchodds?.[0].betlock ? "disabled" : ""
                      }`}
                  >
                    {
                      exposerval ? <>
                        {sectionName === matchData?.market && (
                          <span >
                            {selectionId === oddsdata?.selectionId ? (
                              <>
                                {betInputValue && (betType === "back" ?
                                  <span className={(exposerval + Number(profit?.toFixed(0))) > 0 ? "text-success" : "text-danger"} >
                                    {exposerval + Number(profit?.toFixed(0))}
                                  </span> :
                                  <span className={(exposerval - Number(profit?.toFixed(0))) > 0 ? "text-success" : "text-danger"} >
                                    {exposerval - Number(profit?.toFixed(0))}
                                  </span>)}
                              </>
                            ) : (
                              <>
                                {betInputValue && (betType === "back" ?
                                  <span className={(exposerval - betInputValue) > 0 ? "text-success" : "text-danger"} >
                                    {exposerval - betInputValue}
                                  </span> :
                                  <span className={(exposerval + betInputValue) > 0 ? "text-success" : "text-danger"} >
                                    {exposerval + betInputValue}
                                  </span>)}
                              </>
                            )}
                          </span>
                        )}
                      </> : <>
                        {sectionName === matchData?.market && (
                          <span >
                            {selectionId === oddsdata?.selectionId ? (
                              <span className={betType === "back" ? "text-success" : "text-danger"} >
                                {betInputValue && (betType === "back"
                                  ? `+ ${profit.toFixed(2)}`
                                  : `- ${profit?.toFixed(2)}`)}
                              </span>
                            ) : (
                              <span className={betType !== "back" ? "text-success" : "text-danger"} >
                                {betInputValue && (betType === "back"
                                  ? `- ${betInputValue}`
                                  : `+ ${betInputValue}`)}
                              </span>
                            )}
                          </span>
                        )}
                      </>
                    }
                    {returnMatchOdds([...oddsdata?.ex?.availableToBack])?.reverse().map((back, index) => {

                      return (
                        <>
                          <div
                            key={back?.price}
                            className={`matchesBackSection short ${back?.price && back?.size ? " " : "disabled "
                              } ${blink && previousOverUnderFootball?.length && ([...previousOverUnderFootball?.[dataIndex]?.runners?.[pIndex]?.ex?.availableToBack])?.reverse()?.[index]?.price !== back?.price ? 'blink' : ''}`}
                            onClick={() => {
                              // type, price, selectionId, sectionName, oddType,marketN,iMarketId
                              setBettingSystem(
                                "back",
                                back?.price,
                                oddsdata?.name === "The Draw" ? 'draw' : oddsdata?.selectionId,
                                matchData?.market,
                                matchData?.market,
                                matchData?.markettype,
                                matchData?.marketId
                              )
                              setSecType('oddsSection')
                              setArrType('availableToBack')
                              setItemIndex(index)
                              setParentIndex(pIndex)
                              setShowBet(true)
                            }

                            }
                          >
                            {/* {console.log('paramsssssss', matchData)} */}
                            {/* {
                            previous?.length ?
                            
                            <span>{([...previous?.[pIndex]?.ex?.availableToBack])?.reverse()?.[index]?.price}</span>
                             : ''
                          } */}
                            <span> {back?.price} </span>
                            <span> {formatNumberInK(Number(back?.size?.toFixed(2)))} </span>
                          </div>
                        </>
                      );
                    })}
                    {returnMatchOdds(oddsdata?.ex?.availableToLay)?.map((lay, index) => {
                      return (
                        <div
                          className={`matchesLaySection short ${lay?.price && lay?.size ? " " : "disabled "
                            } ${blink && previousOverUnderFootball?.length && previousOverUnderFootball?.[dataIndex]?.runners?.[pIndex]?.ex.availableToLay?.[index]?.price !== lay?.price ? 'blink' : ''}`}
                          onClick={() => {
                            setBettingSystem(
                              "lay",
                              lay?.price,
                              oddsdata?.name === "The Draw" ? 'draw' : oddsdata?.selectionId,
                              "odds",
                              "odds",
                              matchData?.markettype,
                            )
                            setSecType('oddsSection')
                            setArrType('availableToLay')
                            setItemIndex(index)
                            setParentIndex(pIndex)
                            setShowBet(true)
                          }
                          }
                          key={lay?.price}
                        >
                          <span> {lay?.price} </span>
                          <span>{formatNumberInK(Number(lay?.size?.toFixed(2)))} </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      </>
    );
  };

  const renderBookMarkSection0Comm = () => {
    // const matchData = matchBetsData?.[0]?.bookmakersOdds?.[0];
    return (
      <>
        <div className="matchBettingSection">
          <div className="matchTextSection">
            <div className="matchTxtSecTitle"> Bookmaker</div>
            <div className="matchTxtSecVal">
              {/* {`MIN: ${matchData?.Bookmaker[0]?.minBet} MAX: ${matchData?.Odds[0]?.maxBet}`} */}
              {bookmakerComm?.[0]?.min
                ? `MIN: ${bookmakerComm?.[0]?.min}`
                : "MIN: ---"}{" "}
              {bookmakerComm?.[0]?.max
                ? `MAX: ${bookmakerComm?.[0]?.max}`
                : "MAX: ---"}
            </div>
          </div>
        </div>
        <div className="matchOddsDetailsSection d-flex align-items-center justify-content-between">
          <div className="matchesBetLayFirst"> Market</div>

          <div className="d-flex" style={{ gap: '2px' }}>
            <div className="matchesBackSection d-flex align-items-center justify-content-center shorttt">
              <span>BACK</span>
            </div>
            <div className="matchesLaySection text-uppercase d-flex align-items-center justify-content-center shorttt">
              <span>LAY</span>
            </div>
          </div>
        </div>
        <div className="matchesBetLaySection">
          {bookmakerComm?.[0]?.runners?.map((bookmakerData, pIndex) => {
            let exposerval = returnExposerAmountt(bookmakerData?.selectionId, bookmakerComm?.[0]?.marketId);
            return (
              <div className="matchesBetLayContainer" key={bookmakerData?.sid}>
                <div className="matchesBetLayFirst">
                  {bookmakerData?.runnerName}
                  {
                    exposerval ?
                      <span className={`backLayVal ms-2 ${exposerval > 0 ? 'blueVal' : 'pinkVal'}`}>
                        {returnExposerAmountt(bookmakerData?.selectionId, bookmakerComm?.[0]?.marketId)?.toFixed(0)}
                      </span>
                      : ''
                  }
                </div>
                <div
                  className={`matchesBetLaySecond  ${bookmakerData.status === "SUSPENDED" ? "suspend" : ""
                    }`}
                >
                  {sectionName === "Bookmaker" && (
                    <>
                      {exposerval ? <>
                        {selectionId === bookmakerData?.selectionId ? (
                          <>
                            {betInputValue && (betType === "back" ?
                              <span className={(exposerval + Number(profit?.toFixed(0))) > 0 ? "text-success" : "text-danger"} >
                                {exposerval + Number(profit?.toFixed(0))}
                              </span> :
                              <span className={(exposerval - Number(profit?.toFixed(0))) > 0 ? "text-success" : "text-danger"} >
                                {exposerval - Number(profit?.toFixed(0))}
                              </span>)}
                          </>
                        ) : (
                          <>
                            {betInputValue && (betType === "back" ?
                              <span className={(exposerval - betInputValue) > 0 ? "text-success" : "text-danger"} >
                                {exposerval - betInputValue}
                              </span> :
                              <span className={(exposerval + betInputValue) > 0 ? "text-success" : "text-danger"} >
                                {exposerval + betInputValue}
                              </span>)}
                          </>
                        )}
                      </> : <>
                        {selectionId === bookmakerData?.selectionId ? (
                          <span className={betType === "back" ? "text-success bb" : "text-danger bb"}>
                            {/* {console.log('bookmakerData?.sid', bookmakerData?.selectionId , selectionId)} */}
                            {betInputValue && (betType === "back"
                              ? `+ ${profit?.toFixed(2)}`
                              : `- ${profit?.toFixed(2)}`)}
                          </span>
                        ) : (
                          <span className={betType !== "back" ? "text-success tt" : "text-danger tt"}>
                            {betInputValue && (betType === "back"
                              ? `- ${betInputValue}`
                              : `+ ${betInputValue}`)}
                          </span>
                        )}
                      </>}
                    </>
                  )}
                  <div className="position-relative d-flex" style={{ gap: 2 }}>
                    <div className="suspendDiv d-none">
                      {bookmakerData?.status}
                    </div>
                    <div
                      className={`matchesBackSection ${bookmakerData?.betlock ? "opacityClass" : ""
                        } ${bookmakerData?.back?.[0]?.price && bookmakerData?.back?.[0]?.price !== "0"
                          ? " "
                          : "disabled "
                        } `}
                      onClick={() => {
                        // type, price, selectionId, sectionName, oddType,marketN,iMarketId
                        if (!bookmakerData?.betlock) {
                          setBettingSystem(
                            "back",
                            bookmakerData?.back?.[0]?.price,
                            bookmakerData?.mname === "The Draw" ? 'draw' : bookmakerData?.selectionId,
                            "Bookmaker",
                            "bookmakers",
                            bookmakerComm?.[0]?.mname,
                            bookmakerComm?.[0]?.marketId
                          );
                          setSecType('bookmakerSec')
                          setArrType('availableToBack')
                          setParentIndex(pIndex)
                          setShowBet(true)
                        }
                      }}
                    >
                      <span> {bookmakerData?.back?.[0]?.price} </span>
                      <span> {/*{bookmakerData?.bs1}} */} {formatNumberInK(Number(bookmakerData?.back?.[0]?.size))} </span>
                    </div>
                    <div
                      className={`matchesLaySection ${bookmakerData?.betlock ? "opacityClass" : ""
                        } ${bookmakerData?.lay?.[0]?.price && bookmakerData?.lay?.[0]?.price !== "0"
                          ? " "
                          : "disabled "
                        } `}
                      onClick={() => {
                        if (!bookmakerData?.betlock) {
                          setBettingSystem(
                            "lay",
                            bookmakerData?.lay?.[0]?.price,
                            bookmakerData?.mname === "The Draw" ? 'draw' : bookmakerData?.selectionId,
                            "Bookmaker",
                            "bookmakers",
                            bookmakerData?.runnerName,
                            bookmakerComm?.[0]?.marketId
                          );
                          setSecType('bookmakerSec')
                          setArrType('availableToLay')
                          setParentIndex(pIndex)
                          setShowBet(true)
                        }
                      }}
                    >
                      <span> {bookmakerData?.lay?.[0]?.price} </span>
                      <span> {/*{bookmakerData?.bs1}} */} {formatNumberInK(Number(bookmakerData?.lay?.[0]?.size))} </span>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </>
    )
  }

  const renderBookMarkSectionTied = () => {
    // const matchData = matchBetsData?.[0]?.bookmakersOdds?.[0];
    return (
      <>
        <div className="matchBettingSection">
          <div className="matchTextSection">
            <div className="matchTxtSecTitle">TIED MATCH</div>
            <div className="matchTxtSecVal">
              {/* {`MIN: ${matchData?.Bookmaker[0]?.minBet} MAX: ${matchData?.Odds[0]?.maxBet}`} */}
              {bookmakerTied?.[0]?.min
                ? `MIN: ${bookmakerTied?.[0]?.min}`
                : "MIN: ---"}{" "}
              {bookmakerTied?.[0]?.max
                ? `MAX: ${bookmakerTied?.[0]?.max}`
                : "MAX: ---"}
            </div>
          </div>
        </div>
        <div className="matchOddsDetailsSection d-flex align-items-center justify-content-between">
          <div className="matchesBetLayFirst"> Market</div>

          <div className="d-flex">
            <div className="matchesBackSection me-2 d-flex align-items-center justify-content-center">
              <span>BACK</span>
            </div>
            <div className="matchesLaySection text-uppercase d-flex align-items-center justify-content-center">
              <span>LAY</span>
            </div>
          </div>
        </div>
        <div className="matchesBetLaySection">
          {bookmakerTied?.[0]?.runners?.map((bookmakerData, pIndex) => {
            let exposerval = returnExposerAmountt(bookmakerData?.sid, bookmakerData?.mid)
            return (
              <div className="matchesBetLayContainer" key={bookmakerData?.sid}>
                <div className="matchesBetLayFirst">
                  {bookmakerData?.runnerName}
                  {
                    exposerval ?
                      <span className={`backLayVal ms-2 ${exposerval > 0 ? 'blueVal' : 'pinkVal'}`}>
                        {returnExposerAmountt(bookmakerData?.sid, bookmakerData?.mid)?.toFixed(0)}
                      </span>
                      : ''
                  }
                </div>
                <div
                  className={`matchesBetLaySecond  ${bookmakerData.status === "SUSPENDED" ? "" : ""
                    }`}
                >

                  {sectionName === "Bookmaker" && (
                    <>
                      {exposerval ? <>
                        {selectionId === bookmakerData?.sid ? (
                          <>
                            {betInputValue && (betType === "back" ?
                              <span className={(exposerval + Number(profit?.toFixed(0))) > 0 ? "text-success" : "text-danger"} >
                                {exposerval + Number(profit?.toFixed(0))}
                              </span> :
                              <span className={(exposerval - Number(profit?.toFixed(0))) > 0 ? "text-success" : "text-danger"} >
                                {exposerval - Number(profit?.toFixed(0))}
                              </span>)}
                          </>
                        ) : (
                          <>
                            {betInputValue && (betType === "back" ?
                              <span className={(exposerval - betInputValue) > 0 ? "text-success" : "text-danger"} >
                                {exposerval - betInputValue}
                              </span> :
                              <span className={(exposerval + betInputValue) > 0 ? "text-success" : "text-danger"} >
                                {exposerval + betInputValue}
                              </span>)}
                          </>
                        )}
                      </> : <>
                        {selectionId === bookmakerData?.sid ? (
                          <span className={betType === "back" ? "text-success" : "text-danger"}>
                            {betInputValue && (betType === "back"
                              ? `+ ${profit?.toFixed(2)}`
                              : `- ${profit?.toFixed(2)}`)}
                          </span>
                        ) : (
                          <span className={betType !== "back" ? "text-success" : "text-danger"}>
                            {betInputValue && (betType === "back"
                              ? `- ${betInputValue}`
                              : `+ ${betInputValue}`)}
                          </span>
                        )}
                      </>}
                    </>
                  )}
                  <div className="position-relative d-flex" style={{ gap: 5 }}>
                    <div className="suspendDiv d-none">
                      {bookmakerData?.status}
                    </div>
                    <div
                      className={`matchesBackSection ${bookmakerData?.betlock ? "opacityClass" : ""
                        } ${bookmakerData?.back?.[0]?.price && bookmakerData?.back?.[0]?.price !== "0"
                          ? " "
                          : "disabled "
                        } `}
                      onClick={() => {
                        if (!bookmakerData?.betlock) {
                          setBettingSystem(
                            "back",
                            bookmakerData?.b1,
                            bookmakerData?.nation === "The Draw" ? 'draw' : bookmakerData?.sid,
                            "Bookmaker",
                            "bookmakers",
                            bookmakerData?.t,
                            bookmakerData?.mid
                          );
                          setSecType('bookmakerSec')
                          setArrType('availableToBack')
                          setParentIndex(pIndex)
                          setShowBet(true)
                        }
                      }}
                    >
                      <span> {bookmakerData?.back?.[0]?.price} </span>
                      <span> {/*{bookmakerData?.bs1}} */} {formatNumberInK(Number(bookmakerData?.back?.[0]?.size))} </span>
                    </div>
                    <div
                      className={`matchesLaySection ${bookmakerData?.betlock ? "opacityClass" : ""
                        } ${bookmakerData?.lay?.[0]?.price && bookmakerData?.lay?.[0]?.price !== "0"
                          ? " "
                          : "disabled "
                        } `}
                      onClick={() => {
                        if (!bookmakerData?.betlock) {
                          setBettingSystem(
                            "lay",
                            bookmakerData?.l1,
                            bookmakerData?.nation === "The Draw" ? 'draw' : bookmakerData?.sid,
                            "Bookmaker",
                            "bookmakers",
                            bookmakerData?.t,
                            bookmakerData?.mid
                          );
                          setSecType('bookmakerSec')
                          setArrType('availableToLay')
                          setParentIndex(pIndex)
                          setShowBet(true)
                        }
                      }}
                    >
                      <span> {bookmakerData?.lay?.[0]?.price} </span>
                      <span> {/*{bookmakerData?.bs1}} */} {formatNumberInK(Number(bookmakerData?.lay?.[0]?.size))} </span>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </>
    )
  }

  const renderBookMarkSectionToss = () => {
    const matchData = matchBetsData?.allOddsList;
    return (
      <>
        <div className="matchBettingSection">
          <div className="matchTextSection">
            <div className="matchTxtSecTitle"> TOSS</div>
            <div className="matchTxtSecVal">
              {/* {`MIN: ${matchData?.Bookmaker[0]?.minBet} MAX: ${matchData?.Odds[0]?.maxBet}`} */}
              {matchData?.Bookmaker
                ? `MIN: ${matchData?.Bookmaker[0]?.minBet}`
                : "MIN: ---"}{" "}
              {matchData?.Odds
                ? `MAX: ${matchData?.Odds[0]?.maxBet}`
                : "MAX: ---"}
            </div>
          </div>
        </div>
        <div className="matchOddsDetailsSection d-flex align-items-center justify-content-between">
          <div className="matchesBetLayFirst"> Market</div>

          <div className="d-flex">
            <div className="matchesBackSection me-2 d-flex align-items-center justify-content-center">
              <span>BACK</span>
            </div>
            <div className="matchesLaySection text-uppercase d-flex align-items-center justify-content-center">
              <span>LAY</span>
            </div>
          </div>
        </div>
        <div className="matchesBetLaySection">
          {bookmakerToss?.map((bookmakerData, pIndex) => {
            let exposerval = returnExposerAmountt(bookmakerData?.sid, bookmakerData?.mid)
            return (
              <div className="matchesBetLayContainer" key={bookmakerData?.sid}>
                <div className="matchesBetLayFirst">
                  {bookmakerData?.nation}
                  {/* {returnExposerAmount(bookmakerData?.sid)} */}
                  {
                    exposerval ?
                      <span className={`backLayVal ms-2 ${exposerval > 0 ? 'blueVal' : 'pinkVal'}`}>
                        {returnExposerAmountt(bookmakerData?.sid, bookmakerData?.mid)?.toFixed(0)}
                      </span>
                      : ''
                  }
                </div>
                <div
                  className={`matchesBetLaySecond  ${!!bookmakerData.gstatus ? "" : ""
                    }`}
                >
                  <div className="suspendDiv d-none">
                    {bookmakerData?.gstatus}
                  </div>

                  {sectionName === "Toss" && (
                    <>
                      {exposerval ? <>
                        {selectionId === bookmakerData?.sid ? (
                          <>
                            {betInputValue && (betType === "back" ?
                              <span className={(exposerval + Number(profit?.toFixed(0))) > 0 ? "text-success" : "text-danger"} >
                                {exposerval + Number(profit?.toFixed(0))}
                              </span> :
                              <span className={(exposerval - Number(profit?.toFixed(0))) > 0 ? "text-success" : "text-danger"} >
                                {exposerval - Number(profit?.toFixed(0))}
                              </span>)}
                          </>
                        ) : (
                          <>
                            {betInputValue && (betType === "back" ?
                              <span className={(exposerval - betInputValue) > 0 ? "text-success" : "text-danger"} >
                                {exposerval - betInputValue}
                              </span> :
                              <span className={(exposerval + betInputValue) > 0 ? "text-success" : "text-danger"} >
                                {exposerval + betInputValue}
                              </span>)}
                          </>
                        )}
                      </> : <>
                        {selectionId === bookmakerData?.sid ? (
                          <span className={betType === "back" ? "text-success" : "text-danger"}>
                            {betInputValue && (betType === "back"
                              ? `+ ${profit?.toFixed(2)}`
                              : `- ${profit?.toFixed(2)}`)}
                          </span>
                        ) : (
                          <span className={betType !== "back" ? "text-success" : "text-danger"}>
                            {betInputValue && (betType === "back"
                              ? `- ${betInputValue}`
                              : `+ ${betInputValue}`)}
                          </span>
                        )}
                      </>}
                    </>
                  )}

                  <div
                    className={`matchesBackSection ${bookmakerData?.betlock ? "opacityClass" : ""
                      } ${bookmakerData?.b1 && bookmakerData?.b1 !== "0"
                        ? " "
                        : "disabled "
                      } `}
                    onClick={() => {
                      if (!bookmakerData?.betlock) {
                        setBettingSystem(
                          "back",
                          bookmakerData?.b1,
                          bookmakerData?.nation === "The Draw" ? 'draw' : bookmakerData?.sid,
                          "Toss",
                          "bookmakers",
                          bookmakerData?.t,
                          bookmakerData?.mid
                        );
                        setSecType('bookmakerTossSec')
                        setArrType('availableToBack')
                        setParentIndex(pIndex)
                        setShowBet(true)
                      }
                    }}
                  >
                    <span> {bookmakerData?.b1} </span>
                    <span> {/*{bookmakerData?.bs1}} */} 100K  </span>
                  </div>
                  <div
                    className={`matchesLaySection ${bookmakerData?.betlock ? "opacityClass" : ""
                      } ${bookmakerData?.l1 && bookmakerData?.l1 !== "0"
                        ? " "
                        : "disabled "
                      } `}
                    onClick={() => {
                      if (!bookmakerData?.betlock) {
                        setBettingSystem(
                          "lay",
                          bookmakerData?.l1,
                          bookmakerData?.nation === "The Draw" ? 'draw' : bookmakerData?.sid,
                          "Toss",
                          "bookmakers",
                          bookmakerData?.t,
                          bookmakerData?.mid
                        );
                        setSecType('bookmakerTossSec')
                        setArrType('availableToLay')
                        setParentIndex(pIndex)
                        setShowBet(true)
                      }
                    }}
                  >
                    <span> {bookmakerData?.l1} </span>
                    <span> {/*{bookmakerData?.bs1}} */} 100K  </span>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </>
    )
  }


  console.log('fancyKeys', fancyKeys)

  const renderFancySection = () => {
    const matchData = matchBetsData?.[0]?.matchfancies;
    return (
      <>
        {
          fancyKeys?.length ?
            <div class="eventPillsWrapper mb-0">
              <div class="eventPills d-flex align-items-center flex-nowrap">
                {
                  fancyKeys?.map((item) => (
                    <div onClick={() => setSelectedFancyKey(item)} className={`eventPill rounded-pill me-2 text-nowrap text-capitalize ${selectedFancyKey === item ? 'active' : ''}`}>
                      {item}
                    </div>
                  ))
                }
              </div>
            </div>
            : ''
        }
        <div className="matchBettingSection">
          <div className="matchTextSection">
            <div className="matchTxtSecTitle text-capitalize">{selectedFancyKey}</div>
            <div className="matchTxtSecVal">
              {matchData?.Fancy2
                ? `MIN: ${matchData?.Fancy2[0]?.minBet}`
                : "MIN: 100"}{" "}
              {matchData?.Fancy2
                ? `MAX: ${matchData?.Fancy2[0]?.maxBet}`
                : "MAX: 10000"}
            </div>
          </div>
        </div>
        <div className="d-flex fancyHalfContainerWrapper">
          <div className="matchOddsDetailsSection d-flex align-items-center justify-content-between prDiv fancyHalfContainer">
            <div className="matchesBetLayFirst flex-fill">Market</div>
            <div className="d-flex" style={{ gap: 2 }}>
              <div className="matchesLaySection text-uppercase d-flex align-items-center justify-content-center shorttt">
                <span>
                  {
                    selectedFancyKey === "oddeven" ? 'ODD' :
                      selectedFancyKey === "meter" ? 'NO' :
                        selectedFancyKey === "fancy1" ? 'LAY' :
                          selectedFancyKey === "session" ? 'NO' :
                            selectedFancyKey === "khado" ? 'LAY' : 'NO'
                  }
                </span>
              </div>
              <div className="matchesBackSection d-flex align-items-center justify-content-center shorttt">
                <span>
                  {
                    selectedFancyKey === "oddeven" ? 'EVEN' :
                      selectedFancyKey === "meter" ? 'YES' :
                        selectedFancyKey === "fancy1" ? 'BACK' :
                          selectedFancyKey === "session" ? 'YES' :
                            selectedFancyKey === "khado" ? 'BACK' : 'YES'
                  }
                </span>
              </div>
            </div>
          </div>
          <div className="matchOddsDetailsSection d-none d-lg-flex align-items-center justify-content-between fancyHalfContainer">
            <div className="matchesBetLayFirst flex-fill">Market</div>
            <div className="d-flex" style={{ gap: 2 }}>
              <div className="matchesLaySection text-uppercase d-flex align-items-center justify-content-center shorttt">
                <span>
                  {
                    selectedFancyKey === "oddeven" ? 'ODD' :
                      selectedFancyKey === "meter" ? 'NO' :
                        selectedFancyKey === "fancy1" ? 'LAY' :
                          selectedFancyKey === "session" ? 'NO' :
                            selectedFancyKey === "khado" ? 'LAY' : 'NO'
                  }
                </span>
              </div>
              <div className="matchesBackSection d-flex align-items-center justify-content-center shorttt">
                <span>
                  {
                    selectedFancyKey === "oddeven" ? 'EVEN' :
                      selectedFancyKey === "meter" ? 'YES' :
                        selectedFancyKey === "fancy1" ? 'BACK' :
                          selectedFancyKey === "session" ? 'YES' :
                            selectedFancyKey === "khado" ? 'BACK' : 'YES'
                  }
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="matchesBetLaySection fancyHalfContainerWrapper">
          {fancyData?.map((fancyData, pIndex) => {

            // console.log('fancyData', fancyData);
            return (
              <div className="matchesBetLayContainer fancyContainer fancyHalfContainer" key={fancyData?.nation}>
                <div className="matchesBetLayFirst flex-fill">
                  {fancyData?.RunnerName?.toLowerCase()}
                  {returnExposerAmount(fancyData?.SelectionId)}
                </div>
                {/* <div
                  className={`matchesBetLaySecond ${fancyData.status !== 'active' ? "suspend" : ""
                    }`}
                > */}
                <div
                  className={`matchesBetLaySecond ${fancyData.status !== 'active' ? "active" : ""
                    }`}
                >
                  <div className="suspendDiv d-none">{fancyData?.status}</div>

                  <div
                    className={`matchesLaySection ${fancyData?.LayPrice1 && fancyData?.LaySize1 ? " " : "disabled "
                      } `}

                    // "back",
                    // bookmakerData?.back?.[0]?.price,
                    // bookmakerData?.mname === "The Draw" ? 'draw' : bookmakerData?.selectionId,
                    // "Bookmaker",
                    // "bookmakers",
                    // bookmakerData?.runnerName,
                    // bookmakerComm?.[0]?.marketId

                    onClick={() => {
                      setBettingSystem(
                        "no",
                        (selectedFancyKey === 'oddeven' || selectedFancyKey === 'fancy1') ? fancyData?.LayPrice1 : fancyData?.LaySize1,
                        fancyData?.SelectionId,
                        "Fancy",
                        "fancy",
                        fancyData?.RunnerName,
                        fancyData?.SelectionId,
                      )

                      setSecType('fancySec')
                      setArrType('availableToLay')
                      setParentIndex(pIndex)
                      setShowBet(true)
                    }
                    }
                  >
                    <span> {fancyData?.LayPrice1} </span>
                    <span> {fancyData?.LaySize1} </span>
                  </div>
                  <div
                    className={`matchesBackSection ${fancyData?.BackPrice1 && fancyData?.BackSize1 ? " " : "disabled "
                      } `}
                    onClick={() => {
                      setBettingSystem(
                        "yes",
                        (selectedFancyKey === 'oddeven' || selectedFancyKey === 'fancy1') ? fancyData?.BackPrice1 : fancyData?.BackSize1,
                        fancyData?.SelectionId,
                        "Fancy",
                        "fancy",
                        fancyData?.RunnerName,
                        fancyData?.SelectionId,
                      )
                      setSecType('fancySec')
                      setArrType('availableToBack')
                      setParentIndex(pIndex)
                      setShowBet(true)
                    }
                    }
                  >
                    <span> {fancyData?.BackPrice1} </span>
                    <span> {fancyData?.BackSize1} </span>
                  </div>
                </div>
                {/* <div className="matchMinMax ps-2">
                  <div className="minMaxTxt">
                    <span>Min: 100</span>
                  </div>
                  <div className="minMaxTxt">
                    <span>Max: 10000</span>
                  </div>
                </div> */}
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <>
      <main className="main vh-100" id="main">
        <div className="mainContaint">
          <div className="sportsPage">
            <div className="row">
              {!relatedMatchLoader ? (
                relatedMatches?.length > 0 ? (
                  <div className="col-12">
                    <div className="row g-2 flex-nowrap sportsUpcomingMatchesWrapper">
                      {relatedMatches?.map((item) => (
                        <RelatedMatches key={item?._id} data={item} />
                      ))}
                    </div>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}

              <div className="col-12 mt-3">
                <div className="row">

                  {/* <div className="col">
                    <div
                      className={`showScoreBoard d-inline-flex align-items-center mb-2`}
                      onClick={() => setShowScoreBoard(!showScoreBoard)}
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseExample"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      <div
                        className={`d-flex scoreBoardBtn me-2 ${showScoreBoard ? "active" : ""
                          }`}
                      ></div>
                      Score Board
                    </div>
                  </div>
                  <div className="col-auto">
                  <button class="refreshPageBtn shadow-none" 
                    onClick={()=> {
                      window.location.reload()
                    }}>Refresh Page</button>
                  </div>
                  <div className="col-12 mb-1 mt-1">
                    <div class="collapse" id="collapseExample">
                      <div className="spBetscoreCardIframe mb-2">
                            <iframe
                          src={`https://mis2.sqmr.xyz/livetv.php?eventId=${eventId}`}
                          className="w-100 h-100"
                          frameborder="0"
                          title="iframe"
                        ></iframe>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="col-12">

                    <div className="eventPillsWrapper">
                      <div className="eventPills d-flex align-items-center flex-nowrap">

                        <div className="eventPill rounded-pill me-2 text-nowrap active">
                          Soccer
                          <span className='eventVal'>14</span>
                        </div>
                        {
                          betCard?.map((item) => (
                            <div className="eventPill rounded-pill me-2 text-nowrap" key={item}>
                              Soccer
                              <span className='eventVal'>14</span>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div> */}

                  {/** Section started to display the bets */}
                  <div className="col-lg-8">
                    <div class="matchBettingSection mt-0">
                      <div class="matchTextSection">
                        <div class="matchTxtSecTitle">
                          {matchBetsData?.[0]?.event?.name}
                        </div>
                      </div>
                    </div>

                    <MatchScore eventId={eventId} selectedGameId={matchBetsData?.[0]?.gameId} />

                    <div className="matchTableSection">
                      {renderOddsSection()}
                      {(matchBetsData?.[0]?.gameId === "1" && overUnderDataFootball.length) ? overUnderDataFootball?.map((item, dataIndex) => renderOverUnderSectionFootball(item, dataIndex)) : <></>}
                      {bookmakerComm?.length ? renderBookMarkSection0Comm() : <></>}
                      {bookmakerTied?.length ? renderBookMarkSectionTied() : <></>}
                      {/* {bookmakerToss?.length ? renderBookMarkSectionToss():<></>} */}
                      {matchBetsData?.[0]?.matchfancies?.length > 0 ? (
                        renderFancySection()
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className={`col-lg-4 mt-4 mt-lg-0 betSlipOffcanvasParent ${showBet ? 'show' : ''}`}>
                    <BetSlipOffcanvas
                      profit={profit}
                      betInputValue={betInputValue}
                      changeBetInputValue={changeBetInputValue}
                      applyBet={applyBet}
                      betAmount={betAmount}
                      setBetAmount={setBetAmount}
                      isApplyingBet={isApplyingBet}
                      setIsApplyingBet={setIsApplyingBet}
                      isBetOpen={isBetOpen}
                      setIsBetOpen={setIsBetOpen}
                      changeBetAmount={changeBetAmount}
                      betType={betType}
                      oddsType={oddsType}
                      setShowBet={setShowBet}
                      betLoader={betLoader}
                    />
                  </div>
                  {/* <div className="betSlipMobBtn" onClick={()=> setShowBet(!showBet)}>
                    <img src={!showBet ? betSlipIcon : closeIcon} alt="" />
                  </div> */}
                </div>
              </div>
            </div>
            {/* <BetSlipOffcanvas
              profit={profit}
              betInputValue={betInputValue}
              changeBetInputValue={changeBetInputValue}
              applyBet={applyBet}
              betAmount={betAmount}
            /> */}
          </div>
        </div>
        <div className="refreshFixedBtn"
          onClick={() => {
            window.location.reload()
          }}>
          <img src={refreshIcon} alt="" />
        </div>
        <AppFooter />
      </main>
    </>
  );
};

export default SportsDetailPage;
