import React, { useEffect, useState } from "react";
// import BetSlipOffcanvas from '../betSlipOffcanvas/BetSlipOffcanvas'
import BetSlipOffcanvas from "../../sportsComponents/betSlipOffcanvas/BetSlipOffcanvas";
import AppFooter from "../../../components/AppFooter/AppFooter";
import myBetsIcon from "../../sportsAssets/img/myBets.svg";
import MyBetCard from "./MyBetCard";
import { sportsGetAPI, sportsGetAPIAuth } from "../../sportsServices";

const MyBetsPage = () => {
  const [myBets, setMyBets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [gameSeries, setGameSeries] = useState([]);
  const [seriesLoading, setSeriesLoading] = useState(true);
  const [selectedCat, setSelectedCat] = useState();
  const [filteredBets, setFilteredBets] = useState([]);
  const [selectedStatus,setSelectedStatus]=useState("all")
  const [filteredStatusData,setFilteredStatusData]=useState([])

  const getMyBets = async () => {
    setLoading(true);
    try {
      const res = await sportsGetAPIAuth("bet/myBets");
      if (res?.data?.data?.success) {
        setMyBets(res?.data?.data?.data);
        const filteredData = res?.data?.data?.data?.filter(
          (item) => item?.matchdetails?.gameId === selectedCat
        );
        setFilteredBets(filteredData);
      }
    } catch (error) {
      // console.log("error from my bets" ,error);
    } finally {
      setLoading(false);
    }
  };
  const getMatchSeries = async () => {
    setSeriesLoading(true);
    try {
      const res = await sportsGetAPI("bet/games");
      if (res.data.data.success) {
        setGameSeries(res?.data?.data?.data);
        setSelectedCat(res?.data?.data?.data[0]?.gameId);
      }
    } catch (error) {
    } finally {
      setSeriesLoading(false);
    }
  };

  useEffect(() => {
    getMatchSeries();
  }, []);

  useEffect(() => {
    if (selectedCat) {
      getMyBets();
    }
  }, [selectedCat]);

  const changeCategory = (id) => {
    setSelectedCat(id);
    const filteredData = myBets?.filter(
      (item) => item?.matchdetails?.gameId === id
    );

    setFilteredBets(filteredData);
  };

  const changeStatus = (status) => {
    setSelectedStatus(status);
  };
  
  useEffect(()=>{
    if (selectedStatus!=="all") {
      const filteredData = filteredBets?.filter(
        (item) => item?.status === selectedStatus
      );
      setFilteredStatusData(filteredData);
    }else{
      setFilteredStatusData(filteredBets)
    }
  },[selectedStatus,filteredBets])

  return (
    <>
      <main className="main vh-100" id="main">
        <div className="mainContaint">
          <div className="sportsPage">
            <div className="row">
              <div className="col-12">
                <div className="eventHeading mt-0 d-flex align-items-center">
                  <img src={myBetsIcon} alt="" className="me-2" />
                  My Bets
                </div>
              </div>
              <div className="col-12">
                <div className="eventPillsWrapper">
                  <div className="eventPills d-flex align-items-center flex-nowrap">
                    {!seriesLoading
                      ? gameSeries?.length > 0
                        ? gameSeries?.map((item) => (
                            <div
                              className={`eventPill rounded-pill me-2 text-nowrap ${
                                item?.gameId === selectedCat ? "active" : ""
                              }`}
                              onClick={() => changeCategory(item?.gameId)}
                            >
                              <img src={item?.image} alt="" />
                              {item?.name}
                            </div>
                          ))
                        : "no data"
                      : "loading"}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="eventPillsWrapper">
                  <div className="eventPills d-flex align-items-center flex-nowrap">
                  <div
                      className={`eventPill rounded-pill me-2 text-nowrap ${selectedStatus==="all"? "active":""} `}
                      onClick={() => changeStatus("all")}
                    >
                      All
                    </div>
                    <div
                      className={`eventPill rounded-pill me-2 text-nowrap ${selectedStatus==="open"? "active":""} `}
                      onClick={() => changeStatus("open")}
                    >
                      Open
                    </div>
                    <div
                      className={`eventPill rounded-pill me-2 text-nowrap ${selectedStatus==="won"? "active":""}`}
                      onClick={() => changeStatus("won")}
                    >
                      Won
                    </div>
                    <div
                      className={`eventPill rounded-pill me-2 text-nowrap ${selectedStatus==="lost"? "active":""}`}
                      onClick={() => changeStatus("lost")}
                    >
                      Lost
                    </div>
                    <div
                      className={`eventPill rounded-pill me-2 text-nowrap  ${selectedStatus==="refund"? "active":""}`}
                      onClick={() => changeStatus("refund")}
                    >
                      Refund
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-3">
                <div className="row g-3">
                  {!loading ? (
                    filteredStatusData?.length > 0 ? (
                      filteredStatusData?.map((item) => (
                        <MyBetCard key={item?._id} data={item} />
                      ))
                    ) : (
                      <div className="text-white text-center mt-3">No Data</div>
                    )
                  ) : (
                    "loading"
                  )}
                </div>
              </div>
            </div>
            {/* <BetSlipOffcanvas /> */}
          </div>
        </div>
        <AppFooter />
      </main>
    </>
  );
};

export default MyBetsPage;
