import React, { useEffect, useState } from 'react'
import eventIcon from '../../sportsAssets/img/event.svg'
import gameIcon from '../../sportsAssets/img/gameIcon.svg'
import BetCard from './BetCard'
import { sportsGetAPI } from '../../sportsServices'
import { Spinner } from 'react-bootstrap'
import { sportSocket } from '../../sportsServices/sportsSocket'
import { useDispatch, useSelector } from 'react-redux'
import { setGameId } from '../../../store/action'

const Events = ({ hasPadding }) => {
    const [selectedCat, setSelectedCat] = useState('4');
    const [seriesMatches, setSeriesMatches] = useState([]);
    // console.log("seriesMatches", seriesMatches);
    const [sportstabingicon, setSportsTabingIcon] = useState('')
    const [isSeriesLoading, setIsSeriesLoading] = useState(true)
    const dispatch = useDispatch()
    const matchId = useSelector(state => state.matchId.gameid)
    const betGameList = useSelector(state => state?.betGameList)
    const [activeGame, setActiveGame] = useState();

    //  console.log("sportstabingicon", selectedCat);

    useEffect(() => {
        if (!!matchId) {
            setSelectedCat(matchId)
        }
    }, [matchId])

    useEffect(() => {
        if (betGameList?.length) {
            const data = structuredClone(betGameList)
            let name = data?.filter(item => item?.gameId === matchId)
            setActiveGame(name?.[0]?.name)
        }
    }, [matchId, betGameList])

    useEffect(() => {
        if (!!selectedCat) {
            sportSocket.emit('liveMatchList', { gameId: selectedCat })// for live matches liveMatchList
            sportSocket.on(`liveMatchList-${selectedCat}`, (data) => { // liveMatchList-
                console.log('socket', "-live----- matches-", data);
                setSeriesMatches(data)
                setIsSeriesLoading(false)
            })
        }
    }, [selectedCat])

    const matchSectionHandler = (item) => {
        sportSocket.emit('matchList', { gameId: item?.gameId })// for live matches liveMatchList
        sportSocket.on(`gameId-${item?.gameId}`, (data) => { // liveMatchList-
            console.log('onClick', data);
            setSeriesMatches(data)
        })
    }

    // console.log("hasPaddding", hasPadding)

    return (
        <>
            <div className='mainContaint p-0 m-0 '>
                <div className={`sportsPage ${hasPadding ? 'p-0  darkSection' : 'p-0'}`} style={{ minHeight: 'unset' }}>
                    {/* <div className="eventHeading d-flex align-items-center">
                        <img src={eventIcon} alt="" className='me-2' />
                        Live Matches
                    </div> */}
                    <div className="eventPillsWrapper mt-3">
                        <div className="eventPills d-flex align-items-center flex-nowrap">

                            {/* will be of future use */}
                            {/* <div className="eventPill rounded-pill me-2 text-nowrap active">
            <img src={gameIcon} alt="" />
            Soccer
          </div> */}
                            {
                                // isLoading ? (
                                //     <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                //         <Spinner animation="border" variant="light" size="sm" />
                                //     </div>
                                // ) : (
                                betGameList?.length === 0 ? (
                                    <div className="w-100 h-100">
                                        <h6 className="text-light"> No data available</h6>
                                    </div>
                                ) : (
                                    betGameList?.map((item) => (
                                        <div
                                            onClick={() => {
                                                // matchSectionHandler(item)
                                                // dispatch(setGameId(item?.gameId))
                                                setSelectedCat(item?.gameId)
                                                setSportsTabingIcon(item?.image);
                                                dispatch(setGameId({ "gameid": item?.gameId, "image": item?.image }));
                                            }}
                                            className={`eventPill rounded-pill me-2 text-nowrap ${selectedCat == item?.gameId ? "active" : ""}`}>
                                            <img src={item?.image} alt="" />
                                            {item?.name}
                                        </div>
                                    ))
                                )
                                // )
                            }
                        </div>
                    </div>
                    <div className="row mx-0 gx-0 w-100 activeEventRow">
                        <div className="col clubCol activeEventName">{activeGame}</div>
                        <div className="col-auto sliderCol oneXtwo">
                            <div className="row text-center flex-nowrap justify-content-md-end gx-2 gx-sm-2 sliderRow">
                                <div className="col-sm-auto col-6 scrollBox">1</div>
                                <div className="col-sm-auto col-6 scrollBox">X</div>
                                <div className="col-sm-auto col-6 scrollBox">2</div>
                            </div>
                        </div>
                    </div>
                    <div className="row gx-0 mx-0 w-100">
                        {
                            isSeriesLoading ? (
                                <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                    <Spinner animation="border" variant="light" size="sm" />
                                </div>
                            ) : (
                                seriesMatches === 0 ? (
                                    <div className="w-100 h-100">
                                        {/* <h6 className="text-light"> No data available</h6> */}
                                    </div>
                                ) : (
                                    seriesMatches?.map((item) => (
                                        // <BetCard
                                        //     competitionName={item?.competition?.name}
                                        //     openDate={item?.event?.openDate}
                                        //     odd1={item?.odds?.length ? item?.odds[0]?.runners[0]?.ex : false}
                                        //     odd2={item?.odds?.length ? item?.odds[0]?.runners[1]?.ex : false}
                                        //     runners={item?.runners ? item?.runners : false}
                                        //     // marketId={item?.odds?.length ? item?.odds[0]?.marketId : false}
                                        //     marketId={item?.marketId ? item?.marketId : false}
                                        //     locked={!!item?.odds?.length ? false : true}
                                        //     matchId={item?._id}
                                        //     eventId={item?.event?.id}
                                        //     isLiveMatch={true}
                                        // />

                                        // <></>
                                        <BetCard
                                            competitionName={item?.event?.name}
                                            openDate={item?.event?.openDate}
                                            odd1={item?.matchodds?.length ? item?.matchodds[0]?.runners[0]?.ex : false}
                                            odd2={item?.matchodds?.length ? item?.matchodds[0]?.runners[1]?.ex : false}
                                            odd3={item?.matchodds?.length ? item?.matchodds[0]?.runners[2]?.ex : false}
                                            runners={item?.matchodds ? item?.matchodds?.[0]?.runners : false}
                                            icon={sportstabingicon}
                                            // marketId={item?.odds?.length ? item?.odds[0]?.marketId : false}
                                            marketId={item?.marketId ? item?.marketId : false}
                                            locked={!!item?.matchodds?.length ? false : true}
                                            matchId={item?._id}
                                            eventId={item?.event?.id}
                                            isLiveMatch={true}
                                        />
                                    ))

                                )
                            )

                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default Events