import { useEffect, useState } from "react";
import { ADMIN_LOGGED_IN_ID, OPEN_CAGE_API_KEY } from "../../../constants";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { useDispatch, useSelector } from "react-redux";
import { USER } from "../../AdminConstants/apiEndpoints";
import {
  adminLogIn,
  countryAction,
  ipAddressAction,
} from "../../../store/action";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES_CONST } from "../../../constants/routeConstant";
import { PORTAL } from "../../AdminConstants/urlPaths";
import { adminGetAPIAuth, adminPostAPIAuth } from "../../AdminServices";
import { useAdminLogout } from "../../AdminUtils/Utils";
import { errorToaster, succesToaster } from "../../../utils/toaster";
import ipAddressFunc from "../../../utils/ipAddressFunc";
import axios from "axios";
import AdminLoader from "../adminLoader/AdminLoader";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Container, Form, Row } from "react-bootstrap";

//eslint-disable-next-line
const AdminHeader = ({ setShowSideBar }) => {
  const adminId = useLocalStorage(ADMIN_LOGGED_IN_ID);
  const adminData = useSelector((state) => state.adminData);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logOut = useAdminLogout();
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [show, setShow] = useState(false);
  const [userPassword, setUserPassword] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [error, setError] = useState({
    password: '',
    oldPassword: ''
  })
  const passwordRegx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/

  useEffect(() => {
    if (!Object.keys(adminData).length) {
      getUserDetails();
    }
    const portalUrl = location.pathname
      .split("/")
      .filter((part) => part.trim() !== "");

    if (
      adminData?.role &&
      portalUrl.length === 3 &&
      portalUrl[1] !== adminData?.role
    ) {
      navigate("/" + PORTAL + "/");
    }
    //eslint-disable-next-line
  }, [adminData]);

  useEffect(() => {
    if (adminData?.role === "user") {
      navigate(ROUTES_CONST.INDEX);
    }
    //eslint-disable-next-line
  }, []);

  const getUserDetails = async () => {
    try {
      const res = await adminGetAPIAuth(USER.profile + "/" + adminId.value);
      if (res?.data?.data?.success) {
        dispatch(adminLogIn(res?.data));
      }
    } catch (error) {
      if (error.response.status) {
        logOut();
      }
    }
  };

  const handleLogOut = async () => {
    try {
      const res = await adminGetAPIAuth(USER.logout);
      if (res?.data?.data?.success) {
        logOut();
      }
    } catch (error) {
      if (error?.response.status === 401) {
        logOut();
      }
      if (error?.response?.message) {
        errorToaster(error?.response?.message);
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}-${formattedMonth}-${year}`;
  };

  const formatTime = (date) => {
    const options = {
      hour: "numeric",
      minute: "numeric",
    };

    return date.toLocaleTimeString(undefined, options);
  };

  //ip Address
  const fetchIpAddress = async () => {
    const res = await ipAddressFunc();
    if (res) {
      dispatch(ipAddressAction(res));
    }
  };

  //get country name
  const countryNameFunc = async () => {
    try {
      navigator.geolocation?.getCurrentPosition(async (data) => {
        axios
          .get(
            `https://api.opencagedata.com/geocode/v1/json?q=${data?.coords?.latitude}+${data?.coords?.longitude}&key=${OPEN_CAGE_API_KEY}`
          )
          .then((res) => {
            dispatch(countryAction(res?.data?.results[0]?.components?.country));
          });
      });
    } catch (error) {}
  };

  useEffect(() => {
    fetchIpAddress();
    countryNameFunc();
  }, []);

  const [adminLoader, setAdminLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setAdminLoader(false);
    }, 2000);
  }, []);

  const handlePassword = async () => {
      if (passwordRegx.test(userPassword)) {
        setError((prev) => ({
          ...prev,
          password: '',
        }));
        try {
          const res = await adminPostAPIAuth('user/updateUserCridential', {
            oldPassword: oldPassword,
            password: userPassword
          })
          if (res?.data?.data?.success) {
            succesToaster('Password Changed Successfully')
            setShow(false)
            setOldPassword('')
            setUserPassword('')
          } else {
            errorToaster('Something error has happened')
          }
        } catch (error) {
          console.log(error)
          errorToaster(error?.response?.data?.data?.message)
        }
      }
      else {
        setError((prev) => ({
          ...prev,
          password: 'Password must be at least 8 characters and include one uppercase letter, one lowercase letter, and one number.',
        }));
      }
  }

  return (
    <>
      {adminLoader ? (
        <AdminLoader />
      ) : (
        <>
          <header className="app-header app-header-dark headerCustom">
            <div className="top-bar">
              <div className="top-bar-brand bg-transparent">
                <button
                  className="hamburger hamburger-squeeze mr-2"
                  type="button"
                  data-toggle="aside"
                  aria-label="toggle menu"
                  onClick={() => setShowSideBar(true)}
                >
                  <span className="hamburger-box">
                    <span className="hamburger-inner" />
                  </span>
                </button>
              </div>
              <div className="top-bar-list row justify-content-end">
                <div className="col">
                  <div className="top-bar-item px-2 d-md-none d-lg-none d-xl-none mauto">
                    <button
                      className="hamburger hamburger-squeeze"
                      type="button"
                      data-toggle="aside"
                      aria-label="Menu"
                      onClick={() => setShowSideBar(true)}
                    >
                      <span className="hamburger-box">
                        <span className="hamburger-inner" />
                      </span>
                    </button>
                  </div>
                </div>
                <div className="col-auto d-flex align-items-center">
                  <div className="text-light row dateBox">
                    <div className="col-auto">
                      <p className="mb-0 d-flex">
                        <span className="d-none d-sm-flex me-1">Date:</span>{" "}
                        {formatDate(currentDateTime)}
                      </p>
                    </div>
                    <div className="col-auto ps-0">
                      <p className="mb-0 d-flex">
                        <span className="d-none d-sm-flex me-1">Time:</span>{" "}
                        {formatTime(currentDateTime)}
                      </p>
                    </div>
                  </div>

                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {adminData.username}
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <span
                          className="dropdown-item"
                          onClick={() => setShow(true)}
                        >
                          Change Password
                        </span>
                      </li>
                      <li>
                        <span className="dropdown-item" onClick={handleLogOut}>
                          Log out
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <>
              <Modal
                show={show}
                onHide={() => {
                  setShow(false);
                  setError('')
                  setOldPassword('')
                  setUserPassword('')
                }}
              >
                <Modal.Header>
                  <Modal.Title>Create Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Container>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label htmlFor="name">Old Password</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Old Password"
                              id="password"
                              value={oldPassword}
                              onChange={(e) => setOldPassword(e.target.value)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label htmlFor="name">New Password</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="New Password"
                              id="password"
                              value={userPassword}
                              onChange={(e) => setUserPassword(e.target.value)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Container>
                  </Form>
                  {error?.password ? <div className="text-danger errorTxt">{error?.password}</div> : ''}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    className="text-dark"
                    onClick={() => {
                      setShow(false) 
                      setError('')
                      setOldPassword('')
                      setUserPassword('')
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary px-5"
                    onClick={handlePassword}
                  >
                    Change Password
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          </header>
        </>
      )}
    </>
  );
};

export default AdminHeader;