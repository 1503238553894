import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES_CONST } from '../../constants/routeConstant'
import AppFooter from '../../components/AppFooter/AppFooter'

const DownloadApp = () => {
    const [isAndroidActive, setIsAndroidActive] = useState(false)
    return (
        <>
            <main className="main " id="main">
                <div className="mainContaint">
                    <div className=" downloadAppMobile py-3">
                        <div className="mobileBox">
                            <ul className="nav nav-pills navPillsUl mb-3" id="pills-tab" role="tablist">
                                <li className="nav-item navItem me-4" role="presentation">
                                    <button className={`nav-link navLink ${!isAndroidActive ? 'active' : ''}`} onClick={() => setIsAndroidActive(false)} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-ios" type="button" role="tab" aria-controls="pills-home" aria-selected="true">For IOS Setup</button>
                                </li>
                                <li className="nav-item navItem" role="presentation">
                                    <button className={`nav-link navLink ${isAndroidActive ? 'active' : ''}`} onClick={() => setIsAndroidActive(true)} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-android" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">For Android Setup</button>
                                </li>
                            </ul>
                            <div className="tab-content pillsContent" id="pills-tabContent">
                                <div className="tab-pane tabPaneIos fade show active" id="pills-ios" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                                    {!isAndroidActive ?
                                        <div className="iosTab iosModalContentMobile ">
                                            <div className="row align-items-center">
                                                <div className="col-6">
                                                    <div className="row">
                                                        <div className="col-12"><div className="heading">HOW TO DOWNLOAD MOBILE APP FOR IOS ?</div></div>
                                                        <div className="col-12"><button className="stepBtn mb-lg-5 mb-3">STEP 1</button></div>
                                                        <div className="col-12">
                                                            <div className="subheading">1. Open BX in Safari browser</div>
                                                            <div className="para">Open Safari browser on your phone and go to <Link to={ROUTES_CONST.INDEX} className="anchor">BX.io.</Link></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="modalImg">
                                                        <img src="assets/img/download/ios_mob_dow_1.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="iosTab iosModalContentMobile ">
                                            <div className="row align-items-center">
                                                <div className="col-6">
                                                    <div className="row">
                                                        <div className="col-12"><div className="heading">HOW TO DOWNLOAD MOBILE APP FOR ANDROID ?</div></div>
                                                        <div className="col-12"><button className="stepBtn mb-lg-5 mb-3">STEP 1</button></div>
                                                        <div className="col-12">
                                                            <div className="subheading">1. Open website in Chrome browser</div>
                                                            <div className="para">Tap to open Google Chrome on your phone</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="modalImg">
                                                        <img src="assets/img/download/and_mob_dow_1.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="downArrow d-flex justify-content-center py-4"><img src="assets/img/modal/downArrow.png" className="" alt="" /></div>

                        <div className="mobileBox">
                            <div className="pillsContent">
                                <div className="tabPaneIos">
                                    {!isAndroidActive ?
                                        <div className="iosTab iosModalContentMobile ">
                                            <div className="row align-items-center">
                                                <div className="col-6">
                                                    <div className="row">
                                                        {/* <div className="col-12"><div className="heading">HOW TO DOWNLOAD MOBILE APP FOR IOS ?</div></div> */}
                                                        <div className="col-12"><button className="stepBtn mb-lg-5 mb-3">STEP 2</button></div>
                                                        <div className="col-12">
                                                            <div className="subheading">2. Tap Sharing button.</div>
                                                            <div className="para">When on <Link to={ROUTES_CONST.INDEX} className="anchor">BX.io.</Link> tap then on the Sharing Button.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="modalImg">
                                                        <img src="assets/img/download/ios_mob_dow_2.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="iosTab iosModalContentMobile ">
                                            <div className="row align-items-center">
                                                <div className="col-6">
                                                    <div className="row">
                                                        {/* <div className="col-12"><div className="heading">HOW TO DOWNLOAD MOBILE APP FOR IOS ?</div></div> */}
                                                        <div className="col-12"><button className="stepBtn mb-lg-5 mb-3">STEP 2</button></div>
                                                        <div className="col-12">
                                                            <div className="subheading">2. Tap menu button.</div>
                                                            <div className="para">Use Google Chrome APP to go <Link to={ROUTES_CONST.INDEX} className="anchor">BX.io.</Link> and then click the button “Three dots”.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="modalImg">
                                                        <img src="assets/img/download/and_mob_dow_2.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="downArrow d-flex justify-content-center py-4"><img src="assets/img/modal/downArrow.png" className="" alt="" /></div>
                        <div className="mobileBox">
                            <div className="pillsContent">
                                <div className="tabPaneIos">
                                    {!isAndroidActive ?
                                        <div className="iosTab iosModalContentMobile ">
                                            <div className="row align-items-center">
                                                <div className="col-6">
                                                    <div className="row">
                                                        {/* <div className="col-12"><div className="heading">HOW TO DOWNLOAD MOBILE APP FOR IOS ?</div></div> */}
                                                        <div className="col-12"><button className="stepBtn mb-lg-5 mb-3">STEP 3</button></div>
                                                        <div className="col-12">
                                                            <div className="subheading">3. Choose to Add to Home Screen.</div>
                                                            <div className="para">Press Add to Home Screen in the list popup to add to the home screen. You may need to swipe left to locate the Add to Home Screen button.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="modalImg">
                                                        <img src="assets/img/download/ios_mob_dow_3.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="iosTab iosModalContentMobile ">
                                            <div className="row align-items-center">
                                                <div className="col-6">
                                                    <div className="row">
                                                        {/* <div className="col-12"><div className="heading">HOW TO DOWNLOAD MOBILE APP FOR IOS ?</div></div> */}
                                                        <div className="col-12"><button className="stepBtn mb-lg-5 mb-3">STEP 3</button></div>
                                                        <div className="col-12">
                                                            <div className="subheading">3. Tap menu button.</div>
                                                            <div className="para">Press Install app in the list popup to add to the home screen.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="modalImg">
                                                        <img src="assets/img/download/and_mob_dow_3.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AppFooter />
            </main>

        </>
    )
}

export default DownloadApp