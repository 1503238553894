import React from 'react'
import gameIcon from '../../sportsAssets/img/gameIcon.svg'
import copyIcon from '../../sportsAssets/img/copy.svg'
import moment from 'moment'
import { succesToaster } from '../../../utils/toaster'
import { useNavigate } from 'react-router-dom'
import { ROUTES_CONST } from '../../../constants/routeConstant'

const MyBetCard = ({ data }) => {

  // function to copy transaction id
  const copyTransId = (id, e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(id)
    succesToaster('Successfully copied to clipboard')
  }

  
  const navigate = useNavigate()
  console.log("-->>>>", data);

  return (
    <>
      <div className="col-sm-6 col-lg-4">
        <div className="row mx-0 g-0 h-100 myBetCard" onClick={() => navigate(`${ROUTES_CONST.SPORTS}/${data?.matchId}|+|${data?.matchdetails?.event?.id}|+|${data?.matchdetails?.marketId}`)}>
          <div className="col-12">
            <div className="row mx-0 align-items-center">
              <div className="col px-0">
                <div className="matchType">
                  {/* <span className='type text-uppercase'>single </span> */}
                  <span className='date'>{moment(data?.createdAt).format('MMM Do YYYY, h:mm')}</span>
                </div>
              </div>
              <div className="col-auto pe-0">
                {/* if status is won than add text-success class and if status is lost than text-danger and if open than add open class */}
                <div className={`matchStatus text-uppercase 
                ${data?.status?.toLowerCase() === 'won' ? 'text-success' :
                    data?.status?.toLowerCase() === 'lost' ? 'text-danger' :
                      data?.status?.toLowerCase() === 'refund' ? 'open' : ''
                  }
                `}
                >{data?.status}</div>
              </div>
            </div>
          </div>
          <div className="col-12 clubDetail">
            <div className="row mx-0 align-items-center">
              <div className="col-12 px-0 clubName">
                <img src={gameIcon} alt="" />
                {data?.matchdetails?.competition?.name}
                {/* <span className='dotSpan'></span>
                U20 Friendly Games */}
              </div>
              <div className="col px-0 vsTeams">{data?.matchdetails?.event?.name}</div>
              {/* <div className="col-auto pe-0 taemPoints">5:0</div> */}
            </div>
          </div>
          <div className="col-12">
            <div className="row mx-0 align-items-center mt-3 my-2">
              <div className="col myBetTicket">Ticket ID: {data?.transaction_id}
                <img src={copyIcon} onClick={(e) => copyTransId(data?.transaction_id, e)} className='copyIcon' alt="" />
              </div>
              <div className="col-auto myBetDet">
                <img src="assets/img/heroLogo.png" className='myBetLogo' alt="" />
              </div>
            </div>
            <div className="row mx-0 align-items-center my-2">
              <div className="col myBetDet">1x2 {data?.selectionCountryName}</div>
              <div className="col-auto myBetDet">{data?.odds}</div>
            </div>
            <div className="row mx-0 align-items-center my-2">
              <div className="col myBetDet">Stake</div>
              <div className="col-auto myBetDet">₹ {data?.amount?.toFixed(2)}</div>
            </div>
            <div className="row mx-0 align-items-center my-2">
              <div className="col myBetDetWon">{data?.status === 'open' ? 'POTENTIAL WINNING' : data?.status === 'won' ? 'YOU WON' : data?.status === 'lost' ? 'YOU LOST' : data?.status === 'refund' ? 'REFUNDABLE AMOUNT' : ''}</div>
              <div className="col-auto myBetDetWon">₹ {data?.potentialWin?.toFixed(2)}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MyBetCard