export const DEPOSIT = "Deposit"

export const WITHDRAW ="Withdraw"

export const COMMISSION="Commission"

export const SETTLEMENT="Settlement"

export const RESET_USER_PASSWORD="Reset User Password"

export const CHANGE_STATUS="Change status"

export const  USER_DETAILS ="User Details"