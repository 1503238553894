import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import PasswordInput from "../../passwordInput/PasswordInput";
import { useSelector } from "react-redux";
import { adminPostAPI,adminPostAPIAuth } from "../../../AdminServices";
import { USER } from "../../../AdminConstants/apiEndpoints";
import { succesToaster as successToaster } from "../../../../utils/toaster";
import { ADMIN_AUTH_ACCESS } from "../../../../constants";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";

const userRolesArray = [
  {
    role: "Active",
    value: "active",
  },
  {
    role: "Suspended",
    value: "suspended",
  },
  {
    role: "Bet Locked",
    value: "betLocked",
  },
];

const ChangeStatusForm = ({ setShow, data,updateData }) => {
  const [formValues, setFormValues] = useState({
    accountStatus: data?.status,
    password: "",
  });
  const [error, setError] = useState("");

  const adminData = useSelector((state) => state.adminData);
  const adminToken=useLocalStorage(ADMIN_AUTH_ACCESS,"")

  const handleChange = (e) => {
    setFormValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (formValues.password==="") {
      setError("Please fill in your password")
    }else{
      handleApiCall();
    }
  };

  const handleApiCall = async () => {
    const loginCred = {
      username: adminData.username,
      password: formValues.password,
    };
    setError("");
    try {
      const res = await adminPostAPI(USER.login, loginCred);
      if (res?.data?.data?.success) {
        adminToken.updateValue(res?.data?.data.token)
        changeStatusApi();
      }
    } catch (error) {
      setError(error?.response?.data?.data?.message);
    }
  };

  const changeStatusApi = async () => {
    const payload = {
      status: formValues.accountStatus,
      userId: data?._id,
    };
    try {
      const res = await adminPostAPIAuth(USER.changeStatus, payload);
      if (res.status === 200) {
        successToaster("Status Updated Successfully! ");
        setError("");
        setShow(false);
        updateData()
      }
    } catch (error) {
      setError(error?.response?.data?.data?.message);
    }
  };

  return (
    <Form>
      <Container>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="accountStatus">Account Status</Form.Label>
              {/* Form.Select have css in app.css */}
              <Form.Select
                id="accountStatus"
                name="accountStatus"
                placeholder="accountStatus"
                onChange={handleChange}
              >
                {userRolesArray.map((item) => (
                  <option
                    key={item.value}
                    value={item.value}
                    selected={item.value === formValues.accountStatus}
                  >
                    {item.role}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Label htmlFor="password">Enter Your Password</Form.Label>
            <PasswordInput
              id="password"
              name="password"
              value={formValues.password}
              onChange={handleChange}
            />
          </Col>
        </Row>
        {/* admin-forms-error-text has css in userManagement.css  */}
        {error && (
          <Row className="mb-2 admin-forms-error-text">
            <Col>
              <div>{error}</div>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Button type="submit" onClick={handleClick}>
              Confirm
            </Button>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default ChangeStatusForm;
