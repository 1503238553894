import React from 'react'
import AppFooter from '../../components/AppFooter/AppFooter'

const AffilliatePage = () => {
  return (
    <>
      <main className="main vh-100" id="main">

<div className="mainContaint">
    <div className="affiliatepagenew homepage">

        <div className="container-fluid partnershipSection">
            <div className="affiliateheading">
                BX partnership program 
            </div>
            <div className="partnershiptitle">
                The most rewarding affiliate program! 
            </div>
            <div className="row parnershipCardMain g-sm-3 g-2">
                <div className="col-md-4">
                    <div className="parnershipCard d-flex justify-content-between flex-column position-relative h-100">
                        <div>
                            <div className="cardHeading d-flex align-items-center">
                                <div className="casinoicon d-flex align-items-center justify-content-center">
                                    <img src="assets/img/affiliatenew/casino.png" alt="casino" className="h-100 w-100"/>
                                </div>
                                CASINO
                            </div>
                            <div className="cardTitle">
                                + 20,000 Games for the Best Casino experience.  
                            </div>
                            <div className="carddetail">
                                We provide users with more than 20.000 games from Slots to live casino games & more. All our Games are from the top providers for the best Casino Experience.
                            </div>
                        </div>
                        <a href="javascript:;" className="d-flex justify-content-end align-items-center gotocasino text-decoration-none stretched-link">
                            Go to Casino
                            <span className="d-flex align-items-center justify-content-center gotocasinoicon">
                                <img src="assets/img/affiliatenew/gotocasino.png" alt="affiliate" className="h-100 w-100 img-fluid"/>
                            </span>
                        </a>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="parnershipCard d-flex justify-content-between flex-column position-relative h-100">
                        <div>
                            <div className="cardHeading d-flex align-items-center">
                                <div className="casinoicon d-flex align-items-center justify-content-center">
                                    <img src="assets/img/affiliatenew/sport.png" alt="SPORTS" className="h-100 w-100"/>
                                </div>
                                SPORTS
                            </div>
                            <div className="cardTitle">
                                25+ Sports 1000+ Betting Markets 60 000+ events per Months
                            </div>
                            <div className="carddetail">
                                BX sportsbook offers the widest range of sports events, from live sports to Esports betting, free bets & boosted odds are also available for the greatest betting experience. 
                            </div>
                        </div>
                        <a href="javascript:;" className="d-flex justify-content-end align-items-center gotocasino text-decoration-none stretched-link">
                            Go to Sports
                            <span className="d-flex align-items-center justify-content-center gotocasinoicon">
                                <img src="assets/img/affiliatenew/gotocasino.png" alt="affiliate" className="h-100 w-100 img-fluid"/>
                            </span>
                        </a>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="parnershipCard d-flex justify-content-between flex-column position-relative h-100">
                        <div>
                            <div className="cardHeading d-flex align-items-center">
                                <div className="casinoicon d-flex align-items-center justify-content-center">
                                    <img src="assets/img/affiliatenew/vault.png" alt="VAULT" className="h-100 w-100"/>
                                </div>
                                VAULT
                            </div>
                            <div className="cardTitle">
                                The Vault is the place to store your crypto and get 10% APR!
                            </div>
                            <div className="carddetail">
                                BX allows you to store your crypto in the Vault where you can earn up to 10% APR! Transfer your crypto to the Vault and start earning! You can withdraw your funds from the vault at any time.
                            </div>
                        </div>
                        <div className="gotocasino d-flex justify-content-end align-items-center">
                            Coming soon
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid commissinplanSection">
            <div className="affiliateheading">
                Сommission plan
            </div>
            <div className="commissioncard">
                <div className="row">
                    <div className="col-12 commissionData">
                        The BX Affiliate program allows you to earn a percentage of the net revenue on the referred players*. The revenue share starts from 10% up to 50% for high-performing partners. Сontact us for clarification of all details & Let’s enjoy success together! 
                    </div>
                    <div className="col-12 commissionTitle">
                        *Reward for Casino NGR and Sports Betting NGR is calculated separately.
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid becomepartnerSection">
            <div className="affiliateheading">
                Why becoming a BX affiliate partner?
            </div>
            <div className="row partnerCardMain g-3 g-lg-4">
                <div className="col-md-6">
                    <div className="partnerCard position-relative h-100 d-flex flex-column justify-content-between">
                        <div>
                            <div className="partnerHeading">
                                International brand
                            </div>
                            <div className="partnerDetail">
                                BX is online’s Greatest Casino. We have partnered with some of the most iconic celebrities worldwide, making our brand the most trusted in I-Gaming.
                            </div>
                        </div>
                        <div className="cardfooter d-flex align-items-center justify-content-between">
                            <a href="javascript:;" className="d-flex w-100 stretched-link align-items-center text-decoration-none justify-content-end cardlink">
                                Check our Celebrity list
                                <span className="d-flex align-items-center justify-content-center linkicon">
                                    <img src="assets/img/affiliatenew/gotocasino.png" alt="check our celebrity list" className="h-100 w-100 img-fluid"/>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="partnerCard position-relative h-100 d-flex flex-column justify-content-between">
                        <div>
                            <div className="partnerHeading">
                                Professional Support
                            </div>
                            <div className="partnerDetail">
                                You will have a dedicated team helping you with any questions related to the affiliate, or learn how to generate more income, our team will be here for you to get started. 
                            </div>
                        </div>
                        <div className="cardfooter d-flex align-items-center justify-content-between">
                            <a href="javascript:;" className="d-flex w-100 stretched-link align-items-center text-decoration-none justify-content-end cardlink">
                                Contact us
                                <span className="d-flex align-items-center justify-content-center linkicon">
                                    <img src="assets/img/affiliatenew/gotocasino.png" alt="check our celebrity list" className="h-100 w-100 img-fluid"/>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="partnerCard position-relative h-100 d-flex flex-column justify-content-between">
                        <div>
                            <div className="partnerHeading">
                                Favorable payouts
                            </div>
                            <div className="partnerDetail">
                                With our program, you can earn up to 50% of the NGR*. Get your payout on the 1st day of each month without any carry over. 
                            </div>
                        </div>
                        <div className="cardfooter d-flex align-items-center justify-content-between">
                            <div className="ngrtitle">
                                *NGR: Net gaming revenue.
                            </div>
                            <a href="javascript:;" className="d-flex w-100 stretched-link align-items-center text-decoration-none justify-content-end cardlink">
                                Contact us
                                <span className="d-flex align-items-center justify-content-center linkicon">
                                    <img src="assets/img/affiliatenew/gotocasino.png" alt="check our celebrity list" className="h-100 w-100 img-fluid"/>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="partnerCard position-relative h-100 d-flex flex-column justify-content-between">
                        <div>
                            <div className="partnerHeading">
                                Traffic performance
                            </div>
                            <div className="partnerDetail">
                                You will have access to your own personal dashboard where you can track all your revenue shares from your referrals.
                            </div>
                        </div>
                        <div className="cardfooter d-flex align-items-center justify-content-between">
                            <a href="javascript:;" className="d-flex w-100 stretched-link align-items-center text-decoration-none justify-content-end cardlink">
                                Go to Dashboard
                                <span className="d-flex align-items-center justify-content-center linkicon">
                                    <img src="assets/img/affiliatenew/gotocasino.png" alt="check our celebrity list" className="h-100 w-100 img-fluid"/>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid guidelinesSection">
            <div className="affiliateheading">
                Guidelines
            </div>
            <div className="row guidelinescardmain g-sm-3 g-2">
                <div className="col-lg-3 col-6">
                    <div className="guidelinescard h-100">
                        <div className="guidelinesimg d-flex align-items-center justify-content-center">
                            <img src="assets/img/affiliatenew/marketing.png" alt="marketing" className="h-100 img-fluid"/>
                        </div>
                        <div className="guidelinedata">
                            Affiliate must exclusively promote BX
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-6">
                    <div className="guidelinescard h-100">
                        <div className="guidelinesimg d-flex align-items-center justify-content-center">
                            <img src="assets/img/affiliatenew/review.png" alt="review" className="h-100 img-fluid"/>
                        </div>
                        <div className="guidelinedata">
                            Affiliate must represent BX positively
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-6">
                    <div className="guidelinescard h-100">
                        <div className="guidelinesimg d-flex align-items-center justify-content-center">
                            <img src="assets/img/affiliatenew/notification.png" alt="notification" className="h-100 img-fluid"/>
                        </div>
                        <div className="guidelinedata">
                            Affiliate must notify BX support of new content they have created.
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-6">
                    <div className="guidelinescard h-100">
                        <div className="guidelinesimg d-flex align-items-center justify-content-center">
                            <img src="assets/img/affiliatenew/clipboard.png" alt="clipboard" className="h-100 img-fluid"/>
                        </div>
                        <div className="guidelinedata">
                            BX reserves the right to forfeit affiliate benefits if we feel an affiliate isn't adhering to the aforementioned guidelines.
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="guidelinescard cardlast d-flex align-items-center justify-content-center">
                        <div className="guidelinesimg d-flex align-items-center justify-content-center">
                            <img src="assets/img/affiliatenew/streaming.png" alt="streaming" className="h-100 img-fluid"/>
                        </div>
                        <div className="guidelinedata mt-0 text-start">
                            Affiliate must actively promote BX. For streamers, you must stream at least 1 hour of BX each week. For YouTubers, you must upload at least 1 video per week. For website owners, you must maintain promotions for BX, ensuring all displayed information is accurate & updated.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid faqpage">
            <div className="affiliateheading">
                FAQ
            </div>
            <div className="faqCard">
                <div className="row faqMain">
                    <div className="col-12">
                        <div className="faqinner">
                            <div className="question d-flex align-items-end">
                                <div className="faqcount">01</div>
                                <span className='d-inline-block'>What is an Affiliate Program?</span>
                            </div>
                            <div className="answer">
                                Affiliate Program is a partnership between you and the operator - BX.io. Being part of the affiliate program the operators pays you a commission for every referred customer that is active on the platform.
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="faqinner">
                            <div className="question d-flex align-items-end">
                                <div className="faqcount">02</div>
                                <span className="d-inline-block">How do I earn money with your program?</span>
                            </div>
                            <div className="answer">
                                Whenever a player registers through the tracking link provided to you and wagers money – you will earn a percentage of that player ́s monthly losses.
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="faqinner border-0 pb-0">
                            <div className="question d-flex align-items-end">
                                <div className="faqcount">03</div>
                                <span className=" d-inline-block">How do I earn commission?</span>
                            </div>
                            <div className="answer">
                                When you refer to a player, this player becomes linked to your affiliate account. When your players wager real money and generate revenue for our brand, you earn a percentage of that revenue which is generated.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid JoinusSection">
            <div className="affiliateheading">
                Join us
            </div>
            <div className="joinuscard">
                <div className="row g-3">
                    <div className="col-12 signuptitle">
                        Sign Up
                    </div>
                    <div className="col-lg-8">
                        <div className="row g-3">
                            <div className="col-md-6 order-1">
                                <div className="inputmain position-relative">
                                    <input type="text" id="nameinput" className="form-control shadow-none affiliateInput bg-transparent rounded-0 border-0 h-100"/>
                                    <label for="nameinput" className="affiliateinputLabel position-absolute">Name</label>
                                </div>
                            </div>
                            <div className="col-md-6 order-md-2 order-5">
                                <div className="socialmedia d-flex align-items-center gap-2 w-100">
                                    <div className="dropdown instagramdropdown">
                                        <button className="btn instabtn dropdown-toggle d-flex align-items-center justify-content-center" type="button" data-bs-toggle="dropdown" aria-expanded="false"  data-bs-auto-close="outside">
                                            Instagram
                                        </button>
                                        <ul className="dropdown-menu instamenu w-100">
                                            <li>
                                                <a className="dropdown-item socialmedialink bg-transparent active mt-0" href="javascript:;">Instagram</a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item socialmedialink bg-transparent" href="javascript:;">Facebook</a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item socialmedialink bg-transparent" href="javascript:;">Youtube</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="inputmain position-relative w-100">
                                        <input type="text" id="yourinstagram" className="form-control shadow-none affiliateInput bg-transparent rounded-0 border-0 h-100"/>
                                        <label for="yourinstagram" className="affiliateinputLabel position-absolute">Your Instagram</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 order-md-3 order-2">
                                <div className="inputmain position-relative">
                                    <input type="email" id="emailinput" className="form-control shadow-none affiliateInput bg-transparent rounded-0 border-0 h-100"/>
                                    <label for="emailinput" className="affiliateinputLabel position-absolute">Email</label>
                                </div>
                            </div>
                            <div className="col-md-6 order-md-4 order-last">
                                <div className="inputmain position-relative">
                                    <input type="text" id="companyname" className="form-control shadow-none affiliateInput bg-transparent rounded-0 border-0 h-100"/>
                                    <label for="companyname" className="affiliateinputLabel position-absolute">Your Company name or website</label>
                                </div>
                            </div>
                            <div className="col-md-6 order-md-5 order-3">
                                <div className="inputmain position-relative d-flex align-items-center">
                                    <input type="text" id="Password" className="form-control shadow-none affiliateInput bg-transparent rounded-0 border-0 h-100"/>
                                    <label for="Password" className="affiliateinputLabel position-absolute">Password</label>
                                    <button className="border-0 shadow-none d-flex align-items-center justify-content-center p-0 passwordvisible bg-transparent">
                                        <img src="assets/img/affiliatenew/passwordvisible.png" alt="password" className="h-100 w-100 img-fluid"/>
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-6 order-md-6 order-4">
                                <div className="inputmain position-relative d-flex align-items-center">
                                    <input type="text" id="confirmpassword" className="form-control shadow-none affiliateInput bg-transparent rounded-0 border-0 h-100"/>
                                    <label for="confirmpassword" className="affiliateinputLabel position-absolute">Confirm Password</label>
                                    <button className="border-0 shadow-none d-flex align-items-center justify-content-center p-0 passwordvisible bg-transparent">
                                        <img src="assets/img/affiliatenew/passwordvisible.png" alt="password" className="h-100 w-100 img-fluid"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="h-100 row flex-column align-items-center justify-content-between">
                            <div className="col-12">
                                <div className="termsservice d-flex align-items-start justify-content-start">
                                    <input type="checkbox" id="termsofservice" className="checkinput form-check-input shadow-none"/>
                                    <label for="termsofservice">By accessing you confirm that you are agree to the <a href="javascript:;" className="text-decoration-none conditiontitle">Terms of Service</a></label>
                                </div>
                            </div>
                            <div className="col-lg-8 col-lg-4 col-sm-5 col-sm-6  mx-auto">
                                <button className="btn resisterbtn d-flex align-items-center justify-content-center w-100">Register now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<AppFooter/>

</main>
    </>
  )
}

export default AffilliatePage