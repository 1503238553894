import React from 'react'
// import gameIcon from './../../assets/img/new/gameIcon.svg'
// import gameIcon from '../../sportsAssets/img/gameIcon.svg'
// import progressIcon from '../../sportsAssets/img/progress.svg'
// import starIcon from '../../sportsAssets/img/star.svg'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
// import { updateBetSlip } from '../../../store/action'
import { ROUTES_CONST } from '../../../constants/routeConstant'
// import { formatNumberInK } from '../../../utils/returnsNumberInK'
import { useSelector } from 'react-redux'


const BetCard = ({
  competitionName,
  openDate,
  odd1,
  odd2,
  odd3,
  icon,
  runners,
  marketId,
  locked,
  matchId,
  eventId,
  isLiveMatch
}) => {
  const navigate = useNavigate()
  const matchIcon = useSelector(state => state?.matchId.image);

  // updateBetSlip
  return (
    <div
      className=" col-lg-12 cursor-pointer"
      onClick={() => {
        if (matchId) {
          navigate(`${ROUTES_CONST.SPORTS}/${matchId}|+|${eventId}|+|${matchId}`)
        }
      }}>
      <div className={`betCard row mx-0 ${isLiveMatch ? 'matchIsLive' : ''}`}>
        <div className="col clubCol">
          <div className="d-flex align-items-center h-100">
            <div className="clubName">
              {/* <img src={gameIcon} alt="" /> */}
              <img src={matchIcon || icon || 'https://api.damble.io/sportsBook/1696494742879.png'} alt="" />

              {/* International Clubs UEFA Champions League */}
              {competitionName}
            </div>
            <div className="date text-nowrap ms-md-2">
              ({moment(openDate)?.format('MMM DD, HH:mm')})
            </div>
            {
              isLiveMatch ? <div className="">
                <div className="liveTxt">
                  <span>Live</span>
                </div>
              </div> : ''
            }
            {/* <div className="actionBtns flex-grow-0 d-none d-lg-none">
              <button className='bg-transparent border-0 p-0 shadow-none actionBtn'>
                <img src={progressIcon} alt="" />
              </button>
              <button className='bg-transparent border-0 p-0 shadow-none actionBtn'>
                <img src={starIcon} alt="" />
              </button>
            </div> */}
          </div>
          {/* {
            runners?.map((item) => (
              <div className="d-flex matchTeam align-items-center mt-2">
                <img src="https://static.sptpub.com/competitors/images/normal/medium/2697.png" className='me-2' alt="" />
                {item?.runner}
              </div>
            ))
          } */}

          {/* <div className="d-flex matchTeam align-items-center mt-1">
            <img src="https://static.sptpub.com/competitors/images/normal/medium/2697.png" className='me-2' alt="" />
            Inter Milano
          </div> */}
        </div>
        {/* <div className="col-auto d-none d-md-flex flex-column dateCol px-lg-0 my-2 my-md-0">
          <div className="date">
            {moment(openDate)?.format('MMM DD, HH:mm')}
          </div>
        </div> */}


        <div className="col-auto sliderCol">
          {/* <div className="date text-center d-none d-md-block">1 x 2</div> */}
          <div className="row text-center flex-nowrap justify-content-md-end gx-2 gx-sm-2 sliderRow">
            {/* odd 1 */}
            <div className="col-sm-auto col-4 scrollBox">
              <div className="d-flex gap-1">
                <div className={`betBox betBoxBack d-flex flex-column ${locked ? "disabled" : ""}`}>
                  <div className='title'
                  >
                    {
                      odd1 && odd1?.availableToBack[0]?.price ? (
                        odd1?.availableToBack[0]?.price
                      ) : (
                        ''
                      )
                    }
                  </div>
                  {/* <div className='value mt-auto'>
                    {
                      odd1 && odd1?.availableToBack[0]?.size ? (
                        formatNumberInK(Number(odd1?.availableToBack[0]?.size)?.toFixed(1))
                      ) : (
                        ""
                      )
                    }
                  </div> */}
                </div>
                <div className={`betBox betBoxLay d-flex flex-column ${locked ? "disabled" : ""}`}>
                  <div className='title'
                  >
                    {
                      odd1 && odd1?.availableToLay[0]?.price ? (
                        odd1?.availableToLay[0]?.price
                      ) : (
                        ''
                      )
                    }
                  </div>
                  {/* <div className='value mt-auto'>
                    {
                      odd1 && odd1?.availableToLay[0]?.size ? (
                        formatNumberInK(Number(odd1?.availableToLay[0]?.size)?.toFixed(1))
                      ) : (
                        ''
                      )
                    }
                  </div> */}
                </div>
              </div>
            </div>
            {/* odd 3  */}
            <div className="col-sm-auto col-4 scrollBox">
              <div className="d-flex gap-1">
                <div className={`betBox betBoxBack d-flex flex-column ${locked ? "disabled" : ""}`}>
                  <div className='title'
                  >
                    {
                      odd3 && odd3?.availableToBack[0]?.price ? (
                        odd3?.availableToBack[0]?.price
                      ) : (
                        '-'
                      )
                    }
                  </div>
                  {/* <div className='value mt-auto'>
                    {
                      odd1 && odd1?.availableToBack[0]?.size ? (
                        formatNumberInK(Number(odd1?.availableToBack[0]?.size)?.toFixed(1))
                      ) : (
                        ""
                      )
                    }
                  </div> */}
                </div>
                <div className={`betBox betBoxLay d-flex flex-column ${locked ? "disabled" : ""}`}>
                  <div className='title'
                  >
                    {
                      odd3 && odd3?.availableToLay[0]?.price ? (
                        odd3?.availableToLay[0]?.price
                      ) : (
                        '-'
                      )
                    }
                  </div>
                  {/* <div className='value mt-auto'>
                    {
                      odd1 && odd1?.availableToLay[0]?.size ? (
                        formatNumberInK(Number(odd1?.availableToLay[0]?.size)?.toFixed(1))
                      ) : (
                        ''
                      )
                    }
                  </div> */}
                </div>
              </div>
            </div>
            {/* odd 2 */}
            <div className="col-sm-auto col-4 scrollBox">
              <div className="d-flex gap-1">
                <div className={`betBox betBoxBack d-flex flex-column ${locked ? "disabled" : ""}`}>
                  <div className='title'
                  >

                    {
                      odd2 && odd2?.availableToBack[0]?.price ? (
                        odd2?.availableToBack[0]?.price
                      ) : (
                        ''
                      )
                    }
                  </div>
                  {/* <div className='value mt-auto'>
                    {
                      odd2 && odd2?.availableToBack[0]?.size ? (
                        formatNumberInK(Number(odd2?.availableToBack[0]?.size)?.toFixed(1))
                      ) : (
                        ""
                      )
                    }
                  </div> */}
                </div>
                <div className={`betBox betBoxLay d-flex flex-column ${locked ? "disabled" : ""}`}>
                  <div className='title'
                  >
                    {
                      odd2 && odd2?.availableToLay[0]?.price ? (
                        odd2?.availableToLay[0]?.price
                      ) : (
                        ''
                      )
                    }
                  </div>
                  {/* <div className='value mt-auto'
                  >
                    {
                      odd2 && odd2?.availableToLay[0]?.size ? (
                        formatNumberInK(Number(odd2?.availableToLay[0]?.size)?.toFixed(1))
                      ) : (
                        ''
                      )
                    }
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >

  )
}

export default BetCard