import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { isEmail } from "../../../utils/isEmail";
import { GoogleLogin } from "../../socialMedia/GoogleLogin";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import * as Yup from "yup";
import { errorToaster, succesToaster } from "../../../utils/toaster";
import { getAPI, postAPI } from "../../../service/apiInstance";
import {
  auth,
  createUserWithEmailAndPassword,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "../../../config/firebase";
import countryCode from "../../../constants/countryCode.json";
// import MetaMaskModal from "../../socialMedia/MetamaskModal";
import { useAuthModal } from "../../../hooks/useAuthModal";
import TelegramComp from "../../socialMedia/TelegramLoginButton";
import TelegramInputModal from "../../socialMedia/TelegramInputModal";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";

const validationSchema = Yup.object({
  username: Yup.string().required("This field is required"),
  emailOrNumber: Yup.string()
    .required("This field is required")
    .matches(
      /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$|^(\+\d{1,3}\s?)?[\d-]{10,}$/i,
      "Invalid email or mobile number"
    ),
  // agreement: Yup.boolean().required(),
});

const EmailOrNumber = ({
  setPasswordView,
  setOtpId,
  setActive,
  setEmailOrNumber,
  setUsername,
  setChecks,
  checks,
  Active,
}) => {
  const [select, setSelect] = useState("");
  const [isMetamask, setIsMetamask] = useState(false);
  const { authModalObject } = useAuthModal();
  const [isTelegram, setIsTelegram] = useState(false);
  const [userObj, setUserObj] = useState({});
  const [matchedItem, setmatchedItem] = useState([]);
  const [countrycodedata, setCountryCode] = useState([]);
  const countryName = useSelector((state) => state.country);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values) => {
    if (!checks.terms) {
      errorToaster("Please accept the User Agreement");
      setIsLoading(false);
      return;
    }
    setEmailOrNumber(values.emailOrNumber);
    setUsername(values.username);
    if (isEmail(values.emailOrNumber)) {
      setPasswordView(true);
      setActive(false);
      setIsLoading(false);
    } else {
      if (select == 0) {
        errorToaster("Choose a country code");
        setIsLoading(false);
        return;
      }
      handleSend(values);
    }
  };

  const generateRecaptcha = () => {
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear();
    }

    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha2",
      {
        size: "invisible",
        callback: (response) => {},
      },
      auth
    );
  };

  const handleSend = (values) => {
    generateRecaptcha();
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(
      auth,
      `${select.phoneCode?select.phoneCode:select} ${values.emailOrNumber}`,
      appVerifier
    ) //dynamic code
      .then((confirmationResult) => {
        succesToaster("OTP SENT!");
        setOtpId(confirmationResult.verificationId);
        window.confirmationResult = confirmationResult;
        setActive(false);
        setOtpId(true);
      })
      .catch((error) => {
        errorToaster(error.message);
        // setOtpLoader(false);
        // window.recaptchaVerifier.render().then(function (widgetId) {
        //   appVerifier.reset(widgetId);
        // });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const CountryCodeFun = async () => {
    const res = await getAPI("get-countries");
    setCountryCode(res.data.data);
  };
  useEffect(() => {
    const codedata =
      countrycodedata.filter((item) => item.country == countryName).length == 0
        ? countrycodedata
        : countrycodedata.filter((item) => item.country === countryName);
    setmatchedItem(countrycodedata);
    setSelect(countryName && codedata[0]);
    localStorage.setItem("country_Id", codedata[0]?._id);
  }, [countryName, countrycodedata]);

  useEffect(() => {
    // CountryCodeFun();
  }, []);
  useEffect(() => {
    if (!Active) {
      setIsLoading(false);
    }
  }, [Active]);
  const handlesearch = (val) => {
    if (val.length > 0) {
      const filterdata = countrycodedata?.filter((item) =>
        item.country.toLowerCase().includes(val.toLowerCase())
      );
      setmatchedItem(filterdata);
    } else {
      setmatchedItem(countrycodedata)
    }
  };
  return (
    <>
      <Formik
        initialValues={{
          emailOrNumber: "",
          username: "",
        }}
        // validate={validationSchema}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setIsLoading(true);
          onSubmit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <form>
            <div className="row gap-3 gx-0">
              <div className="col-12 inputdata">
                {/* username */}
                <label htmlFor="text" className="inputLabel">
                  USERNAME
                  <span>*</span>
                </label>
                <input
                  type="text"
                  id="text"
                  className="form-control inputlogin border-0 shadow-none"
                  placeholder="Username"
                  value={values.username}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="username"
                  // onKeyDown={(e) => {
                    // e.preventDefault()
                    // if (e.key === "Enter") {
                      // handleSubmit(values)
                    // }
                  // }}
                />
                <ErrorMessage>
                  {errors.username && touched.username && errors.username}
                </ErrorMessage>
              </div>
              <div className="col-12 inputdata">
                {/* email / password */}
                <label htmlFor="email12" className="inputLabel">
                  {/* SELECT */}
                  EMAIL/PHONE NO
                  <span>*</span>
                </label>

                <div className="inputlogin position-relative d-flex align-items-center rounded-2">
                  <div className="dropdown countycodedata position-static">
                    <button
                      className="btn bg-transparent code rounded-0 py-1 border-0 dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-auto-close="outside"
                    >
                       {select?.phoneCode ? select?.phoneCode : select?select:+91}
                    </button>
                    <div className="dropdown-menu countrydata">
                      <div className="input-group mb-2 rounded-2 searchinput flex-nowrap">
                        <span
                          className="input-group-text py-0 pe-0 shadow-none border-0 bg-transparent searchbtn"
                          id="basic-addon2"
                        >
                          <img src="assets/img/search.png" alt="search" />
                        </span>
                        <input
                          type="text"
                          className="form-control py-0 selectInput inputlogin m-0 border-0 bg-transparent shadow-none"
                          placeholder="Search"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          onChange={(e)=>handlesearch(e.target.value)}
                        />
                      </div>
                      <ul className="list-unstyled m-0 d-flex flex-column gap-1 innerdata overflow-y-auto">
                      {matchedItem.map((item) => (
                          <li>
                            <div className=" text-decoration-none innerlink  rounded-2 d-flex align-items-center gap-3"   onClick={() => {setSelect(item.phoneCode);localStorage.setItem("country_Id", item?._id)}}>
                              <div
                                className="countrycode"
                              
                              >
                                {item.phoneCode}
                              </div>
                              <div className="countryname" >{item.country}</div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <input
                    type="text"
                    id="email12"
                    className="form-control selectInput inputlogin mt-0 bg-transparent border-0 shadow-none text-white"
                    placeholder="Email/Phone Number"
                    value={values.emailOrNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="emailOrNumber"
                  />
                </div>
                <ErrorMessage>
                  {errors.emailOrNumber &&
                    touched.emailOrNumber &&
                    errors.emailOrNumber}
                </ErrorMessage>
              </div>

              {/* <div className="col-12 inputdata">
                                <label
                                    htmlFor="email12"
                                    className="inputLabel"
                                >
                                    EMAIL/PHONE NO
                                    <span>*</span>
                                </label>
                                <input
                                    type="text"
                                    id="email12"
                                    className="form-control inputlogin border-0 shadow-none"
                                    placeholder="Email/Phone Number"
                                    value={values.emailOrNumber}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="emailOrNumber"
                                />
                                <ErrorMessage>
                                    {errors.emailOrNumber && touched.emailOrNumber && errors.emailOrNumber}
                                </ErrorMessage>
                            </div> */}
              {/* checks */}
              <div className="col-12 inputdata forgotmain">
                <div className="form-check ">
                  <input
                    className="form-check-input shadow-none"
                    type="checkbox"
                    id="flexCheckC"
                    value={checks.terms}
                    name="terms"
                    onChange={(e) =>
                      setChecks((pre) => ({
                        ...pre,
                        [e.target.name]: e.target.checked,
                      }))
                    }
                  />

                  <label
                    className="form-check-label forgotPassword"
                    htmlFor="flexCheckC"
                  >
                    I agree to the User Agreement & confirm I am at least 18
                    years old 
                  </label>
                </div>
                {/* <div className="form-check">
                  <input
                    className="form-check-input shadow-none"
                    type="checkbox"
                    id="flexCheckChecked"
                    value={checks.promotions}
                    name="promotions"
                    onChange={(e) =>
                      setChecks((pre) => ({
                        ...pre,
                        [e.target.name]: e.target.checked,
                      }))
                    }
                  />
                  <label
                    className="form-check-label forgotPassword"
                    htmlFor="flexCheckChecked"
                  >
                    I agree to receive marketing promotions from Damble.
                  </label>
                </div> */}
              </div>
            </div>
            {/* footer section*/}
            <div className="row mt-4 gap-4">
              {/* button section*/}
              <div className="col-12 mx-auto">
                <span className="playBtn position-relative d-flex align-items-center justify-content-center cursor-pointer">
                  <button
                    type="submit"
                    id="recaptcha2"
                    className="playBtnInner border-0 d-flex align-items-center justify-content-center w-100"
                    onClick={handleSubmit}
                  >
                    {/* Play now! */}
                    {isLoading ? (
                      <Spinner size="sm" color="dark" />
                    ) : (
                      "Continue"
                    )}
                  </button>
                </span>
              </div>

              {/* divider*/}
              <div className="col-12 mx-auto ">
                <div className="loginbutton text-center">
                  <span className="directLogin text-decoration-none">
                    Log in directly with
                  </span>
                </div>
              </div>
              {/* social links*/}
              <div className="col-12 mx-auto">
                <div className="row align-items-center justify-content-center g-2">
                  {/* google */}
                  <div className="col-auto">
                    <span className="text-decoration-none">
                      <div className="link">
                        <GoogleLogin parent={"signUp"}>
                          <img src="assets/img/newIcon/google.svg" alt="" />
                        </GoogleLogin>
                      </div>
                    </span>
                  </div>
                  {/* <div className="col-auto">
                                            <span className="text-decoration-none">
                                                <div className="link">
                                                    <img src="assets/img/newIcon/telegram.svg" alt="" />
                                                </div>
                                            </span>
                                        </div> */}
                  {/* metamask */}
                  <div className="col-auto">
                    <span className="text-decoration-none cursor-pointer">
                      <div className="link" onClick={() => setIsMetamask(true)}>
                        <img
                          src="assets/img/newIcon/metamask-grayscale-logo.svg"
                          alt=""
                        />
                      </div>
                    </span>
                  </div>
                  {/* whatsApp */}
                  {/* <div className="col-auto">
                                            <span className="text-decoration-none">
                                                <div className="link"><img src="assets/img/newIcon/whatsapp.svg" alt="" /></div>
                                            </span>
                                        </div> */}
                  {authModalObject.isAuthOpen ? (
                    <div className="col-auto d-flex justify-content-center my-3">
                      <TelegramComp
                        isOpen={isTelegram}
                        setIsOpen={setIsTelegram}
                        userObj={userObj}
                        setUserObj={setUserObj}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
      {/* <MetaMaskModal
        isOpen={isMetamask}
        setIsOpen={setIsMetamask}
        parent={"signUp"}
      /> */}
      <TelegramInputModal
        isOpen={isTelegram}
        setIsOpen={setIsTelegram}
        userObj={userObj}
        setUserObj={setUserObj}
      />
    </>
  );
};

export default EmailOrNumber;
