import React from "react";
import { Button, Table } from "react-bootstrap";
import {
  CHANGE_STATUS,
  COMMISSION,
  DEPOSIT,
  RESET_USER_PASSWORD,
  SETTLEMENT,
  USER_DETAILS,
  WITHDRAW,
} from "../../AdminConstants/actionButtonsType";
import Pagination from "../pagination/Pagination";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { PORTAL, USER_DETAIL_PAGE } from "../../AdminConstants/urlPaths";

const UserManagementTable = ({
  tableData,
  getUserDetails,
  adminData,
  openActionModal,
  totalPages,
  currentPage,
  setCurrentPage,
  getChildren,
  userData,
  parentData,
  searchedUserResult,
  searchUser,
  tableHeading,
  tableHeadingCol,
  showPagination,
  setSearchUser,
  usernameSort,
  avialableBalSort,
  exposerSort,
  handleNameSort,
  handleAvialableBalSort,
  handleExposerSort,
  hierarchy,
  errorMsg
}) => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props}
    </Tooltip>
  );
  const navigate = useNavigate()
  // const adminData = useSelector(state => state.adminData)

  // handleClick(`/${PORTAL}/${adminData.role}/${BET_LIST_URL}`)
  

  console.log(tableData, errorMsg)

  return (
    <div className="page-section pt-4">
      <div className="table-responsive">
        <Table className="table table-hover"
        >
          <thead>
            {tableHeading?.length ? 
                            <tr>
                              <th colSpan={tableHeadingCol}>
                                <h5 className="tableData d-flex align-items-center ">
                                  <span className="imgIocn d-inline-flex align-items-center">
                                    {/* <img
                                      src={calender}
                                      className="img-fluid w-100 h-100 "
                                      alt="deshboard card"
                                    /> */}
                                  </span>
                                  {tableHeading}
                                </h5>
                              </th>
                              {/* <th className="">
                                <div className=" d-flex align-items-center justify-content-end">
                                  <div className="border-0 shadow-none p-0 d-flex align-items-center">
                                    <button
                                      className="btn bg-white border-0 shadow-none dropdownTable"
                                      type="button"
                                      // data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      View Details
                                    </button>
                                  </div>
                                </div>
                              </th> */}
                            </tr>
                            :
                            ''
                          }
            <tr>
              <th
                style={{ minWidth: "136px", cursor: "pointer" }}
                className="p-2 d-flex justify-content-evenly"
                onClick={() => {!tableHeading?.length && handleNameSort()}}
              >
                <span>Username</span>
                {!tableHeading?.length && <span>
                      {usernameSort === '-1' ? (
                        <i className="bi bi-sort-alpha-down"></i>
                      ) : (
                        <i className="bi bi-sort-alpha-down-alt"></i>
                      )}
                </span>}
              </th>

              <th
                style={{ minWidth: "145px", cursor: "pointer" }}
                className="p-2"
              >
                <span>Credit Reference</span>
                <span>
                        {/* <i className="bi bi-sort-alpha-down"></i>
                        <i className="bi bi-sort-alpha-down-alt"></i> */}
                </span>
              </th>
              <th
                style={{ minWidth: "135px", cursor: "pointer" }}
                className="p-2"
                onClick={() => {!tableHeading?.length && handleAvialableBalSort()}}
              >
                <span>Available Bal</span>
                {!tableHeading?.length && <span className="ms-1">
                      {avialableBalSort === '-1' ? (
                        <i className="bi bi-sort-numeric-down"></i>
                      ) : (
                        <i className="bi bi-sort-numeric-down-alt"></i>
                      )}
                </span>}
              </th>
              <th
                style={{ minWidth: "135px", cursor: "pointer" }}
                className="p-2"
              >
                <span>Downlevel Bal</span>
                <span>
                        {/* <i className="bi bi-sort-alpha-down"></i>
                        <i className="bi bi-sort-alpha-down-alt"></i> */}
                </span>
              </th>
              <th
                style={{ minWidth: "98px", cursor: "pointer" }}
                className="p-2"
              >
                <span>Points W/L</span>
                <span>
                        {/* <i className="bi bi-sort-alpha-down"></i>
                        <i className="bi bi-sort-alpha-down-alt"></i> */}
                </span>
              </th>
              <th
                style={{ minWidth: "98px", cursor: "pointer" }}
                className="p-2"
              >
                <span>UpLine P/L</span>
                <span>
                      {/* <i className="bi bi-sort-alpha-down"></i>
                      <i className="bi bi-sort-alpha-down-alt"></i> */}
                </span>
              </th>
              <th
                style={{ minWidth: "130px", cursor: "pointer" }}
                className="p-2"
                onClick={() => {!tableHeading?.length && handleExposerSort()}}
              >
                <span>Exposure W/L</span>
                {!tableHeading?.length && <span className="ms-1">
                      {exposerSort === '-1' ? (
                        <i className="bi bi-sort-numeric-down"></i>
                      ) : (
                        <i className="bi bi-sort-numeric-down-alt"></i>
                      )}
                </span>}
              </th>
              <th 
              // style={{ minWidth: "400px" }} 
              className="p-2">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {!searchUser?.length > 0 ?
            tableData?.length ?
              tableData?.map((item) => (
                <tr key={item?._id}>
                  <td className="align-middle fw-bold text-decoration-none cursor-pointer" onClick={
                        item?.role === "user"
                          ? () => {
                            navigate(`/${PORTAL}/${adminData.role}/${USER_DETAIL_PAGE}/${item?._id}`, {state: {breadcrumData : hierarchy, username: item?.username}})                            
                          }
                          : () => {
                            getUserDetails(item)
                            
                          }
                      }>
                      {item?.username}
                  
                  </td>
                  <td className="align-middle fw-medium">{item?.totalPoints}</td>
                  <td className="align-middle fw-medium">{item?.availablePoints}</td>
                  <td className="align-middle fw-medium"> {item?.downLevelBalance}</td>
                  <td className={`align-middle fw-medium ${item?.profitLossPoints <= 0 ? 'admin-forms-error-text':'admin-forms-success-text'}`}>{item?.profitLossPoints}</td>
                  <td className="align-middle fw-medium"> {parentData?.profitLossPoints || 0}</td>
                  <td className={`align-middle fw-medium ${item?.exposer <= 0 ? 'admin-forms-error-text':'admin-forms-success-text'}`}>{item?.exposer}</td>
                  <td className="align-middle fw-medium ">
                    <div className="d-flex align-items-center">
                    {item._id !== adminData._id && (
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip('Deposit')}
                        >
                          <Button
                            className="mx-1 fw-bold"
                            variant="success"
                            onClick={() => openActionModal(DEPOSIT, item)}
                          >
                            D
                          </Button>
                        </OverlayTrigger>
                    )}
                    {item._id !== adminData._id && (
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip('Withdraw')}
                        >
                      <Button
                        className="mx-1 fw-bold"
                        variant="danger"
                        onClick={() => openActionModal(WITHDRAW, item)}
                      >
                        W
                      </Button>
                      </OverlayTrigger>
                    )}
                    {item.role !== "superAdmin" && item.role !== "user" && (
                      
                      <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip('Commission')}
                    >
                      <Button
                        className="mx-1 fw-bold"
                        variant="info"
                        onClick={() => openActionModal(COMMISSION, item)}
                      >
                        C
                      </Button>
                      </OverlayTrigger>
                    )}
                    
                    <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip('Settlement')}
                        >
                    <Button
                      className="mx-1 fw-bold"
                      variant="warning"
                      onClick={() => openActionModal(SETTLEMENT, item)}
                    >
                      S
                    </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip('Reset Password')}
                        >
                    <Button
                      className="mx-1 fw-bold text-dark"
                      variant="secondary"
                      onClick={() =>
                        openActionModal(RESET_USER_PASSWORD, item)
                      }
                    >
                      P
                    </Button>
                    </OverlayTrigger>
                    {item.role !== "superAdmin" && item.role !== "user" && (
                      
                    <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip('Change Status')}
                  >
                      <Button
                        className="mx-1 fw-bold"
                        onClick={() => openActionModal(CHANGE_STATUS, item)}
                      >
                        CS
                      </Button>
                      </OverlayTrigger>
                    )}
                    
                    <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip('Edit')}
                        >
                    <Button
                      className="mx-1 fw-bold"
                      variant="dark"
                      onClick={() => openActionModal(USER_DETAILS, item)}
                    >
                      <i className="bi bi-eye-fill"></i>
                    </Button>
                      </OverlayTrigger>
                    </div>
                  </td>
                </tr>
              )) : 
              <tr>
                <td colSpan={8} className="text-center" style={{fontSize: 16, fontWeight: 500}}>{errorMsg===''?"No User Available": errorMsg}</td>
              </tr>
              : ''
            }
            {searchUser?.length > 0 ?
              searchedUserResult?.length > 0 ?
                searchedUserResult?.map((item) => (
                  <tr key={item?._id}>
                    <td className="align-middle fw-bold text-decoration-none cursor-pointer" onClick={
                          item?.role === "user"
                            ? () => {navigate(`/${PORTAL}/${adminData.role}/${USER_DETAIL_PAGE}/${item?._id}`)}
                            : () => {
                              getUserDetails(item)
                              setSearchUser("")
                            }
                        }>
                        {item?.username}
                    </td>
                    <td className="align-middle fw-medium">{item?.totalPoints}</td>
                    <td className="align-middle fw-medium">{item?.availablePoints}</td>
                    <td className="align-middle fw-medium"> {item?.downLevelBalance}</td>
                    <td className="align-middle fw-medium">{item?.profitLossPoints}</td>
                    <td className="align-middle fw-medium"> {parentData?.profitLossPoints || 0}</td>
                    <td className="align-middle fw-medium ">0.00</td>
                    <td className="align-middle fw-medium ">
                      <div className="d-flex align-items-center">
                      {item._id !== adminData._id && (
                        <Button
                          className="mx-1"
                          variant="success"
                          onClick={() => openActionModal(DEPOSIT, item)}
                        >
                          D
                        </Button>
                      )}
                      {item._id !== adminData._id && (
                        <Button
                          className="mx-1"
                          variant="danger"
                          onClick={() => openActionModal(WITHDRAW, item)}
                        >
                          W
                        </Button>
                      )}
                      {item.role !== "superAdmin" && item.role !== "user" && (
                        <Button
                          className="mx-1"
                          variant="info"
                          onClick={() => openActionModal(COMMISSION, item)}
                        >
                          C
                        </Button>
                      )}
                      <Button
                        className="mx-1"
                        variant="warning"
                        onClick={() => openActionModal(SETTLEMENT, item)}
                      >
                        S
                      </Button>
                      <Button
                        className="mx-1 text-dark"
                        variant="secondary"
                        onClick={() =>
                          openActionModal(RESET_USER_PASSWORD, item)
                        }
                      >
                        P
                      </Button>
                      {item.role !== "superAdmin" && item.role !== "user" && (
                        <Button
                          className="mx-1"
                          onClick={() => openActionModal(CHANGE_STATUS, item)}
                        >
                          CS
                        </Button>
                      )}
                      <Button
                        className="mx-1"
                        variant="dark"
                        onClick={() => openActionModal(USER_DETAILS, item)}
                      >
                        <i className="bi bi-eye-fill"></i>
                      </Button>
                      </div>
                    </td>
                  </tr>
                )) :
                <tr>
                  <td colSpan={8} className="text-center" style={{fontSize: 16, fontWeight: 500}}>No User Available</td>
                </tr>
              : ''
            }
          </tbody>
        </Table>
      </div>
      {
        showPagination ? 
        <>
        {!searchUser?.length > 0 && 
        <>
        {
          totalPages > 1 ? 
          <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
          : ''
        }
        </>
        }
        </>
        :
        ''
      }
      <hr className="my-5" />
    </div>
  );
};

export default UserManagementTable;
