import leftIcon from './../../AdminAssets/img/left.png'
import rightIcon from './../../AdminAssets/img/right.png'

const Pagination = ({
  //eslint-disable-next-line
  totalPages,
  //eslint-disable-next-line
  currentPage,
  //eslint-disable-next-line
  setCurrentPage
}) => {
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1)
    }
  }
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1)
    }
  }

  return (
    <ul className="pagination adminPagination d-flex justify-content-center mt-3">
      <li className="page-item arrow d-flex justify-content-end" onClick={handlePrevious} >
        <span className="page-link " tabIndex={-1}>
          {/* <i className="bi bi-lg bi-arrow-left-circle" /> */}
          <img src={leftIcon} alt="" />
        </span>
      </li>
      <li className="" >
        <ul className="lis list-unstyled d-flex list-group-horizontal bg-none border-none justify-content-between">
          {
            currentPage > 1 && <li className="page-item" onClick={() => setCurrentPage(1)} >
              <span className="page-link" >
                1
              </span>
            </li>
          }
          {
            (currentPage > 2)
              ? (
                <li className="page-item disabled">
                  <span className="page-link" tabIndex={-1}>
                    ...
                  </span>
                </li>
              ) : ""}
          <li className="page-item active">
            <span className="page-link">
              {currentPage}
            </span>
          </li>
          {(currentPage < totalPages - 1) ? (<li className="page-item disabled">
            <span className="page-link" tabIndex={-1}>
              ...
            </span>
          </li>) : ''}
          {currentPage < totalPages && (
            <li className="page-item" onClick={() => setCurrentPage(totalPages)} >
              <span className="page-link">
                {totalPages}
              </span>
            </li>
          )}
        </ul>
      </li>
      <li className="page-item arrow d-flex justify-content-start" onClick={handleNext} >
        <span className="page-link ">
          {/* <i className="bi bi-arrow-right-circle" /> */}
          <img src={rightIcon} alt="" />
        </span>
      </li>
    </ul>
  );
};

export default Pagination;
