import React from 'react';
import '../casino-styles/casino.scss';
import { getAPIAuth } from '../../service/apiInstance';
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES_CONST } from '../../constants/routeConstant';
import AppFooter from '../../components/AppFooter/AppFooter';
import { useAuth } from '../../hooks/useAuth';
import searchIcon from "./../../Trading/TradingAssets/img/search.png";
import { Spinner } from 'react-bootstrap';

const CasinoHome = () => {
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(16)
    const [casinoCategory, setCasinoCategory] = useState([])
    const [casinoProvider, setCasinoProvider] = useState([]);
    // console.log("casinoProvider", casinoProvider);
    const [selectedCategory, setSelectedCategory] = useState({ _id: "", name: "" })
    const [selectedProvider, setSelectedProvider] = useState('')
    const [searchQuery, setSearchQuery] = useState('');
    const [launchUrl, setLaunchUrl] = useState('');

    const navigate = useNavigate()
    const location = useLocation()
    const { token } = useAuth()

    useEffect(() => {
        setSelectedProvider(location.state?.provider_id ? location.state?.provider_id : '')
    }, [location])

    const getData = async () => {
        setLoader(true)
        if (token) {
            try {
                const res = await getAPIAuth(`casino/casinoGames?perPage=${perPage}&page=${currentPage}&name=${searchQuery}&category=${selectedCategory?._id}&provider=${selectedProvider}`, token)
                if (res?.data?.data?.success) {
                    setData(res?.data?.data?.data)
                } else {
                    // setData([])
                }
            } catch (error) {
                setData([])
            } finally {
                setLoader(false)
            }
        }
    }

    // get casino providers, sub-provider and categories
    const getCasinoOptions = async () => {
        try {
            const res = await getAPIAuth('casino/casinoGamesData')
            if (res?.data?.data?.success) {
                // setCasinoCategory(res?.data?.data?.data?.[0]?.category)
                // setCasinoProvider(res?.data?.data?.data?.[0]?.providerName)
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        // getCasinoOptions()
        getCasinoCategories()
        getProviders()
    }, [])

    const getCasinoCategories = async () => {
        try {
            const res = await getAPIAuth('casino/getCasinoCategory?status=enabled&nameSort=1&createdAtSort=1&page=1&perPage=0')
            if (res?.data?.data?.success) {
                setCasinoCategory(res?.data?.data?.data)
            }
        } catch (error) {

        }
    }

    const getProviders = async () => {
        try {
            const res = await getAPIAuth("casino/getProviders");
            if (res?.data?.data?.success) {
                setCasinoProvider(res?.data?.data?.data);
            }
        } catch (error) {
        }
    };


    useEffect(() => {
        // setSelectedProvider('')
        // setSelectedCategory('')
        // const searchData = setTimeout(() => {
        getData()
        //     }, 2000);
        // return ()=> clearTimeout(searchData)
    }, [searchQuery, currentPage, perPage, selectedCategory, selectedProvider, location])//eslint-disable-line

    const handleClick = async (item) => {

        // const obj = {
        //     "gameId": item?._id
        // }

        // try {
        //     const res = await postAPIAuth(`casinoApi/authentication`, obj, token);
        //     console.log(res);
        //     if (res?.data?.data?.success) {
        //         setLaunchUrl(res.data.data.data.launchURL);
        //     }
        // } catch (error) {
        //     console.log(error.response.data.data.message);
        //     toast(error.response.data.data.message)

        // }

        // work data

        if (item?.providerCode === 'SN') {
            // dispatch(setVideoUrlAction(item?.videoUrl))
            navigate(`${ROUTES_CONST.CASINO_HOME}/${item.providerCode}?game=${item._id}`)
        } else {
            navigate(`${ROUTES_CONST.COMING_SOON}`)
        }
        // navigate(`${ROUTES_CONST.CASINO_HOME}/${item?.gameId}?provider=${item?.providerName}`) 
    }


    // console.log("dasdfgsdftaaaa", data);

    return (

        <main className='main  vh-100'>
            <div className='mainContaint'>
                <div className='homepage tradingPages'>
                    {/* <ProviderSlider/> */}
                    <div className="row optionPillsOuter g-2 flex-nowrap overflow-x-auto mt-4">
                        <div className="col-auto">
                            <div className={`optionPill text-decoration-none rounded-pill d-flex align-items-center justify-content-center ${selectedProvider === '' ? "active" : ""}`}
                                onClick={() => setSelectedProvider('')}>
                                All
                            </div>
                        </div>
                        {
                            casinoProvider?.length > 0 ?
                                casinoProvider?.map((item) => (
                                    <div className="col-auto">
                                        <div className={`optionPill text-decoration-none rounded-pill d-flex align-items-center justify-content-center ${selectedProvider === item?.fullName ? "active" : ""}`}
                                            onClick={() => setSelectedProvider(item?.fullName)}>
                                            {item?.fullName}
                                        </div>
                                    </div>
                                ))
                                : ''
                        }
                    </div>
                    <div className="row optionPillsOuter g-2 flex-nowrap overflow-x-auto mt-1">
                        <div className="col-auto">
                            <div className={`optionPill text-decoration-none rounded-pill d-flex align-items-center justify-content-center ${selectedCategory._id === '' ? "active" : ""}`}
                                onClick={() => setSelectedCategory({ _id: "", name: "" })}>
                                <img src="assets/img/sidebar/new/homeIcon.png" alt="" />
                                {/* <svg stroke="white" fill="white" style={{marginRight: '8px'}} stroke-width="0" viewBox="0 0 24 24" height="18px" width="18px" xmlns="http://www.w3.org/2000/svg"><path d="M19 21.0001H5C4.44772 21.0001 4 20.5524 4 20.0001V11.0001L1 11.0001L11.3273 1.61162C11.7087 1.26488 12.2913 1.26488 12.6727 1.61162L23 11.0001L20 11.0001V20.0001C20 20.5524 19.5523 21.0001 19 21.0001ZM13 19.0001H18V9.15757L12 3.70302L6 9.15757V19.0001H11V13.0001H13V19.0001Z"></path></svg> */}
                                All
                            </div>
                        </div>
                        {
                            casinoCategory?.length > 0 ?
                                casinoCategory?.map((item) => (
                                    <div className="col-auto">
                                        <div className={`optionPill text-decoration-none rounded-pill d-flex align-items-center justify-content-center ${selectedCategory._id === item?._id ? "active" : ""}`}
                                            onClick={() => setSelectedCategory({ _id: item?._id, name: item?.name })}>
                                            {
                                                item?.imageStatus ?
                                                    <img src={item?.image} alt="" />
                                                    : ''
                                            }
                                            {item?.name}
                                        </div>
                                    </div>
                                ))
                                : ''
                        }
                    </div>
                    <div className="row g-3 mt-1 mb-3 align-items-center">
                        <div className="col-lg">
                            <div className="tradingSecHead">Casino Games</div>
                        </div>
                        <div className="col-lg-3 col-xxl-3 col-md-6">
                            <div className="input-group w-100 searchDrop rounded-2 inputGroup">
                                <span className="input-group-text  shadow-none border-0 bg-transparent searchIcon" id="basic-addon2">
                                    <img
                                        src={searchIcon}
                                        className="img-fluid w-100 h-100"
                                        alt="table accordion"
                                    />
                                </span>
                                <input
                                    type="search"
                                    className="form-control ps-0 py-0 searchInput m-0 border-0 bg-transparent shadow-none"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-auto col-xxl-2 col-md-3 col-6">
                            <div className=" socialmedia">
                                <div className="dropdown">
                                    <button
                                        className="btn btn-secondary marketBtn dropdown-toggle dropdownToggle text-capitalize"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {selectedCategory?.name ? selectedCategory?.name : 'Select Category'}
                                    </button>
                                    <ul className="dropdown-menu dropdownMenu w-100">
                                        <li
                                            className={`menuLinkDrop text-capitalize ${selectedCategory?._id === "" ? "active" : ""}`}
                                            onClick={() => {
                                                setCurrentPage('1')
                                                setSelectedCategory({ _id: "", name: "" })
                                                // setSelectedProvider("")
                                                // setSearchQuery('')
                                            }
                                            }
                                        >
                                            All
                                        </li>
                                        {
                                            casinoCategory?.length > 0 ?
                                                casinoCategory?.map((item) => (
                                                    <li
                                                        className={`menuLinkDrop text-capitalize ${selectedCategory._id === item?._id ? "active" : ""}`}
                                                        onClick={() => {
                                                            setCurrentPage('1')
                                                            setSelectedCategory({ _id: item?._id, name: item?.name }
                                                            )
                                                            // setSelectedProvider("")
                                                            // setSearchQuery('')
                                                        }}
                                                    >
                                                        {item?.name}
                                                    </li>
                                                ))
                                                : ''
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-auto col-xxl-2 col-md-3 col-6">
                            <div className=" socialmedia">
                                <div className="dropdown">
                                    <button
                                        className="btn btn-secondary marketBtn dropdown-toggle dropdownToggle text-capitalize"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {selectedProvider ? selectedProvider : 'Select Provider'}
                                    </button>
                                    <ul className="dropdown-menu dropdownMenu w-100">
                                        <li
                                            className={`menuLinkDrop text-capitalize ${selectedProvider === "" ? "active" : ""}`}
                                            onClick={() => {
                                                setCurrentPage('1')
                                                setSelectedProvider("")
                                                // setSelectedCategory('')
                                                // setSearchQuery('')
                                            }}
                                        >
                                            All
                                        </li>
                                        {
                                            casinoProvider?.length > 0 ?
                                                casinoProvider?.map((item) => (
                                                    <li
                                                        className={`menuLinkDrop text-capitalize ${selectedProvider === item?.name ? "active" : ""}`}
                                                        onClick={() => {
                                                            setCurrentPage('1')
                                                            setSelectedProvider(item?.fullName)
                                                            // setSelectedCategory('')
                                                            // setSearchQuery('')
                                                        }}
                                                    >
                                                        {item?.fullName}
                                                    </li>
                                                ))
                                                : ''
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='row '>
                        <div className='col-12'>
                            <div className='row g-3'>
                                {
                                    !loader ?
                                        data.length ? data.map(item => (<div className='col-sm-3 col-6' key={item?._id} >
                                            <div className={`casinoImg rounded-2 overflow-hidden cursor-pointer`} onClick={() => handleClick(item)} >
                                                <img src={item?.urlThumb} className="img-fluid w-100 h-100 " alt="CasinoHome" />
                                            </div>
                                        </div>)
                                        ) : <div className='col-12 d-flex align-items-center text-light justify-content-center py-4'>
                                            No Data Available
                                        </div>
                                        : <div className='col-12 d-flex align-items-center justify-content-center py-4'>
                                            <Spinner variant="light" />
                                        </div>
                                }

                                {
                                    launchUrl ? (
                                        <>
                                            <iframe
                                                src={launchUrl}
                                                className="w-100 h-100"
                                                frameborder="0"
                                                title="iframe"
                                                style={{ position: "absolute " }}
                                            ></iframe>
                                        </>
                                    ) : <></>
                                }
                            </div>
                        </div>
                        {
                            data.length ?
                                <div className="col-12 d-flex justify-content-center py-4">
                                    <button className='casinoloadmoreBtn' onClick={() => setPerPage(perPage + 8)}>Load More</button>
                                </div>
                                : ''
                        }
                    </div>
                </div>
            </div>
            <AppFooter />
        </main>
    )
}

export default CasinoHome