import { useLocation, useNavigate } from "react-router-dom";
import AppHeader from "./components/AppHeader/AppHeader";
import RoutesComp from "./routes/Routes";
import AppSideBar from "./components/AppSideBar/AppSideBar";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import i18next from "i18next";
import { ADMIN_AUTH_ACCESS, ADMIN_AUTH_ROLE, AUTH_MODAL_TABS, USER_LANG } from "./constants";
import { DEFAULT_LANG } from "./constants/languageConstant";
import { socket } from "./service/socket";
import { useAuth } from "./hooks/useAuth";
import { getAPI, postAPIAuth } from "./service/apiInstance";
import ChatWrapper from "./components/common/ChatBoat/ChatWrapper";
import { useAuthModal } from "./hooks/useAuthModal";
import { ROUTES_CONST } from "./constants/routeConstant";
import Chatbot from "./components/common/ChatBoat/chatbaot";
import axios from "axios";
import AdminHeader from "./Admin/Admincomponents/AdminHeader/AdminHeader";
import AdminSideBar from "./Admin/Admincomponents/AdminSidebar/AdminSideBar";
import { useFantasyLoader } from "./hooks/useFantasyLoader";
import FantasyLoader from "./components/fantasyLoader/FantasyLoader";
import { DASHBOARD_URL, LOGIN_URL, PORTAL } from "./Admin/AdminConstants/urlPaths";
import { adminLogOut } from "./store/action";
// import ipapi from 'ipapi';

function App() {
  const [referraldata, setReferral] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useAuth()
  const userDetails = useSelector(state => state.userDetails);
  const adminData = useSelector(state => state.adminData);
  const { setAuthModalObject } = useAuthModal()
  const { showFantasyLoader } = useFantasyLoader()
  const adminTokenValue = localStorage.getItem(ADMIN_AUTH_ACCESS)
  // const token = localStorage.getItem(ADMIN_AUTH_ACCESS);
  const adminRole = localStorage.getItem(ADMIN_AUTH_ROLE)
  const referralFun = () => {
    setReferral(location.pathname);
  };
  const splitBySlash = referraldata.split("/");
  const lastPart = splitBySlash[splitBySlash.length - 1];
  const extractedStart = lastPart.startsWith("i-") ? "i-" : "";
  const extractedEnd = lastPart.endsWith("-n") ? "-n" : "";
  const middlePart = lastPart.substring(2, lastPart.length - 2);
  const modifiedLink = referraldata.replace(
    "/" + extractedStart + middlePart + extractedEnd,
    ""
  );
  
const dispatch=useDispatch();
useEffect(()=>{
if(localStorage.length===0){
  dispatch(adminLogOut());
}
},[localStorage])
  // ---------
  const [showSideBar, setShowSideBar] = useState(false);
  // open register section with referal code.
  useEffect(() => {
    if (extractedStart && extractedEnd) {
      localStorage.setItem("middlePart", middlePart);
      setAuthModalObject(pre => ({
        isAuthOpen: true,
        selectedTab: AUTH_MODAL_TABS.SIGN_UP
      }))
      // navigate("/games", { state: { extractedStart: extractedStart } });
      navigate(ROUTES_CONST.INDEX, { state: { extractedStart: extractedStart } });
    } else {
      localStorage.setItem("middlePart", "");
    }
    referralFun();
  }, [extractedStart, extractedEnd]);

  // for language
  const languageHandler = async () => {
    const lang = localStorage.getItem(USER_LANG)
    if (!userDetails?.language) {
      // if user don't have a lang.
      if (token && lang && !userDetails?.language) {
        // api call to set userDetails.language
        if (userDetails?.id) {
          try {
            const body = {
              language: lang
            }
            const res = await postAPIAuth(`user/change-user-lang?userId=${userDetails?.id}`, body)
          } catch (error) {

          }
        }
        // localStorage.setItem(USER_LANG, item)

      }
      if (!token && lang) {
        // using the cached language
        // console.log("logging", "!token && lang", lang)
        i18next.changeLanguage(lang)
      } else if (!token && !lang) {
        // setting default language as en
        // console.log("logging", "!token && !lang", lang)
        i18next.changeLanguage(DEFAULT_LANG.symbol)
        localStorage.setItem(USER_LANG, DEFAULT_LANG.symbol)
      }
    } else if (userDetails?.language) {
      // if user do have a lang.
      // console.log("logging", "userDetails?.language", lang)
      i18next.changeLanguage(userDetails?.language)
      localStorage.setItem(USER_LANG, userDetails?.language)
    }
  }

  const newLanguageHandler = async () => {
    const lang = localStorage.getItem(USER_LANG)
    if (userDetails?.id) {
      // setting users language from local storage
      try {
        const body = {
          language: lang
        }
        const res = await postAPIAuth(`user/change-user-lang?userId=${userDetails?.id}`, body)
        if (res.data.success) {
          i18next.changeLanguage(lang)
          localStorage.setItem(USER_LANG, lang)
        }
      } catch (error) {
        // error
      }
    } else {
      if (!token && lang) {
        // using the cached language
        i18next.changeLanguage(lang)
      } else if (!token && !lang) {
        // setting default language as en
        i18next.changeLanguage(DEFAULT_LANG.symbol)
        localStorage.setItem(USER_LANG, DEFAULT_LANG.symbol)
      }
    }

  }

  useEffect(() => {
    // language
    newLanguageHandler()
  }, [userDetails?.id, token])

  // will be of future use

  // const chatBox = async () => {
  //   const path = window.location.pathname
  //   if (!path.startsWith('/sports')) {
  //     // alert(path, 'here')
  //     var $zoho = {};
  //     $zoho.salesiq = {
  //       widgetcode: "siq2a2bff036a476b4b4b76050979792b22930f2581c782d83b9ac7b6f4d435c9dd",
  //       values: {},
  //       ready: function () { }
  //     };
  //     var d = document;
  //     var s = d.createElement("script");
  //     s.type = "text/javascript";
  //     s.id = "zsiqscript";
  //     s.defer = true;
  //     s.src = "https://salesiq.zohopublic.com/widget";
  //     var t = d.getElementsByTagName("script")[0];
  //     t.parentNode.insertBefore(s, t);
  //   }
  // }

  // scroll to top everytime url changes
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
    // chatBox()
  }, [location.pathname])


  // add a class on body on fantasy pages
  useEffect(() => {
    if (location.pathname.includes('/fantasy')) {
      document.body.classList.add('fantasyPages')
    } else {
      document.body.classList.remove('fantasyPages')
    }
  }, [location.pathname])

  // add a class on body on admin  pages
  useEffect(() => {
    if (location.pathname.includes('/portal')) {
      document.body.classList.add('adminPages')
    } else {
      document.body.classList.remove('adminPages')
    }
  }, [location.pathname])

  // conditional style for admin and fantasy pages
  useEffect(() => {
    if (location.pathname.includes('/portal')) {
      import('./Admin/AdminAssets/stylesheets/custom.scss')
      import('./Admin/AdminAssets/stylesheets/theme.scss')
    } else if (location.pathname.includes('/fantasy')) {
      import('./fantasy/fantasyAssets/scss/style.scss')
    }
  }, [location.pathname])



  // redirect to admin dashboard if user try to go to admin login page after login
  useEffect(() => {
    console.log(adminData)
    if (adminTokenValue) {
      if (adminRole) {
        if (location.pathname.includes("/" + PORTAL + "/" + LOGIN_URL)) {
          navigate(`/${PORTAL}/` + adminRole + "/" + DASHBOARD_URL)
        }
      }
    }
  }, [adminTokenValue, location?.pathname, adminRole])



  return (
    <>
      {
        // location.pathname === "/" ||
        location?.pathname === "/aml-policy" ||
          location?.pathname === "/responsible-gamble" ||
          location?.pathname.includes('/fantasy') ||
          location?.pathname === "/terms-conditions" ? (
          <></>
        ) : location?.pathname?.includes('/portal') && !location?.pathname?.includes('/login') ? (
          <>
            <AdminHeader setShowSideBar={setShowSideBar} />
          </>
        ) : !location?.pathname?.includes('/portal') ? (
          <>
            <AppHeader />
          </>
        ) : ""
      }

      {
        // location.pathname === "/sports" ||
        // location.pathname === "/" ||
        location.pathname === "/affiliate-dashboard" ||
          location.pathname === "/aml-policy" ||
          location.pathname === "/responsible-gamble" ||
          location.pathname === "/terms-conditions" ||
          location.pathname.includes('/fantasy')
          ? (
            <></>
          ) : location.pathname.includes('/portal') ? (
            <>
              <AdminSideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
            </>
          ) : (
            <>
              <AppSideBar />
            </>
          )
      }
      {/* {location.pathname === "/sports" ? <></> : <Chatbot />} */}
      {/* <ChatWrapper /> */}
      {/* <Chatbot /> */}
      {showFantasyLoader ? <FantasyLoader /> : ''}

      <RoutesComp socket={socket} />
      {/* -------------------------- */}
      {/* {pathname !== LOGIN_URL && (
      )}
      <SideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      
      <Routes /> */}
    </>
  );
}

export default App;
